import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Blacklist } from './contact-blacklist.model';

export interface ContactBlacklistState extends EntityState<Blacklist> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox-contact-backlist', idKey: 'id' })
export class ContactBlacklistStore extends EntityStore<ContactBlacklistState> {
  constructor() {
    super({});
  }
}
