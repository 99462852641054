import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { InboxNotificationStoreName, Notification } from './notifications.model';

export interface NotificationsState extends EntityState<Notification> {
  count: number;
  lastUpdated: number;
  unread: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: InboxNotificationStoreName, idKey: 'id' })
export class NotificationsStore extends EntityStore<NotificationsState> {
  constructor() {
    super();
  }
}
