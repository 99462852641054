import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'shc-slice-text',
  templateUrl: './slice-text.component.html',
  styleUrls: ['./slice-text.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule]
})
export class SliceTextComponent implements OnChanges {
  @Input() text: string;
  @Input() limitContentLength = 16;

  tooltip: string;

  constructor() {}

  ngOnChanges(): void {
    if (this.text) {
      this.tooltip = this.text.length > this.limitContentLength ? this.text : '';
    }
  }
}
