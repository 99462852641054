import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '@b3networks/api/common';
import { X_B3_HEADER } from '@b3networks/shared/common';
import { map } from 'rxjs';
import { AddKeyReq, EditKeyReq, GetKeyParams, Key } from './language.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private http: HttpClient) {}

  getAll(reqParams: GetKeyParams) {
    let params = new HttpParams().set('page', reqParams.page).set('perPage', reqParams.perPage);
    if (reqParams.key) {
      params = params.set('key', reqParams.key);
    }
    return this.http.get<Key[]>(`portal/private/v1/language`, { params, observe: 'response' }).pipe(
      map(resp => {
        const totalCount = +resp.headers.get(X_B3_HEADER.totalCount);
        return <Page<Key>>{
          content: resp.body,
          totalCount: totalCount
        };
      })
    );
  }

  addKey(req: AddKeyReq) {
    return this.http.post<Key>(`portal/private/v1/language/key`, req);
  }

  editKey(req: EditKeyReq) {
    return this.http.put<Key>(`portal/private/v1/language/key`, req);
  }

  importCsvFile(fileKey: string) {
    return this.http.post<void>(`portal/private/v1/language/import`, { fileKey });
  }
}
