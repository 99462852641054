import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemberStatus } from '@b3networks/api/auth';
import { UserQuery } from '@b3networks/api/workspace';
import { HashMap, ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { ChatMessage } from '../chat-message/chat-message.model';
import { BuildMessageUI } from '../chat-message/chat-messsage-ui.model';
import { Privacy } from '../enums.model';
import { ChannelQuery } from './channel.query';
import { ChannelState, ChannelStore } from './channel.store';
import { ChannelUI } from './model/channel-ui.model';
import {
  Channel,
  ChannelPersonalResponse,
  CopyMessageRequest,
  CreateConvoGroupReq,
  CreateThreadRequest,
  GetChannelWithMeV2Response,
  IParticipant,
  RecentChannel,
  RequestRangeThreadsByParent,
  RequestRangeThreadsByUser,
  ResponseRangeThreads,
  UpdateChannelReq
} from './model/channel.model';
import { ChannelType, NameChannelPersonal } from './model/enum-channel.model';
import { MessageSearchResult, RequestSearchCriteria } from './model/search-criteria.model';

@Injectable({ providedIn: 'root' })
export class ChannelService {
  convoNotFound: string[] = ['undefined', 'null'];

  private _mapFetchingChannel: HashMap<boolean> = {};

  constructor(
    private http: HttpClient,
    private store: ChannelStore,
    private query: ChannelQuery,
    private userQuery: UserQuery
  ) {}

  search(filter: RequestSearchCriteria): Observable<MessageSearchResult> {
    return this.http.post<MessageSearchResult>(`/public/_tc/message/search`, filter);
  }

  createChannel(req: CreateConvoGroupReq, meChatUuid: string) {
    return this.http.post<Channel>(`/public/_tc/channel`, req).pipe(
      map(channel => new Channel(channel).withMeUuid(meChatUuid)),
      tap(channel => {
        this.store.upsert(channel.id, channel, { baseClass: Channel });
      })
    );
  }

  getChannelsWithMe(meChatUuid: string, personalChannels = false): Observable<Channel[]> {
    let params = new HttpParams();
    if (personalChannels) {
      params = params.append('personalChannels', 'true');
    }
    return this.http.get<Channel[]>(`/public/_tc/channel`, { params }).pipe(
      map(list =>
        list?.map(channel => {
          const model = new Channel(channel).withMeUuid(meChatUuid);
          return model;
        })
      ),
      tap(channels => {
        this.updateChannel(channels);
        this.store.update({ loadedMineChannel: true });
      })
    );
  }

  getMines(meChatUuid: string) {
    return this.http
      .post<{ channels: GetChannelWithMeV2Response[] }>(`/public/v2/_tc/namespace/channel/getMines`, {})
      .pipe(
        map(x => x?.channels || []),
        map(list =>
          list?.map(channel => {
            const item = new GetChannelWithMeV2Response(channel);
            return this.convertChannel(item, meChatUuid);
          })
        ),
        tap(channels => {
          this.updateChannel(channels);
        })
      );
  }

  getPublicChannels(): Observable<Channel[]> {
    this.store.setLoading(true);
    return this.http.get<Channel[]>(`/public/_tc/channel/public`).pipe(
      finalize(() => this.store.setLoading(false)),
      map(list =>
        list?.map(
          channel =>
            <Channel>{
              name: channel.name,
              id: channel.id,
              privacy: Privacy.public,
              type: ChannelType.gc
            }
        )
      ),
      tap(channels => {
        this.updateChannel(channels);
        this.store.update({ loaded: true });
      })
    );
  }

  getOne(channelId: string, meChatUuid: string) {
    return this.http
      .post<{ channels: GetChannelWithMeV2Response[] }>(`/public/v2/_tc/namespace/channel/getMulti`, {
        channelsIds: [channelId]
      })
      .pipe(
        map(x => x?.channels || []),
        map(list =>
          list?.map(channel => {
            const item = new GetChannelWithMeV2Response(channel);
            return this.convertChannel(item, meChatUuid);
          })
        ),
        tap(channels => {
          this.updateChannel(channels);
        }),
        map(list => list.find(x => x.id === channelId))
      );
  }

  getMulti(channelIds: string[], meChatUuid: string) {
    return this.http
      .post<{ channels: GetChannelWithMeV2Response[] }>(`/public/v2/_tc/namespace/channel/getMulti`, {
        channelsIds: channelIds
      })
      .pipe(
        map(x => x?.channels || []),
        map(list =>
          list?.map(channel => {
            const item = new GetChannelWithMeV2Response(channel);
            return this.convertChannel(item, meChatUuid);
          })
        ),
        tap(channels => {
          this.updateChannel(channels);
          this.store.update({ loadedMineChannel: true });
        })
      );
  }

  getDetailsSequential(channelId: string, meChatUuid: string) {
    if (!this._mapFetchingChannel?.[channelId]) {
      this._mapFetchingChannel[channelId] = true;
      return this.getOne(channelId, meChatUuid).pipe(finalize(() => delete this._mapFetchingChannel[channelId]));
    } else {
      return this.query.selectEntity(channelId)?.pipe(
        filter(x => !!x),
        take(1)
      );
    }
  }

  getDetails(channelId: string, meChatUuid: string, isThread = false): Observable<Channel> {
    const params = new HttpParams().append('isThread', isThread);
    return this.http
      .get<Channel>(`/public/_tc/channel/${channelId}`, {
        params: params
      })
      .pipe(
        map(channel => new Channel(channel).withMeUuid(meChatUuid)),
        tap(
          channel => {
            this.updateChannel([channel]);
          },
          _ => this.convoNotFound.push(channelId)
        )
      );
  }

  getPersonalChannels(types: NameChannelPersonal[] | string[], meChatUuid: string): Observable<Channel[]> {
    return this.http
      .post<ChannelPersonalResponse>(`/public/v2/_tc/namespace/channel/getPersonal`, {
        // empty = all
        types: types
      })
      .pipe(
        map(resp => resp?.channels || {}),
        map(data => {
          const channels: Channel[] = [];
          Object.keys(data).forEach(key => {
            if (data[key] && !!data[key].details) {
              const model = new Channel({
                ...data[key].details,
                name: key,
                privacy: Privacy.private,
                type: ChannelType.PERSONAL
              }).withMeUuid(meChatUuid);
              channels.push(model);
            }
          });

          return channels;
        }),
        tap(channels => {
          this.updateChannel(channels);
          this.store.update({ getPersonalChannels: true });
        })
      );
  }

  getCloseThreadsByParent(req: RequestRangeThreadsByParent, meChatUuid: string) {
    return this.http
      .post<ResponseRangeThreads>(`/public/v2/_tc/namespace/channel/thread/rangeClosedByParent`, req)
      .pipe(
        map(x => new ResponseRangeThreads(x)),
        map(model => {
          const rangeThreadsParent = this.query.getChannelUiState(req.parentId)?.rangeThreadsParent || {};
          const channels = model.threads?.map(item => this.convertChannel(item, meChatUuid, true)) || [];
          if ((req.afterToSize === 0 && req.beforeFromSize === 0) || (!req.afterToSize && !req.beforeFromSize)) {
            // case: load first
            if (!req.to && !req.from) {
              if (!req.isAsc) {
                rangeThreadsParent.hasMoreBackward = channels.length !== 0;
              } else {
                rangeThreadsParent.hasMoreForward = channels.length !== 0;
              }
            }

            // case: load more top,auto DESC
            if (req.to && !req.from) {
              rangeThreadsParent.hasMoreBackward = channels.length !== 0;
            }

            // case: load more bottom,auto ASC
            if (req.from && !req.to) {
              rangeThreadsParent.hasMoreForward = channels.length !== 0;
            }
          } else if (req.from === req.to) {
            // case: jump to message, load 2 directions
            const index = channels.findIndex(x => x.id === req.from);
            rangeThreadsParent.hasMoreBackward = index !== 0;
            rangeThreadsParent.hasMoreForward = index <= channels.length - 1;
          }
          // TODO: more case, handle when use it
          this.updateChannel(channels);
          this.updateChannelViewState(req.parentId, {
            rangeThreadsParent: rangeThreadsParent
          });

          return model.withConvertChannel(channels);
        })
      );
  }

  getCloseThreadsByUser(req: RequestRangeThreadsByUser, meChatUuid: string) {
    return this.http.post<ResponseRangeThreads>(`/public/v2/_tc/namespace/channel/thread/rangeClosedByUser`, req).pipe(
      map(x => new ResponseRangeThreads(x)),
      map(model => {
        const rangeThreadsUser = { ...this.query.getState()?.rangeThreadsUser } || {};
        const channels = model.threads?.map(item => this.convertChannel(item, meChatUuid, true)) || [];
        if ((req.afterToSize === 0 && req.beforeFromSize === 0) || (!req.afterToSize && !req.beforeFromSize)) {
          // case: load first
          if (!req.to && !req.from) {
            if (!req.isAsc) {
              rangeThreadsUser.hasMoreBackward = channels.length !== 0;
            } else {
              rangeThreadsUser.hasMoreForward = channels.length !== 0;
            }
          }

          // case: load more top,auto DESC
          if (req.to && !req.from) {
            rangeThreadsUser.hasMoreBackward = channels.length !== 0;
          }

          // case: load more bottom,auto ASC
          if (req.from && !req.to) {
            rangeThreadsUser.hasMoreForward = channels.length !== 0;
          }
        } else if (req.from === req.to) {
          // case: jump to message, load 2 directions
          const index = channels.findIndex(x => x.id === req.from);
          rangeThreadsUser.hasMoreBackward = index !== 0;
          rangeThreadsUser.hasMoreForward = index <= channels.length - 1;
        }
        // TODO: more case, handle when use it
        this.updateChannel(channels);
        this.updateStateStore({
          rangeThreadsUser: rangeThreadsUser
        });

        return model.withConvertChannel(channels);
      })
    );
  }

  getActiveThreadsByChannel(parentId: string, meChatUuid: string) {
    return this.http
      .post<{ threads: GetChannelWithMeV2Response[] }>(`/public/v2/_tc/namespace/channel/thread/getActiveByParent`, {
        parentId: parentId
      })
      .pipe(
        map(x => x?.threads?.map(x => new GetChannelWithMeV2Response(x)) || []),
        map(list => list?.map(item => this.convertChannel(item, meChatUuid))),
        tap(channels => {
          this.updateChannel(channels);
          this.updateChannelViewState(parentId, {
            loadedActiveThread: true
          });
        })
      );
  }

  getMineByParent(parentId: string, meChatUuid: string) {
    return this.http
      .post<{ channels: GetChannelWithMeV2Response[] }>(`/public/v2/_tc/namespace/channel/getMinesByParent`, {
        parentId: parentId
      })
      .pipe(
        map(x => x?.channels?.map(x => new GetChannelWithMeV2Response(x)) || []),
        map(list => list?.map(item => this.convertChannel(item, meChatUuid))),
        tap(channels => {
          this.updateChannel(channels);
        })
      );
  }

  createThread(req: CreateThreadRequest, meChatUuid: string) {
    return this.http
      .post<{ thread: GetChannelWithMeV2Response }>(`/public/v2/_tc/namespace/channel/thread/create`, req)
      .pipe(
        map(x => x?.thread),
        map(x => new GetChannelWithMeV2Response(x)),
        map(item => this.convertChannel(item, meChatUuid)),
        tap(channel => {
          this.updateChannel([channel]);
        })
      );
  }

  updateRecentChannels(switchChannel: string) {
    return this.http
      .post<{ recentChannels: { channelId: string; millis: string }[] }>(
        `/public/v2/_tc/namespace/channel/switchChannel`,
        {
          channelId: switchChannel
        }
      )
      .pipe(
        map(x => x?.recentChannels || []),
        tap(recentChannel => {
          this.store.update({
            recentChannels: recentChannel?.map(
              x =>
                <RecentChannel>{
                  id: x.channelId,
                  date: +x.millis || new Date().getTime()
                }
            )
          });
        })
      );
  }

  addOrRemoveParticipants(channelId: string, req: UpdateChannelReq) {
    return this.http.post<void>(`/public/_tc/channel/${channelId}/participants`, req);
  }

  archivedOrUnarchiveChannel(channelId: string, isArchived) {
    return this.http.put<void>(`/public/_tc/channel/${channelId}/${isArchived ? 'archived' : 'unarchived'}`, {});
  }

  updateNameOrDescriptionChannel(channelId: string, req: UpdateChannelReq) {
    return this.http.put<void>(`/public/_tc/channel/${channelId}`, req);
  }

  copyMessage(req: CopyMessageRequest) {
    return this.http.post<{ message: ChatMessage }>(`/public/v2/_tc/namespace/message/copy`, req);
  }

  deleteCopy(req: CopyMessageRequest) {
    return this.http.post<{ message: ChatMessage }>(`/public/v2/_tc/namespace/message/deleteCopy`, req);
  }

  closeThread(threadId: string, parentId: string) {
    return this.http.post<{ message: ChatMessage }>(`/public/v2/_tc/namespace/channel/thread/close`, {
      threadId,
      parentId
    });
  }

  joinThread(threadId: string, parentId: string) {
    return this.http.post<{ message: ChatMessage }>(`/public/v2/_tc/namespace/channel/thread/join`, {
      threadId,
      parentId
    });
  }

  transferOwner(convoId: string, ownerId: string) {
    return this.http.post<{ message: ChatMessage }>(`/public/v2/_tc/namespace/channel/transferOwner`, {
      convoId,
      ownerId
    });
  }

  updateChannelViewState(channelId: string | string[], state: Partial<ChannelUI>) {
    this.store.ui.update(channelId, entity => ({
      ...entity,
      ...state
    }));
  }

  resetChannelViewStateHistory(channelId: string | string[]) {
    this.store.ui.update(channelId, entity => ({
      ...entity,
      loaded: false,
      loadedFirst: false,
      hasMore: false,
      toMillis: undefined,
      fromMillis: undefined
    }));
  }

  updateChannel(channels: Channel[]) {
    this.mappingUserToDm(channels);
    this.store.upsertMany(channels, { baseClass: Channel });
  }

  updateOneChannel(channel: Channel) {
    this.mappingUserToDm([channel]);
    this.store.upsert(channel.id, channel, { baseClass: Channel });
  }

  setActive(channelId: string | ID) {
    this.store.setActive(channelId);
  }

  removeActive(channelId: string | ID) {
    this.store.removeActive(channelId);
  }

  closeConversation(id: string) {
    if (!id) {
      return;
    }
    const channel = this.query.getEntity(id);
    if (!channel) {
      return;
    }

    if (channel.privacy === Privacy.private) {
      this.store.remove(id);
    }
  }

  markSeen(convoId: string, lastSeenMillis: number) {
    this.store.update(convoId, {
      unreadCount: 0,
      mentionCount: 0,
      lastSeenMillis: null
    });

    this.updateChannelViewState(convoId, {
      newMessage: null,
      lastSeenMsgID: null
    });
  }

  updateLastMessage(convoId: string, message: ChatMessage) {
    if (!this.query.hasEntity(convoId)) {
      return;
    }

    this.store.update(convoId, {
      lastMessage: new ChatMessage({
        ...message,
        buildMsg: <BuildMessageUI>{
          newByLastMessageChannel: true
        }
      })
    });
  }

  updateStateStore(data: Partial<ChannelState>) {
    this.store.update(data);
  }

  updateNavigateToMsg(channelId: string, messageId: string) {
    const current = this.query.ui.getEntity(channelId)?.jumpMessageId;
    if (current === messageId) {
      // reset value
      this.store.ui.update(channelId, {
        jumpMessageId: null
      });
    }
    this.store.ui.update(channelId, {
      jumpMessageId: messageId
    });
  }

  removeAllThreadByParent(parentId: string) {
    return this.store.remove(entity => entity.isThreadChat && entity.parentId === parentId);
  }

  removeThreadsCloseNotMe() {
    return this.store.remove(
      entity => entity.isThreadChat && (entity.isTemporary || (entity.unreadCount < 1 && !entity.closedL2))
    );
  }

  removeChannels(ids: string[]) {
    this.store.remove(ids);
  }

  private convertChannel(item: GetChannelWithMeV2Response, meChatUuid: string, isTemporary = false) {
    const channel = <Partial<Channel>>{
      ...item?.details,
      isTemporary: isTemporary
    };

    if (item.lastMessage) channel.lastMessage = item.lastMessage;
    if (item.parent) channel.parent = item.parent;
    if (item.closedL2) channel.closedL2 = item.closedL2;
    if (item.users) channel.participants = item.users?.map(x => <IParticipant>{ userID: x.id, role: x.role });
    if (item.estimatedMessagesCount) channel.emc = item.estimatedMessagesCount;
    if (item.seenStatus?.lastSeenMillis) channel.lastSeenMillis = item.seenStatus.lastSeenMillis;
    if (item.seenStatus?.mentionCount) channel.mentionCount = item.seenStatus.mentionCount;
    if (item.seenStatus?.unreadCount) channel.unreadCount = item.seenStatus.unreadCount;
    if (item.archived?.at) channel.archivedAt = item.archived.at;
    if (item.archived?.by) channel.archivedBy = item.archived.by;

    return new Channel(channel).withMeUuid(meChatUuid);
  }

  private mappingUserToDm(channels: Channel[]) {
    const dm = channels
      .filter(x => x.type === ChannelType.dm && !x.nameUserDirect)
      .map(entity => {
        const user = this.userQuery.getUserByChatUuid(entity?.directChatUsers?.otherUuid);
        return <Channel>{
          id: entity.id,
          nameUserDirect: user?.displayName,
          name: user?.displayName || entity.name,
          isBot: user?.isBot,
          isDisableUser: (user && user.memberStatus === MemberStatus.disabled) || !user
        };
      });
    if (dm.length > 0) this.updateChannel(dm);
  }
}
