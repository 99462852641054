export class CacheMedia {
  key: string;
  url: string;
  time: number;

  constructor(obj: Partial<CacheMedia>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export const UUID_V4_REGEX = '[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}';
