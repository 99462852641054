import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TxnChannel } from '../txn/txn.model';
import { ContactBlacklistState, ContactBlacklistStore } from './contact-blacklist.store';

@Injectable({ providedIn: 'root' })
export class ContactBlacklistQuery extends QueryEntity<ContactBlacklistState> {
  constructor(protected override store: ContactBlacklistStore) {
    super(store);
  }

  selectItem(channel: TxnChannel, contact: string) {
    return this.selectEntity(`${channel}_${contact}`);
  }

  getItem(channel: TxnChannel, contact: string) {
    return this.getEntity(`${channel}_${contact}`);
  }
}
