import { randomGuid } from '../../plain_func.util';
import { MatchType, PREFIX_LINK } from '../model/match.model';
import { OutputProcessMessage, WidgetCompile, WidgetMatched } from '../model/output-message.model';
import { RegExpPattern } from '../model/patterns.const';

// link href
export class LinkMarkdownCompiler implements WidgetCompile {
  constructor() {}

  preCompile(data: OutputProcessMessage): WidgetMatched[] {
    const linkMatched = data.text.match(RegExpPattern.URL1);
    const matches: WidgetMatched[] = [];
    if (linkMatched) {
      linkMatched.forEach(item => {
        const replaceString: string = randomGuid();
        data.text = data.text.replace(item, replaceString);
        matches.push({ random: replaceString, text: item });
      });
    }
    return matches;
  }

  compile(matches: WidgetMatched[], data: OutputProcessMessage) {
    matches.forEach(item => {
      let replaceItem = item.text;

      if (item.text.startsWith(PREFIX_LINK)) {
        const path = item.text.replace(PREFIX_LINK, '');
        data.isTriggerDirective = true;
        replaceItem = `<a class='highlight-link cursor-pointer cannotRender'
                  href=${item.text}
                  data-path="${path}"
                  data-cannot-render='${MatchType.LINK}'>${item.text}</a>`;
      } else {
        replaceItem = `<a href="${item.text}" target="_blank">${item.text}</a>`;
      }

      data.text = data.text.replace(item.random, replaceItem);
    });
  }
}
