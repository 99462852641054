import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { GroupHolidaysState, GroupHolidaysStore } from './group-holidays.store';

@Injectable({ providedIn: 'root' })
export class GroupHolidaysQuery extends QueryEntity<GroupHolidaysState> {
  constructor(protected override store: GroupHolidaysStore) {
    super(store);
  }

  // Public holiday in Org management
  getGroupRules() {
    return this.selectAll({
      filterBy: g => g.groupName !== 'Default',
      sortBy: 'createdDate',
      sortByOrder: Order.DESC
    });
  }
}
