import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Balance } from './balance.model';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  constructor(private http: HttpClient) {}

  getMyBalanceByYear(year: number): Observable<Balance[]> {
    const params = new HttpParams().set('year', year);
    return this.http
      .get<Balance[]>(`leave/private/v1/me/balances`, { params: params })
      .pipe(map(res => res.map(r => new Balance(r))));
  }

  getBalanceForUserByYear(identityUuid: string, year: number): Observable<Balance[]> {
    const params = new HttpParams().set('staffIdentityUuid', identityUuid).set('year', year);
    return this.http
      .get<Balance[]>(`leave/private/v1/iam/balances`, { params: params })
      .pipe(map(res => res.map(r => new Balance(r))));
  }
}
