import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonLoadingModule, SharedUiMaterialModule } from '@b3networks/shared/ui/material';
import { SSOLoginButtonComponent } from './s-s-o-login-button.component';

@NgModule({
  imports: [CommonModule, SharedUiMaterialModule, ButtonLoadingModule],
  exports: [SSOLoginButtonComponent],
  declarations: [SSOLoginButtonComponent]
})
export class SSOLoginButtonModule {}
