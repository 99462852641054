import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ORG_CONNECT_STATUS } from 'libs/portal/org/feature/org-link/src/lib/shared/constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrgConnectionState, OrgConnectionStore } from './org-connection.store';
import { RecipientOrInitiator } from './org-link.model';

@Injectable({ providedIn: 'root' })
export class OrgConnectionQuery extends QueryEntity<OrgConnectionState> {
  constructor(protected override store: OrgConnectionStore) {
    super(store);
  }

  getOrgShortNameByUuid(uuid: string): Observable<string> {
    return this.selectAll().pipe(
      map(
        data =>
          data
            .flatMap(({ initiator, recipient }) => [
              { uuid: initiator?.uuid, shortName: initiator?.shortName },
              { uuid: recipient?.uuid, shortName: recipient?.shortName }
            ])
            .find(item => item?.uuid?.includes(uuid))?.shortName
      )
    );
  }

  selectActiveOrgConnect() {
    return this.selectAll({
      filterBy: entity => entity.status === ORG_CONNECT_STATUS.active
    });
  }

  selectListOrgConnect(exceptOrgUuid: string) {
    return this.selectActiveOrgConnect().pipe(
      map(orgConnection => {
        return orgConnection.reduce<RecipientOrInitiator[]>((orgs, org) => {
          orgs.push(org.initiator);
          orgs.push(org.recipient);
          return [...new Set(orgs)].filter(o => !!o.name && o.uuid !== exceptOrgUuid);
        }, []);
      })
    );
  }
}
