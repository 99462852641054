import { FormControl, Validators } from '@angular/forms';

import { PasswordPolicy } from '@b3networks/api/auth';
import { PatternConfig, PolicyName } from './password-policy.model';
import { PASSWORD_POLICIES, REGEX_LEAST_A_LETTER, REGEX_LEAST_A_NUMBER } from './constant';

export function setDefaultValidators(ctrl: FormControl) {
  const validators = [
    Validators.required,
    Validators.minLength(6),
    customPatternValid({ pattern: REGEX_LEAST_A_NUMBER, name: PolicyName.numberRequired }),
    customPatternValid({ pattern: REGEX_LEAST_A_LETTER, name: PolicyName.letterRequired })
  ];
  ctrl.setValidators(validators);
  ctrl.updateValueAndValidity();
}

export function updateValidators(ctr: FormControl, passwordPolicy: PasswordPolicy) {
  const validators = PASSWORD_POLICIES.reduce(
    (acc, { name, regex }) => {
      if (passwordPolicy[name]) {
        acc.push(customPatternValid({ pattern: regex, name: name }));
      }
      return acc;
    },
    [Validators.required]
  );
  if (passwordPolicy[PolicyName.minimumLength]) {
    validators.push(Validators.minLength(passwordPolicy[PolicyName.minimumLength]));
  }
  if (passwordPolicy[PolicyName.maximumLength]) {
    validators.push(Validators.maxLength(passwordPolicy[PolicyName.maximumLength]));
  }

  ctr.setValidators(validators);
  ctr.updateValueAndValidity();
}

export function customPatternValid(config: PatternConfig) {
  return (control: FormControl) => {
    const matched = control.value?.match(config.pattern);
    return !matched ? { [config.name]: true } : null;
  };
}
