import { HttpHeaders } from '@angular/common/http';
import { X_B3_HEADER } from '../constants/constants';

export const CUSTOM_HEADER_UTIL = 'custom_header_helper';

export class CustomHeaderUtil {
  static _onBehalfOfCustomerOrgUuid: string;
  static readonly _customHeaders: { [key: string]: string } = {};

  static get customHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    Object.keys(CustomHeaderUtil._customHeaders)
      .filter(h => h && CustomHeaderUtil._customHeaders[h])
      .forEach(h => {
        headers = headers.set(h, CustomHeaderUtil._customHeaders[h]);
      });

    return headers;
  }

  static get onBelfOfCustomerHeader() {
    return CustomHeaderUtil.customHeaders;
  }

  static withOnBehalfOfCustomer(orgUuid: string) {
    CustomHeaderUtil._onBehalfOfCustomerOrgUuid = orgUuid;
    this._customHeaders[X_B3_HEADER.orgUuid] = orgUuid;
  }

  static setHeader(header, value: string) {
    CustomHeaderUtil._customHeaders[header] = value;
  }
}

export interface CustomerRouteParams {
  fromSeller?: boolean | undefined;
}
