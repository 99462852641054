import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from '@b3networks/api/common';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { IAMActionMappedPG, PermissionGroup } from '../iam/iam-group.model';
import { MembersAssigned, VerifyIAMReq } from '../member/member';
import { IAMAssignments, IAMPermission } from './iam.model';
import { IamStore } from './iam.store';
import { PolicyDocument } from './policty-document.model';

@Injectable({
  providedIn: 'root'
})
export class IamService {
  constructor(
    private http: HttpClient,
    private store: IamStore
  ) {}

  fetchAllPermissions(): Observable<IAMPermission[]> {
    return this.http.get<IAMPermission[]>(`auth/private/v1/iam/all`).pipe(
      map(list => list.map(permission => new IAMPermission(permission))),
      tap(permissions => this.store.update({ supportingPermissions: permissions }))
    );
  }

  getAllSupportingGroups() {
    return this.http
      .get<PermissionGroup[]>(`auth/private/v1/iam/group/all`)
      .pipe(tap(groups => this.store.update({ supportingPermissionGroups: groups })));
  }

  getMappedActionsByGroupID(groupID: string): Observable<IAMActionMappedPG[]> {
    return this.http.get<IAMActionMappedPG[]>(`auth/private/v1/iam/group/${groupID}/actions`).pipe(
      tap(actions => {
        this.store.update(state => {
          const mapped = state.supportingActionMappingPG || {};
          mapped[groupID] = actions;
          return { ...state, supportingActionMappingPG: mapped };
        });
      })
    );
  }

  verifyUser(requestBody: Partial<VerifyIAMReq>): Observable<void> {
    return this.http.post<void>('auth/private/v1/iam/verify', requestBody);
  }

  getPermissionsV2(): Observable<any> {
    return this.http.get<any>(`auth/private/v1/self/permissions`);
  }

  ///
  ///SELF PERMISSION ASSIGNED FOR ORGANIZATION
  getOrganizationGrantedPolicy(orgUuid: string) {
    const loadedPolicy = this.store.getValue().orgGrantedPolicy;
    if (loadedPolicy?.orgUuid !== orgUuid) {
      this.store.update({ orgGrantedPolicy: null });
    }

    const req$ = this.http.get<PolicyDocument>(`/auth/private/v1/iam/policies/self/organization`).pipe(
      map(org => new PolicyDocument(org).withOrgUuid(orgUuid).cleanupPolicies()),
      tap(org => this.store.update({ orgGrantedPolicy: org })),
      shareReplay(1)
    );

    if (loadedPolicy?.orgUuid === orgUuid) {
      return of(loadedPolicy);
    } else {
      return req$;
    }
  }

  // domain policy should call one time
  getDomainGrantedPolicy(): Observable<PolicyDocument> {
    const req$ = this.http.get<PolicyDocument>(`/auth/private/v1/iam/policies/self/domain`).pipe(
      map(res => new PolicyDocument(res)),
      tap(iam => this.store.update({ domainGrantedPolicy: iam })),
      shareReplay(1)
    );

    if (this.store.getValue().domainGrantedPolicy != null) {
      return of(this.store.getValue().domainGrantedPolicy);
    } else {
      return req$;
    }
  }

  getMembersAssigned(requestBody: Partial<MembersAssigned>, pageable?: Pageable): Observable<IAMAssignments[]> {
    return this.http.post<any>(
      `auth/private/v1/iam/assignments/members?page=${pageable.page}&size=${pageable.perPage}`,
      requestBody
    );
  }
}
