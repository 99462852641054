import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { B3Sentry, TESTING_DOMAIN } from '@b3networks/shared/common';
import { enableAkitaProdMode } from '@datorama/akita';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
  if (location.host !== atob(TESTING_DOMAIN)) {
    console.log = function () {
      // donothing
    };
  }
}

let tabUuid;
try {
  tabUuid = window?.['tabUuid'];
} catch (error) {}
B3Sentry.init({
  nameApp: 'Portal Base',
  enabled: environment.enableSentry,
  debug: !environment.production,
  browserId: `PortalBase_${tabUuid || new Date().getTime()}`,
  isPortal: true
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

window.addEventListener('load', function () {
  (<HTMLElement>document.querySelector('.app-loading')).style.display = 'none';
});
