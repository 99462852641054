import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InboundRuleState, InboundRuleStore } from './inbound-rule.store';

@Injectable({ providedIn: 'root' })
export class InboundRuleQuery extends QueryEntity<InboundRuleState> {
  constructor(protected override store: InboundRuleStore) {
    super(store);
  }
}
