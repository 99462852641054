import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from '@b3networks/api/common';
import { Contact } from '@b3networks/api/contact';
import { Observable, map, tap } from 'rxjs';
import { Notification, QueryNotificationReq, ResponseNotifications } from './notifications.model';
import { NotificationsQuery } from './notifications.query';
import { NotificationsStore } from './notifications.store';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private http: HttpClient, private store: NotificationsStore, private query: NotificationsQuery) {}

  getMine(meIdentity: string, pageable: Pageable): Observable<ResponseNotifications> {
    let params = new HttpParams();
    Object.keys(pageable).forEach(key => {
      if (pageable[key]) {
        params = params.append(key, String(pageable[key]));
      }
    });
    return this.http
      .get<{
        notifications: Notification[];
        count: number;
        lastUpdated: number;
        unread: number;
      }>('/inbox/private/v1/notifications/specific', { params: params })
      .pipe(
        map(resp => {
          this.store.update({
            count: resp.count || 0,
            lastUpdated: resp.lastUpdated,
            unread: resp.unread || 0
          });
          const contacts = resp?.notifications?.map(x => new Contact(x.customer));

          return <ResponseNotifications>{
            contacts,
            notifications: resp?.notifications?.map(item => new Notification(item).withMe(meIdentity)) || []
          };
        }),
        tap(data => {
          this.store.set(data.notifications);
        })
      );
  }

  queryNotifications(meIdentity: string, req: QueryNotificationReq, pageable: Pageable) {
    let params = new HttpParams();
    Object.keys(pageable).forEach(key => {
      if (pageable[key]) {
        params = params.append(key, String(pageable[key]));
      }
    });
    return this.http
      .post<{
        notifications: Notification[];
        count: number;
        lastUpdated: number;
        unread: number;
      }>('/inbox/private/v1/notifications/specific', req, { params: params })
      .pipe(
        map(resp => {
          this.store.update({
            count: resp.count || 0,
            lastUpdated: resp.lastUpdated,
            unread: resp.unread || 0
          });
          const contacts = resp?.notifications?.map(x => new Contact(x.customer));

          return <ResponseNotifications>{
            contacts,
            notifications: resp?.notifications?.map(item => new Notification(item).withMe(meIdentity)) || []
          };
        }),
        tap(data => {
          this.store.set(data.notifications);
        })
      );
  }

  notificationRead(lastUpdated, clickedAll) {
    const body = {
      clickedAll: clickedAll,
      lastUpdated: lastUpdated
    };
    return this.http.post(`/inbox/private/v1/notifications/specific/read`, body).pipe(tap(() => {}));
  }

  notificationClicked(clickedAll: boolean, notificationIds: number[], lastUpdated: number) {
    const body = {
      clickedAll: clickedAll,
      notificationIds: notificationIds,
      lastUpdated: lastUpdated
    };
    return this.http.post(`/inbox/private/v1/notifications/specific/clicked`, body).pipe(
      tap(() => {
        this.store.upsertMany(
          notificationIds?.map(id => <Notification>{ id: id, isClicked: true }),
          { baseClass: Notification }
        );
      })
    );
  }

  addNotification2Store(item: Notification, meIdentity: string) {
    const noti = new Notification({ ...item }).withMe(meIdentity);
    this.store.upsertMany([noti], { baseClass: Notification });
    return this.query.getEntity(item.id);
  }
}
