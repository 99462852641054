export const BACKBONE_SUPPORTED = ['txnUuid', 'inboxUuid', 'inboxName'];

export class NoteTemplateV2 {
  templateUuid?: string;
  templateCode?: string;
  title?: string;
  updated?: number;
  draft?: boolean;
  backboneFields?: BackboneFields[];
  userDefinedFields?: UserDefinedField[];
  formOrder?: string[];
  reportOrder?: string[];
  actionName?: string;
  fields?: UserDefinedField[]; // new structure form
  archived?: boolean;

  constructor(obj: Partial<NoteTemplateV2>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj?.userDefinedFields) {
        this.userDefinedFields = obj.userDefinedFields?.map(x => new UserDefinedField(x));
      }
    }
  }
}

export class UserDefinedField {
  type: FieldType;
  key: string;
  label: string;
  description?: string;
  isRequired?: boolean;
  isNotRequired?: boolean;
  options?: string[];
  maxSelection?: number;
  minSelection?: number;
  display?: { [key: number]: { color: string; default: boolean } };

  constructor(obj: Partial<UserDefinedField>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  get isBackbone() {
    return BACKBONE_SUPPORTED.includes(this.key);
  }
}

export interface BackboneFields {
  key: BackboneFieldsType;
  label?: string;
  description?: string;
  isRequired?: boolean;
  type?: string;
}

export enum FormType {
  popup = 'agentPopupNote',
  survey = 'survey',
  inbox = 'inbox',
  inboxSLA = 'inboxSLA',
  programmableFlow = 'programmableFlow'
}

export enum FieldType {
  options = 'options',
  text = 'text',
  textarea = 'textarea',
  double = 'double',
  long = 'long',
  singleChoice = 'singleChoice',
  multipleChoice = 'multipleChoice',
  checkbox = 'checkbox',
  dateOnly = 'dateOnly',
  dateAndTime = 'dateAndTime',
  time = 'time'
}

export enum BackboneFieldsType {
  txnUuid = 'txnUuid',
  inboxUuid = 'inboxUuid',
  inboxName = 'inboxName'
}

export enum DEFAULT_SUPPORTED_TYPE {
  options = 'Option',
  text = 'Short Answer',
  textarea = 'Paragraph',
  double = 'Decimal Number',
  long = 'Integer Number',
  singleChoice = 'Single choice',
  multipleChoice = 'Multiple choice',
  checkbox = 'Confirmation box',
  dateOnly = 'Date only',
  dateAndTime = 'Date and time'
}

export enum ActionForm {
  create = 'Create',
  editDraft = 'Edit Draft',
  viewDetail = 'View Detail'
}

export interface QueryDataTemplate {
  columns: BackboneFields[];
  data: [];
}

export interface AgentPopupNoteData {
  [key: string]: any;
}

export class QueryAgentPopupNoteResp {
  columns: UserDefinedField[];
  data: AgentPopupNoteData[];
  constructor(obj: Partial<QueryAgentPopupNoteResp>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  getNoteData(item: UserDefinedField) {
    return this.data?.[0]?.[item.key];
  }
}

export interface NoteReq {
  templateCode: string;
  templateUuid: string;
  values: AgentPopupNoteData;
}

export interface DownloadReq {
  start: string;
  end: string;
}
