<div
  class="container flex justify-start items-center gap-2"
  [ngClass]="{
    disabled: isBot,
    large: isLarge,
    small: isSmall,
    'small-v2': isSmallV2
  }"
>
  <ng-container *ngIf="!avatarUrl; else hasAvatar">
    <mat-icon *ngIf="isBot" class="icon-smart-toy">smart_toy</mat-icon>
    <span *ngIf="!isBot" class="no-avatar flex justify-center items-center" [class.large]="isLarge">
      {{ displayName | firstWord }}
      <ng-container *ngTemplateOutlet="showStatus"></ng-container>
    </span>
  </ng-container>
  <ng-template #hasAvatar>
    <div class="avatar" [class.large]="isLarge" [style.background-image]="photoUrl">
      <ng-container *ngTemplateOutlet="showStatus"></ng-container>
    </div>
  </ng-template>

  <div *ngIf="showDisplayName" class="flex">
    <span [innerHTML]="displayName | highlight: key" class="displayName text-ellipsis"> </span>
    <span *ngIf="isMe">&nbsp;(Me)&nbsp;</span>
  </div>
</div>

<ng-template #showStatus>
  <mat-icon *ngIf="showStatus" class="status material-icons-outlined fill" [class]="'status__' + status">
    lens
  </mat-icon>
</ng-template>
