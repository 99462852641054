import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { HistoryTxn } from './history-txn.model';

export interface HistoryTxnState extends EntityState<HistoryTxn>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox-inboxes', idKey: 'uuid' })
export class HistoryTxnStore extends EntityStore<HistoryTxnState> {
  constructor() {
    super();
  }
}
