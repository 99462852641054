import { map, Subject } from 'rxjs';

export const MAX_RECONNECT_TIME = 5;

export class ReconnectChatStragery {
  reconnectTimes: number;
  maxReconnect: number;
  isRun: boolean;

  constructor(obj?: Partial<ReconnectChatStragery>) {
    if (obj) {
      Object.assign(this, obj);
      if (!this.reconnectTimes) {
        this.reconnectTimes = 0;
      }
    }
  }

  get maxRetry() {
    return this.maxReconnect || MAX_RECONNECT_TIME;
  }

  get waitingTime() {
    return this.reconnectTimes < 2 ? 5 * 1000 : 10 * 1000;
  }

  get canReconnect() {
    return this.reconnectTimes < this.maxRetry;
  }

  increaseReconnectTime() {
    this.isRun = true;
    this.reconnectTimes = (this.reconnectTimes || 0) + 1;
  }

  reset() {
    this.isRun = false;
    this.reconnectTimes = 0;
  }
}

export class ReconnectChatStrageryV2 extends ReconnectChatStragery {
  private _reconnectTimes$ = new Subject<number>();

  get isMaxReconnect$() {
    return this._reconnectTimes$.asObservable().pipe(map(() => !this.canReconnect));
  }

  constructor(obj?: Partial<ReconnectChatStragery>) {
    super(obj);
    if (obj) {
      this._reconnectTimes$.next(this.reconnectTimes);
    }
  }

  // remember destroy subject if use in component
  onDestroy() {
    this._reconnectTimes$.complete();
  }

  override increaseReconnectTime(): void {
    super.increaseReconnectTime();
    this._reconnectTimes$.next(this.reconnectTimes);
  }

  override reset(): void {
    super.reset();
    this._reconnectTimes$.next(this.reconnectTimes);
  }
}
