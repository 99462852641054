import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { claimOwnerReq } from './teamchat.model';

@Injectable({
  providedIn: 'root'
})
export class TeamChatService {
  constructor(private http: HttpClient) {}

  claimOwner(data: claimOwnerReq) {
    return this.http.post(`/inbox/private/v1/teamchat/_claimOwner`, data);
  }
}
