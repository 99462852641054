import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'b3n-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule]
})
export class ExpiredComponent {}
