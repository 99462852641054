import { Pipe, PipeTransform } from '@angular/core';
import { addSeconds, intervalToDuration } from 'date-fns';

// seconds or milliseconds to duration (4h 14m 7s)
@Pipe({
  name: 'durationV2',
  standalone: true
})
export class DurationV2Pipe implements PipeTransform {
  transform(duration: number, unit: string = 'second') {
    if (!duration) {
      duration = 0;
    }

    let durationInSec;

    if (unit === 'millisecond') {
      durationInSec = Math.round(duration / 1000);
    } else {
      durationInSec = Math.round(duration);
    }

    const dateDuration = intervalToDuration({
      start: new Date(),
      end: addSeconds(new Date(), durationInSec)
    });

    if (durationInSec >= 24 * 60 * 60) {
      return dateDuration.days + 'd ' + dateDuration.hours + 'h ' + dateDuration.minutes + 'm ';
    } else if (durationInSec >= 60 * 60) {
      return dateDuration.hours + 'h ' + dateDuration.minutes + 'm ' + dateDuration.seconds + 's';
    } else if (durationInSec >= 60) {
      return dateDuration.minutes + 'm ' + dateDuration.seconds + 's';
    }
    return dateDuration.seconds + 's';
  }
}
