import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { QueueInfo } from './queue.model';

export interface QueuesState extends EntityState<QueueInfo> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'queue-queues', idKey: 'uuid' })
export class QueuesStore extends EntityStore<QueuesState> {
  constructor() {
    super();
  }
}
