import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { DownloadReq, NoteReq, NoteTemplateV2, QueryAgentPopupNoteResp, QueryDataTemplate } from './form.model';
import { FormStore } from './form.store';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(private http: HttpClient, private store: FormStore) {}

  getNoteTemplatesV2(templateCode: string) {
    return this.http.get<NoteTemplateV2[]>(`/data/private/v2/noteTemplate/${templateCode}/list`).pipe(
      map(noteTemplate =>
        noteTemplate.map(
          i =>
            new NoteTemplateV2({
              ...i,
              templateCode: templateCode
            })
        )
      ),
      tap(notes => this.store.upsertMany(notes, { baseClass: NoteTemplateV2 }))
    );
  }

  getNoteTemplateV2Detail(template: NoteTemplateV2) {
    const { templateCode, templateUuid } = template;
    return this.http.get<NoteTemplateV2>(`/data/private/v2/noteTemplate/${templateCode}/${templateUuid}/config`).pipe(
      map(
        noteTemplate =>
          new NoteTemplateV2({
            ...noteTemplate,
            templateCode: templateCode,
            templateUuid: templateUuid
          })
      ),
      tap(notes => this.store.upsertMany([notes], { baseClass: NoteTemplateV2 }))
    );
  }

  getNoteTemplateV3Detail(templateCode: string, templateUuid: string) {
    return this.http.get<NoteTemplateV2>(`/data/private/v2/noteTemplate/${templateCode}/${templateUuid}/form`).pipe(
      map(
        noteTemplate =>
          new NoteTemplateV2({
            ...noteTemplate,
            templateCode: templateCode,
            templateUuid: templateUuid,
            title: noteTemplate.title,
            userDefinedFields: noteTemplate?.fields || [],
            fields: noteTemplate?.fields || []
          })
      ),
      tap(notes => this.store.upsertMany([notes], { baseClass: NoteTemplateV2 }))
    );
  }

  createNoteTemplateV2(templateCode: string, noteTemplate: Partial<NoteTemplateV2>) {
    const { templateUuid, draft } = noteTemplate;
    const params = new HttpParams().set('draft', draft);
    return this.http.put(`/data/private/v2/noteTemplate/${templateCode}/${templateUuid}`, noteTemplate, { params });
  }

  getBackboneTemplate(templateCode: string) {
    return this.http.get(`/data/private/v2/noteTemplate/${templateCode}`);
  }

  deleteNoteTemplateV2(template: NoteTemplateV2) {
    const { templateCode, templateUuid } = template;
    return this.http.delete(`/data/private/v2/noteTemplate/${templateCode}/${templateUuid}`);
  }

  getDataFromTemplate(templateCode: string, templateUuid: string, body) {
    return this.http
      .post(`/data/private/v2/noteTemplate/${templateCode}/${templateUuid}/query`, body)
      .pipe(map(data => data as QueryDataTemplate));
  }

  download(templateCode: string, templateUuid: string, req: DownloadReq) {
    return this.http.post(`/data/private/v2/noteTemplate/${templateCode}/${templateUuid}/csv`, req, {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

  getAgentPopupNote(templateUuid: string, noteUuid: string) {
    return this.http
      .get<QueryAgentPopupNoteResp>(`/data/private/v2/noteTemplate/agentPopupNote/${templateUuid}/${noteUuid}`)
      .pipe(map(res => new QueryAgentPopupNoteResp(res)));
  }

  //* isMerge true:
  // if there's an existing note, the data will be merged based on RFC 7386 -- if the value is null, it means to delete the existing value. Omit field to skip update.
  // fields with isRequired true cannot be deleted.
  // isRequired is not checked if a field is omitted. Important: use merge=false for isRequired validation
  // time field is still required

  //* isMerge false:
  // if there's an existing note, the existing data is discarded.
  // fields with isRequired true must not be null or omitted.
  // UI is responsible to fill backbone fields -- usually by hardcoding based on business logic
  updateAgentNote(noteUuid: string, isMerge: boolean, body: NoteReq) {
    return this.http.post(`/data/private/v2/note/${noteUuid}?merge=${isMerge}`, body);
  }
}
