import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FormDataState, FormDataStore } from './form-data.store';

@Injectable({ providedIn: 'root' })
export class FormDataQuery extends QueryEntity<FormDataState> {
  constructor(protected override store: FormDataStore) {
    super(store);
  }

  getFormData(txnUuid: string) {
    return this.getEntity(txnUuid);
  }

  selectFormData(txnUuid: string) {
    return this.selectEntity(txnUuid);
  }
}
