import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { InboundRule } from './inbound-rule.model';
export interface InboundRuleState extends EntityState<InboundRule> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbound-rule', idKey: 'id' })
export class InboundRuleStore extends EntityStore<InboundRuleState> {
  constructor() {
    super();
  }
}
