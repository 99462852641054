import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SharedResource } from './shared-resources.model';

export interface SharedResourcesState extends EntityState<SharedResource> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_shared_resources', idKey: 'resourceId' })
export class SharedResourcesStore extends EntityStore<SharedResourcesState> {
  constructor() {
    super();
  }
}
