import {
  Breadcrumbs,
  BrowserTracing,
  Dedupe,
  FunctionToString,
  GlobalHandlers,
  LinkedErrors,
  TryCatch,
  getActiveTransaction,
  init,
  routingInstrumentation,
  setContext
} from '@sentry/angular-ivy';
import { X } from '../x/x-app';

export const DSN_DEFAULT = 'https://1f5502c5f3f13af6b42e17656b34472e@o132261.ingest.sentry.io/4506341742608384';
export const SENTRY_DOMAIN_SUPPORTED = ['portal.hoiio.net', 'portal.b3networks.com', 'localhost'];

export class B3Sentry {
  static enabledSentryLog: boolean;
  static init(config: SentryConfiguration) {
    if (SENTRY_DOMAIN_SUPPORTED.includes(location.hostname)) {
      if (config && config.enabled) {
        console.log('initial sentry');
        init({
          dsn: config.dns || DSN_DEFAULT,
          environment: config.nameApp,
          debug: config.debug,
          integrations: [
            new Breadcrumbs({
              dom: true,
              history: true,
              sentry: true,
              console: false,
              fetch: true,
              xhr: true
            }),
            new Dedupe(),
            new FunctionToString(),
            new GlobalHandlers({
              onerror: true,
              onunhandledrejection: true
            }),
            new LinkedErrors(),
            new TryCatch({
              setTimeout: true,
              setInterval: true,
              requestAnimationFrame: true,
              XMLHttpRequest: false
            }),
            new BrowserTracing({
              routingInstrumentation: routingInstrumentation
            })
          ],
          // maxBreadcrumbs: 100,
          tracesSampleRate: 1.0
          // beforeSend(event) {
          //   console.log('beforeSend: ', event);
          //   return event;
          // }
        });

        if (!config.isPortal) {
          B3Sentry.setContextUser('info client', {
            orgUuid: X.orgUuid,
            pathname: location.pathname,
            browserId: config.browserId
          });
        }

        console.log(getActiveTransaction());
      }
      B3Sentry.enabledSentryLog = config?.enabled;
    }
  }

  static setContextUser(
    section: string,
    context: {
      [key: string]: string;
    }
  ) {
    setContext(section, context);
  }
}

export interface SentryConfiguration {
  dns?: string;
  isPortal?: boolean;
  nameApp: string;
  enabled: boolean;
  debug: boolean;
  browserId: string;
}
