export interface TfaInfo {
  tfaEnabled: boolean;
  recoveryKey: string;
  activationDate: number;
  totpActivated: boolean;
}

export enum TFAIntentType {
  LOGIN = 'LOGIN',
  ENABLE_TFA = 'ENABLE_TFA',
  DISABLE_TFA = 'DISABLE_TFA',
  REFRESH_RECOVERY_KEY = 'REFRESH_RECOVERY_KEY'
}

export interface SendOtpCodeRequest {
  intent: TFAIntentType;
}

export interface SendOtpCodeResponse {
  sanitizedCode: string;
  otpId: string;
}

export interface Toggle2FaRequest {
  tfaSession: string;
  default_method?: 'EMAIL' | 'TOTP';
}

export interface Toggle2FaResponse {
  recoveryKey: string;
}

export enum Verify2FaType {
  system = 'system_generated',
  recover = 'recovery_key',
  totp = 'totp'
}

export interface Verify2FaRequest {
  fromMobile?: boolean;
  domain?: string;
  otpId: string;
  otpCode: string;
  recoveryKey?: string;
  type: Verify2FaType;
}

export interface Verify2FaResponse {
  tfaSession: string;
}

export interface RefreshRecoveryKeyRequest {
  tfaSession: string;
}

export interface RefreshRecoveryKeyResponse {
  recoveryKey: string;
}

export interface TotpRequest {
  secret: string;
  code: string;
}

export interface TotpResponse {
  uri: string;
  secret: string;
}
