<ng-container *ngIf="notification">
  <div
    (@fadeAnimation.done)="onFadeFinished($event)"
    [@fadeAnimation]="{ value: animationState, params: { fadeIn: 150, fadeOut: 250 } }"
    class="container"
    *ngIf="{ countdown: countdown$ | async } as time"
    [class]="notification.backgroundColor"
  >
    <div class="toast">
      <div class="toast-header">
        <div class="toast-header__message" [innerHTML]="notification.displayText"></div>
        <mat-icon
          class="icon s-16"
          *ngIf="[TxnChannel.call, TxnChannel.supportCenter].includes(notification.channel)"
          (click)="closeNotification()"
        >
          close
        </mat-icon>
      </div>

      <ng-container [ngSwitch]="notification.channel">
        <ng-container *ngSwitchCase="TxnChannel.call">
          <a
            href="void:javascript"
            *ngIf="
              !(
                !notification.inboxUuid &&
                [NotificationType.assigning, NotificationType.transferring].includes(notification.type)
              )
            "
            (click)="showDetailTxn()"
          >
            View details
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="TxnChannel.livechat">
          <ng-container *ngTemplateOutlet="infoCustomer"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="TxnChannel.whatsapp">
          <ng-container *ngTemplateOutlet="infoCustomer"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="TxnChannel.supportCenter">
          <a href="void:javascript" (click)="showDetailTxn()"> View details </a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="notification.channel !== TxnChannel.call">
        <div *ngIf="time.countdown > 0" class="progress-container">
          <div class="progress-relative">
            <mat-progress-spinner
              [color]="time.countdown < 10 ? 'warn' : 'primary'"
              mode="determinate"
              [value]="(time.countdown / notification.duration) * 100"
              [diameter]="55"
            >
            </mat-progress-spinner>
            <div class="time" [style.color]="time.countdown < 10 ? 'red' : null">
              <span>{{ time.countdown }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container [ngSwitch]="notification.type">
      <ng-container *ngSwitchCase="NotificationType.assigning">
        <ng-container *ngIf="notification.channel !== TxnChannel.call && time.countdown > 0">
          <ng-container *ngIf="!notification.isTriggerred; else cancalTransfer">
            <mat-divider></mat-divider>
            <div class="actions flex-gap-5">
              <a href="javascript:void" (click)="reject()" class="btn-reject">Reject</a>
              <button
                mat-flat-button
                [disabled]="loadingType === 'first'"
                [loading]="loadingType === 'secound'"
                (click)="accept()"
                color="primary"
              >
                Accept
              </button>
            </div>
          </ng-container>
          <ng-template #cancalTransfer>
            <mat-divider></mat-divider>
            <div class="actions">
              <button
                mat-flat-button
                [disabled]="loadingType === 'first'"
                [loading]="loadingType === 'first'"
                (click)="cancelTransfer()"
                color="primary"
              >
                Cancel assignment
              </button>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="NotificationType.transferring">
        <ng-container *ngIf="notification.channel !== TxnChannel.call && time.countdown > 0">
          <ng-container *ngIf="!notification.isTriggerred; else cancalTransfer">
            <mat-divider></mat-divider>
            <div class="actions flex-gap-5">
              <a href="javascript:void" (click)="reject()" class="btn-reject">Reject</a>
              <button
                mat-flat-button
                [disabled]="loadingType === 'first'"
                [loading]="loadingType === 'secound'"
                (click)="accept()"
                color="primary"
              >
                Accept
              </button>
            </div>
          </ng-container>
          <ng-template #cancalTransfer>
            <mat-divider></mat-divider>
            <div class="actions">
              <button
                mat-flat-button
                [disabled]="loadingType === 'first'"
                [loading]="loadingType === 'first'"
                (click)="cancelTransfer()"
                color="primary"
              >
                Cancel assignment
              </button>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="NotificationType.accepted">
        <!-- <ng-container *ngTemplateOutlet="closeToast"></ng-container> -->
      </ng-container>
      <ng-container *ngSwitchCase="NotificationType.rejectAssign">
        <ng-container *ngIf="!notification.isTriggerred">
          <mat-divider></mat-divider>
          <div class="actions">
            <a href="javascript:void" (click)="closeNotification()" class="btn-reject">Close</a>
            <button
              mat-flat-button
              [disabled]="loadingType === 'first'"
              [loading]="loadingType === 'secound'"
              (click)="reassign()"
              color="primary"
            >
              Reassign
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="NotificationType.assigningTimeout">
        <ng-container *ngIf="!notification.isAffected">
          <mat-divider></mat-divider>
          <div class="actions">
            <a href="javascript:void" (click)="closeNotification()" class="btn-reject">Close</a>
            <button
              mat-flat-button
              [disabled]="loadingType === 'first'"
              [loading]="loadingType === 'secound'"
              (click)="reassign()"
              color="primary"
            >
              Reassign
            </button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #closeToast>
  <mat-divider></mat-divider>
  <div class="actions">
    <a href="javascript:void" (click)="closeNotification()" class="btn-reject">Close</a>
  </div>
</ng-template>

<ng-template #infoCustomer>
  <ng-container
    *ngIf="notification.type !== NotificationType.selfAssigned && notification.type !== NotificationType.accepted"
  >
    <div>Customer Name: {{ notification?.customer.displayName }}</div>
    <div *ngIf="notification?.customer?.emails?.length > 0">Email: {{ notification?.customer?.emails[0].email }}</div>
    <div *ngIf="notification?.customer?.numbers?.length > 0">
      Number: {{ notification?.customer?.numbers[0].number }}
    </div>
    <a href="void:javascript" (click)="showDetailTxn()"> View details</a>
  </ng-container>
</ng-template>
