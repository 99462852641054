import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BulkProject } from './bulk-project.model';

export interface BulkProjectState extends EntityState<BulkProject> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bulk-project', idKey: 'uuid' })
export class BulkProjectStore extends EntityStore<BulkProjectState> {
  constructor() {
    super();
  }
}
