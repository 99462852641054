import { HashMap } from '@datorama/akita';
export const WhatsappTemplateRegex = /\{\{(\d+)\}\}/g;

export class WhatsappTemplate {
  id: string;
  wabaId: string;
  name: string;
  category: CategoryTemplate;
  language: string;
  status: StatusTemplate;
  component: ComponentTemplate;

  constructor(obj: Partial<WhatsappTemplate>) {
    if (obj) {
      Object.assign(this, obj);
      if (obj?.component) {
        this.component = new ComponentTemplate(obj.component);
      }
    }
  }
}

export class ComponentTemplate {
  header: ComponentStructure;
  body: ComponentStructure;
  buttons: ComponentStructure[];
  footer: ComponentStructure;

  constructor(obj: Partial<ComponentTemplate>) {
    if (obj) {
      Object.assign(this, obj);
      if (obj?.header) this.header = new ComponentStructure(obj.header);
      if (obj?.body) this.body = new ComponentStructure(obj.body);
      if (obj?.buttons) {
        this.buttons = [];
        obj.buttons.forEach(b => {
          this.buttons.push(new ComponentStructure(b, obj.buttons.indexOf(b)));
        });
      }
      if (obj?.footer) this.footer = new ComponentStructure(obj.footer);
    }
  }
}

export enum ComponentStructureFormat {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT'
}

export enum TemplateButtonType {
  QUICK_REPLY = 'QUICK_REPLY',
  URL = 'URL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  COPY_CODE = 'COPY_CODE'
}

export class ComponentStructure {
  format?: ComponentStructureFormat;
  text: string; // for ComponentStructureFormat.TEXT
  example: any[] | string[]; // for ComponentStructureFormat.IMAGE or something
  type?: TemplateButtonType;
  url?: string; // for Button

  // ui
  icon?: string;
  arrayParams: string[] = [];
  mapParamsExample: HashMap<ParamsExample> = {};

  get hasParameters() {
    switch (this.type) {
      case TemplateButtonType.QUICK_REPLY:
      case TemplateButtonType.PHONE_NUMBER:
        return false;
      case TemplateButtonType.URL: {
        if (this.url) {
          const matched = this.url.match(WhatsappTemplateRegex);
          return matched?.length > 0;
        }
        return false;
      }
      case TemplateButtonType.COPY_CODE:
        return !!this.text && this.example?.length > 0;
      default:
        return !!this.text && this.example?.length > 0;
    }
  }

  constructor(obj: Partial<ComponentStructure>, index?: number) {
    if (obj) {
      Object.assign(this, obj);

      if (this.hasParameters) {
        this.arrayParams = [];
        this.mapParamsExample = {};
        this.example.forEach((item, i) => {
          const key = index ? `${index}_{{${i + 1}}}` : `{{${i + 1}}}`;
          this.arrayParams.push(key);
          this.mapParamsExample[key] = <ParamsExample>{
            key: key,
            default: item,
            value: item
          };
        });
      }

      switch (this.type) {
        case TemplateButtonType.QUICK_REPLY:
          this.icon = 'wa_btn_reply';
          break;
        case TemplateButtonType.URL:
          this.icon = 'wa_btn_launch';
          break;
        case TemplateButtonType.PHONE_NUMBER:
          this.icon = 'b3n_call';
          break;
        case TemplateButtonType.COPY_CODE:
          this.icon = 'wa_btn_copy';
          break;
      }
    }
  }
}

export interface ParamsExample {
  key: string; // {{n}} or i_{{n}}
  default: string; // value
  value?: string;
}

export enum CategoryTemplate {
  UTILITY = 'UTILITY',
  MARKETING = 'MARKETING'
}

export enum StatusTemplate {
  APPROVED = 'APPROVED'
}
