import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Request } from './request.model';

export interface RequestState extends EntityState<Request> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'leave_request', idKey: 'id' })
export class RequestStore extends EntityStore<RequestState> {
  constructor() {
    super();
  }
}
