import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Timezone } from './timezone';
import { TimezoneStore } from './timezone.store';

@Injectable({ providedIn: 'root' })
export class TimezoneService {
  constructor(
    private http: HttpClient,
    private timezoneStore: TimezoneStore
  ) {}

  getTimezone(): Observable<Timezone[]> {
    const request$ = this.http.get<Timezone[]>('/auth/private/v1/timezone').pipe(
      map(res => {
        const temp = this.standardTimezone(res);
        this.timezoneStore.set(temp);
        return temp;
      })
    );
    return cacheable(this.timezoneStore, request$);
  }

  standardTimezone(data: Timezone[]) {
    return data.map(item => {
      return new Timezone(item);
    });
  }
}
