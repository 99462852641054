<div class="menu-container">
  <!--org section-->
  <div *ngIf="data.profile" class="org-section">
    <section (click)="$event.stopPropagation()" class="org-info" fxLayout="row" fxLayoutAlign="start center">
      <div
        [class.default]="data.currentOrg.photoSrc && data.currentOrg.photoSrc.indexOf('http') <= -1"
        class="org-avatar"
      >
        <img [attr.src]="data.currentOrg.photoSrc" class="avatar" />
      </div>

      <div class="info">
        <div class="row">{{ data.currentOrg?.orgShortName || data.currentOrg?.orgName }}</div>
        <div class="uuid__wrapper">
          UUID:
          <button
            (click)="copied()"
            *ngIf="data.currentOrg?.orgUuid; let orgUuid"
            [cdkCopyToClipboard]="orgUuid"
            class="box-uuid box-uuid-profile"
            mat-button
            matTooltip="Click to copy"
          >
            {{ orgUuid.slice(0, 8) }}
          </button>
        </div>
      </div>
    </section>

    <ng-container *ngIf="data.currentOrg.isSuperAdmin; else showInfo">
      <div class="extension">
        <div>
          <mat-icon class="icon-group" svgIcon="group"></mat-icon>
          <div class="italic">No data available</div>
        </div>
        <div>
          <mat-icon class="icon-extension" svgIcon="extension"></mat-icon>
          <div class="italic">No data available</div>
        </div>
      </div>
    </ng-container>
    <ng-template #showInfo>
      <section *ngIf="teamAndExtData$ | async as teamAndExtData" (click)="$event.stopPropagation()" class="extension">
        <div *ngIf="teamAndExtData.teams">
          <mat-icon class="icon-group" svgIcon="group"></mat-icon>
          <ng-container *ngIf="teamAndExtData.teams.length; else noTeam">
            <div>{{ teamAndExtData.teams[0].name }}</div>
            <div *ngIf="teamAndExtData.teams.length > 1" [matMenuTriggerFor]="menu" class="count-team cursor-pointer">
              +{{ teamAndExtData.teams.length - 1 }}
            </div>
          </ng-container>
          <ng-template #noTeam>
            <div class="italic">No team assigned</div>
          </ng-template>

          <mat-menu
            #menu="matMenu"
            [backdropClass]="'team-menu-backdrop'"
            class="team-menu {{ (isDarkMode$ | async) ? 'dark-theme-portal-base' : null }}"
          >
            <div *ngFor="let team of teamAndExtData.teams.slice(1)" class="team-item">{{ team.name }}</div>
          </mat-menu>
        </div>

        <div *ngIf="teamAndExtData.ext">
          <mat-icon class="icon-extension" svgIcon="extension"></mat-icon>
          <ng-container *ngIf="teamAndExtData.ext.extKey; else noExt"> #{{ teamAndExtData.ext.extKey }} </ng-container>
          <ng-template #noExt>
            <div class="italic">No ext assigned</div>
          </ng-template>
        </div>
      </section>
    </ng-template>

    <section>
      <button (click)="gotoOrganizationChart()" *ngIf="showOrgChartMenu()" class="org-menu-item" mat-menu-item>
        Organization Chart
      </button>

      <button
        (click)="gotoManageOrganization()"
        *ngIf="data.currentOrg && (data.currentOrg.isUpperAdmin || !data.currentOrg.licenseEnabled)"
        class="org-menu-item"
        mat-menu-item
      >
        Manage Organization
      </button>

      <button
        (click)="gotoManageConnections()"
        *ngIf="data.currentOrg && (data.currentOrg.isUpperAdmin || !data.currentOrg.licenseEnabled)"
        class="org-menu-item"
        mat-menu-item
      >
        Manage Connections
      </button>

      <button
        (click)="showSwitchOrganizationDialog()"
        *ngIf="
          data.profile.shouldHasSwitchOrg ||
          data.servicedOrgs.length > 1 ||
          (data.profile.organizations?.length === 1 && data.servicedOrgs.length === 1)
        "
        class="org-menu-item"
        mat-menu-item
      >
        Switch Organization
      </button>
    </section>

    <ng-container *ngIf="hasStore$ | async">
      <ng-container *ngIf="portalConfig$ | async as portalConfig">
        <button
          (click)="gotoStore()"
          *ngIf="portalConfig?.showStore && data.currentOrg?.isUpperAdmin"
          class="org-menu-item"
          mat-menu-item
        >
          Store
        </button>
      </ng-container>
    </ng-container>
  </div>

  <!--account section-->
  <div class="account-menu">
    <div (click)="$event.stopPropagation()" class="user-info" fxLayout="row" fxLayoutAlign="start center">
      <div [class.default]="data.profile.photoUrl && data.profile.photoUrl.indexOf('http') <= -1" class="user-avatar">
        <img *ngIf="data.profile.hasPhoto" [attr.src]="data.profile.photoUrl" class="avatar" />

        <mat-icon *ngIf="!data.profile.hasPhoto" svgIcon="user"></mat-icon>
      </div>

      <div class="info">
        <div class="row">{{ data.profile?.displayName }}</div>
        <div class="uuid__wrapper">
          UUID:
          <button
            (click)="copied()"
            *ngIf="data.profile?.uuid; let uuid"
            [cdkCopyToClipboard]="uuid"
            class="box-uuid box-uuid-profile"
            mat-button
            matTooltip="Click to copy"
          >
            {{ uuid.slice(0, 8) }}
          </button>
        </div>
      </div>
    </div>

    <section (click)="$event.stopPropagation()" *ngIf="data.profile" class="email">
      <div>
        <mat-icon class="icon-email" svgIcon="email"></mat-icon>
        <div>{{ data.profile.email }}</div>
      </div>
      <div>
        <mat-icon class="icon-clock" svgIcon="clock"></mat-icon>
        <div>{{ data.profile.timezone ? data.profile.timezone.slice(0, 6)?.replace('GMT', 'GMT ') : '' }}</div>
      </div>
    </section>

    <section>
      <button (click)="gotoManageProfile()" mat-menu-item>Manage Account</button>
      <button (click)="openLogoutDialog()" mat-menu-item>Sign Out</button>

      <div
        (click)="toggleDarkMode(data.darkMode, $event)"
        class="ml-8 flex justify-between logout-container toggle-theme"
      >
        <div>Dark Mode</div>
        <mat-slide-toggle [checked]="data.darkMode" labelPosition="before"> </mat-slide-toggle>
      </div>
    </section>
  </div>
</div>
