import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { HotDeskingState, HotDeskingStore } from './hot-desking.store';

@Injectable({ providedIn: 'root' })
export class HotDeskingQuery extends QueryEntity<HotDeskingState> {
  constructor(protected override store: HotDeskingStore) {
    super(store);
  }

  getSIPAccountByKey(sipUsername: ID) {
    return this.getEntity(sipUsername);
  }

  selectAllByUsername(usernames: string[] = []) {
    return this.selectAll({
      filterBy: e => usernames.includes(e.sipUsername)
    });
  }
}
