import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnnouncementsComponent } from './announcements.component';
import { StoreAnnouncementComponent } from './store-announcement/store-announcement.component';

const components = [AnnouncementsComponent, StoreAnnouncementComponent];
@NgModule({
  declarations: [],
  imports: [CommonModule, components],
  exports: [components]
})
export class AnnouncementsModule {}
