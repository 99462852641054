<div *ngIf="list?.length > 0" class="container flex column gap-8">
  <ng-container *ngFor="let item of list; trackBy: trackByItem">
    <ng-container *ngIf="item.template; else textContent">
      <ng-container *ngTemplateOutlet="item.template; context: item.templateContext"></ng-container>
    </ng-container>
    <ng-template #textContent>
      <b3n-toast [data]="item"></b3n-toast>
    </ng-template>
  </ng-container>
</div>
