import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { FormType } from './form.model';
import { FormState, FormStore } from './form.store';

@Injectable({ providedIn: 'root' })
export class FormQuery extends QueryEntity<FormState> {
  constructor(protected override store: FormStore) {
    super(store);
  }

  getForm(templateUuid: string) {
    return this.getEntity(templateUuid);
  }

  getAllFormByTemplateCode(templateCode: FormType) {
    return this.getAll({
      filterBy: entity => entity.templateCode === templateCode,
      sortBy: 'updated',
      sortByOrder: Order.DESC
    });
  }

  searchFormByTemplateCodeAndTitle(keyword: string, templateCodes: FormType[]) {
    return this.selectAll({
      filterBy: entity =>
        templateCodes.includes(entity.templateCode as FormType) &&
        entity?.title?.toLowerCase().includes(keyword?.toLowerCase())
    });
  }

  selectFormByTemplateCode(templateCode: FormType) {
    return this.selectAll({
      filterBy: entity => entity.templateCode === templateCode,
      sortBy: 'updated',
      sortByOrder: Order.DESC
    });
  }
}
