import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceType, Extension, ExtensionBase, GetAllExtsParams, UsageControl } from '@b3networks/api/bizphone';
import { Pageable } from '@b3networks/api/common';
import { ID, cacheable } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AllowedCallerId, DelegatedCallerId, UpdateExtDevice } from './allowed-callerid.model';
import { ExtensionStore } from './extension.store';

@Injectable({ providedIn: 'root' })
export class ExtensionService {
  constructor(
    private store: ExtensionStore,
    private http: HttpClient
  ) {}

  getMe() {
    return this.http.get<Extension>('callcenter/private/v3/extension/me').pipe(
      map(ext => new Extension(ext)),
      tap((ext: Extension) => {
        this.store.update({ me: ext });
        this.store.add(ext);
      })
    );
  }
  // only use display lable
  getAllExtenison(cache: boolean = true, params?: GetAllExtsParams) {
    let httpParams = new HttpParams();

    if (params) {
      if (params.filterDelegable) {
        httpParams = httpParams.set('filterDelegable', params.filterDelegable);
      }

      if (params.filterGroupable) {
        httpParams = httpParams.set('filterGroupable', params.filterGroupable);
      }
    }

    const req$ = this.http.get<ExtensionBase[]>(`callcenter/private/v3/extension/_all`, { params: httpParams }).pipe(
      map(exts => exts.map(x => new ExtensionBase(x))),
      // map(_ => []), // teting only
      tap(exts => {
        if (params?.filterDelegable === 'true' || params?.filterGroupable === 'true') {
          return;
        }
        this.store.set(exts);
      })
    );

    return cache ? cacheable(this.store, req$) : req$;
  }

  getDetails(extKey: string) {
    return this.http.get<Extension>(`callcenter/private/v3/extension/${extKey}`).pipe(
      map(extension => new Extension(extension)),
      tap(extension => {
        this.store.upsert(extKey, extension);
      })
    );
  }

  getAssignedExtensions(uuids: string[], pageable?: Pageable): Observable<Extension[]> {
    let params = new HttpParams();
    if (pageable) {
      Object.keys(pageable).forEach(key => {
        if (pageable[key] !== undefined && pageable[key] !== null) {
          params = params.set(key, pageable[key]);
        }
      });
    }
    params = params.set('identityUuids', uuids.join(','));
    return this.http.get<Extension[]>(`/callcenter/private/v3/extension`, { params: params });
  }

  updateExtAndMe(extKey: string, extension: Partial<Extension>): Observable<void> {
    return this.http.put<void>(`callcenter/private/v3/extension/${extKey}`, extension).pipe(
      tap(_ => {
        this.store.update(state => {
          const stateTemp = structuredClone(state);
          const stateExt = structuredClone(stateTemp.entities[extKey]);
          stateTemp.entities[extKey] = new Extension({ ...stateExt, ...extension });
          if (stateTemp.me.extKey === extKey) stateTemp.me = new Extension({ ...stateTemp.me, ...extension });
          return stateTemp;
        });
      })
    );
  }

  update(extKey: string, extension: Partial<Extension>): Observable<void> {
    return this.http.put<void>(`callcenter/private/v3/extension/${extKey}`, extension).pipe(
      tap(_ => {
        this.store.update(extKey, extension);
        if (this.store.getValue()?.me?.extKey === extKey && extension.hasOwnProperty('disableViewMe')) {
          this.store.update(state => ({
            ...state,
            me: {
              ...state.me,
              disableViewMe: extension.disableViewMe
            }
          }));
        }
      })
    );
  }

  updateUsageControl(extKey: string, usageControl: UsageControl): Observable<void> {
    return this.http.put<void>(`callcenter/private/v3/usageControl/extKey/${extKey}`, usageControl).pipe(
      tap(usageControl => {
        this.store.update(extKey, ext => ({ ...ext, usageControl }));
      })
    );
  }

  getUsageControl(extKey: string): Observable<UsageControl> {
    return this.http.get<UsageControl>(`callcenter/private/v3/usageControl/extKey/${extKey}`).pipe(
      tap(usageControl => {
        this.store.update(extKey, ext => ({ ...ext, usageControl }));
      })
    );
  }

  /**
   * Update ext device to change protocol & STUN config. Result of this will ignore to update entity store
   * @param id
   * @param req
   */
  updateExtDevice(id: { extKey: string; deviceType: DeviceType; sipUsername: string }, req: UpdateExtDevice) {
    return this.http
      .put<void>(`callcenter/private/v3/extension/${id.extKey}/device/${id.deviceType}/${id.sipUsername}`, req)
      .pipe(tap(_ => {}));
  }

  updateDeviceById(
    id: { extKey: string; deviceType: DeviceType; sipUsername: string; deviceId?: number },
    req: UpdateExtDevice
  ) {
    return this.http
      .put<void>(`callcenter/private/v3/extension/${id.extKey}/device/${id.deviceType}/${id.deviceId}`, req)
      .pipe(tap(_ => {}));
  }

  getAllowedCallerId(extKey: string) {
    return this.http.get<AllowedCallerId>(`callcenter/private/v3/extension/${extKey}/allowedCallerId`).pipe(
      tap(res => {
        this.store.update(state => {
          const data = { ...state.allowedCallerIds };
          data[extKey] = res;
          return { allowedCallerIds: data };
        });
      })
    );
  }

  getDelegatedCallerId(extKey: string) {
    return this.http
      .get<{
        [key: string]: string;
      }>(`callcenter/private/v3/extension/${extKey}/delegatedCallerId`)
      .pipe(
        tap(delegatedCallerId => {
          const list: DelegatedCallerId[] = [];

          Object.keys(delegatedCallerId).forEach(key => {
            list.push(<DelegatedCallerId>{
              extKey: delegatedCallerId[key],
              number: key
            });
          });

          this.store.update(state => {
            const data = { ...state.delegatedCallerIds };
            data[extKey] = list;
            return { delegatedCallerIds: data };
          });
        })
      );
  }

  setActive(extKey: ID) {
    this.store.setActive(extKey);
  }

  removeActive(extKey: ID) {
    this.store.removeActive(extKey);
  }

  syncExtensionKey(extension: Partial<Extension>, toKey: string) {
    const newOne = new Extension({ ...extension, extKey: toKey });
    this.store.add(newOne);
    this.store.remove(extension.extKey);
  }

  export() {
    return this.http.get(`callcenter/private/v1/extensions/export`, {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

  import(fileKey: string) {
    return this.http.post(`callcenter/private/v1/extensions/import`, {
      fileKey
    });
  }

  importPersonalSetting(tempKey: string, temUuid: string) {
    return this.http.post(
      `callcenter/private/v1/extensions/importPersonalSetting/${temUuid}`,
      {
        fileKey: tempKey
      },
      { reportProgress: true, observe: 'events' }
    );
  }

  setDelegate(fromExtKey: string, toExtKey: string) {
    return this.http.post(`callcenter/private/v3/extension/${fromExtKey}/delegate/${toExtKey}`, {});
  }

  delDelegate(fromExtKey: string, toExtKey: string) {
    return this.http.delete(`callcenter/private/v3/extension/${fromExtKey}/delegate/${toExtKey}`);
  }

  exportUsers() {
    return this.http.get(`callcenter/private/v1/extensions/exportAll`, {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

  importUsers(fileKey: string) {
    return this.http.post(`callcenter/private/v1/extensions/importAll`, {
      fileKey
    });
  }

  applySelectedExtension(extKeys: string[], templateUuid: string, applyToAll: boolean): Observable<void> {
    return this.http.post<void>(`callcenter/private/v3/extension/template/applySelected`, {
      extKeys,
      templateUuid,
      applyToAll
    });
  }
}
