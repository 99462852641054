<mat-form-field #trigger="matMenuTrigger" [matMenuTriggerFor]="timepickerMenu" class="input-timepicker">
  <input matInput readonly type="text" [value]="displayedTime" [required]="required" [placeholder]="placeholder" />
  <button mat-icon-button matSuffix><mat-icon class="icon-15px">access_time</mat-icon></button>
</mat-form-field>
<mat-menu #timepickerMenu="matMenu" class="popup-time-setting" (closed)="onClosedMenu()">
  <ng-template matMenuContent>
    <ngx-mat-timepicker
      [(ngModel)]="time"
      [showSpinners]="showSpinners"
      [stepHour]="stepHour"
      [stepMinute]="stepMinute"
      [stepSecond]="stepSecond"
      [showSeconds]="showSeconds"
      (click)="$event.stopPropagation()"
      (keyup.enter)="onSaveTime()"
    >
    </ngx-mat-timepicker>
    <div class="actions">
      <button mat-button>Cancel</button>
      <button mat-button color="primary" (click)="onSaveTime()">OK</button>
    </div>
  </ng-template>
</mat-menu>
