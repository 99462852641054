import { Inject, Injectable, InjectionToken, Renderer2, RendererFactory2 } from '@angular/core';
import { HashMap } from '@datorama/akita';
import { BehaviorSubject, map } from 'rxjs';

export enum ThemeName {
  lightMode = 'lightMode',
  darkMode = 'darkMode'
}

export const LIGHT_MODE = new InjectionToken<any>(ThemeName.lightMode);
export const DARK_MODE = new InjectionToken<any>(ThemeName.darkMode);

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _modeTheme = new BehaviorSubject<ThemeName>(ThemeName.lightMode);
  private _renderer: Renderer2;

  get modeTheme$() {
    return this._modeTheme.asObservable();
  }

  get isDarkMode$() {
    return this.modeTheme$.pipe(map(mode => mode === ThemeName.darkMode));
  }

  get isDarkMode() {
    return this._modeTheme.value === ThemeName.darkMode;
  }

  constructor(
    public rendererFactory: RendererFactory2,
    @Inject(LIGHT_MODE) private lightModeJson: any,
    @Inject(DARK_MODE) private darkModeJson: any
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  generateClassWithTheme(className: string, nameTheme: ThemeName = ThemeName.lightMode) {
    this.appThemeVariables(className, nameTheme);
  }

  toggleDarkMode(nameTheme: ThemeName): void {
    this._modeTheme.next(nameTheme);
  }

  private appThemeVariables(className: string, nameTheme: ThemeName): void {
    const customThemeStyle: HTMLStyleElement = this._renderer.createElement('style');
    customThemeStyle.classList.add(`${className}`);
    customThemeStyle.classList.add(nameTheme === ThemeName.lightMode ? 'light-mode' : 'dark-mode');
    const data = nameTheme === ThemeName.lightMode ? this.lightModeJson : this.darkModeJson;
    const variables = this.generateCssVariables(data);
    let cssContent = `.${className} { `;
    for (const key in variables) {
      if (variables.hasOwnProperty(key)) {
        cssContent += `${key}: ${variables[key]};`;
      }
    }
    cssContent += `}`;
    customThemeStyle.innerHTML = cssContent;

    const styleOld = document.querySelectorAll(`style.${className}`);
    if (styleOld?.length > 0) {
      styleOld.forEach(item => item.remove());
    }
    this._renderer.appendChild(document.head, customThemeStyle);
  }

  private generateCssVariables(obj: HashMap<any>, parentKey = '', result: any = {}) {
    for (const key in obj) {
      if (obj?.hasOwnProperty(key)) {
        const value = obj[key];
        if (key == 'DEFAULT') {
          result['--' + parentKey] = value;
          continue;
        }
        const currentKey = parentKey ? parentKey + '-' + key : key;
        if (typeof value === 'object' && !Array.isArray(value)) {
          this.generateCssVariables(value, currentKey, result);
        } else {
          if (value) {
            result['--' + currentKey] = value;
          }
        }
      }
    }
    return result;
  }
}
