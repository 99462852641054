import { DashboardV3AppSetting } from '@b3networks/api/dashboard';
import { CallcenterAppSettings } from './callcenter-setting.model';
import { CommunicationAppSettings } from './communication-setting.model';
import { DashboardV2AppSetting } from './dashboard-setting-v2.model';
import { DashboardAppSetting } from './dashboard-setting.model';
import { MemberSettingsAppSettings } from './member-setting.model';
import { UnifiedWorkspaceSetting } from './unified-workspace-setting-model';

export type PersonalAppSettings =
  | CallcenterAppSettings
  | DashboardAppSetting
  | UnifiedWorkspaceSetting
  | MemberSettingsAppSettings
  | DashboardV2AppSetting
  | DashboardV3AppSetting
  | CommunicationAppSettings;

export class PersonalSettings {
  darkMode: boolean;
  apps: Array<PersonalAppSettings>;

  constructor(obj?: Partial<PersonalSettings>) {
    if (obj) {
      Object.assign(this, obj);
      this.apps = this.apps || [];
    }
  }
}
