import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncementsModule } from '@b3networks/portal/base/shared';
import { SharedAuthModule } from '@b3networks/shared/auth';
import { ButtonLoadingModule, SharedUiMaterialModule } from '@b3networks/shared/ui/material';
import { LandingPageComponent } from './landing-page.component';

const routes: Routes = [{ path: '', component: LandingPageComponent }];

@NgModule({
  declarations: [LandingPageComponent],
  imports: [
    CommonModule,
    SharedUiMaterialModule,
    ButtonLoadingModule,
    FormsModule,
    AnnouncementsModule,
    SharedAuthModule
  ],
  exports: [LandingPageComponent]
})
export class LandingPageModule {}

@NgModule({ imports: [LandingPageModule, RouterModule.forChild(routes)] })
export class LandingPageFeatureModule {}
