import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { ActivityMode } from './activities.model';
import { ActivitiesState, ActivitiesStore } from './activities.store';

@Injectable({ providedIn: 'root' })
export class ActivitiesQuery extends QueryEntity<ActivitiesState> {
  constructor(protected override store: ActivitiesStore) {
    super(store);
  }

  selectActivityByTxn(txnUuid: string, isNewest: boolean, type?: string) {
    return this.selectAll({
      filterBy: entity =>
        entity.txnUuid === txnUuid && (type && type !== ActivityMode.all ? entity.type === type : true),
      sortBy: 'createdAt',
      sortByOrder: isNewest ? Order.DESC : Order.ASC
    });
  }
}
