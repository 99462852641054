import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, Pageable } from '@b3networks/api/common';
import { X_B3_HEADER, X_PAGINATION } from '@b3networks/shared/common';
import { ID } from '@datorama/akita';
import { EMPTY, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PermissionGroup, UpdateIAMGroupMemberReq } from '../iam/iam-group.model';
import { IAMGrantedPermission } from '../iam/iam.model';
import { PolicyDocument } from '../iam/policty-document.model';
import { Identity, MemberRole } from '../identity/identity';
import {
  AddMemberRequest,
  CreateCredentialRequest,
  ExportMember,
  GetMemberBelongIAMGroupReq as GetMembersBelongIAMGroupReq,
  GetMembersReq,
  ImportMemberRequest,
  ImportMemberResp,
  Member,
  MemberPin,
  MemberStatus,
  MemberUpdateRequest,
  MemberWithIAM,
  PendingMember,
  ResendActivationEmailReq,
  SearchMemberNotInTeamReq,
  SortMemberDirection,
  TriggerExportMember
} from './member';
import { OrgMemberQuery } from './org-member.query';
import { OrgMemberStore } from './org-member.store';

@Injectable({
  providedIn: 'root'
})
export class OrgMemberService {
  constructor(private http: HttpClient, private orgMemberStore: OrgMemberStore, private query: OrgMemberQuery) {}

  getDirectoryMembers(
    req: GetMembersReq,
    pageable?: Pageable,
    storable: boolean = true,
    headers?: HttpHeaders,
    unassign: boolean = false
  ): Observable<Page<Member>> {
    this.orgMemberStore.setLoading(true);
    const requestParams = [
      req.sort,
      req.keyword,
      req.filterExtension,
      MemberStatus.active,
      `${MemberStatus.active},${MemberStatus.pending}`,
      req.roles,
      req.team
    ].filter(Boolean);
    let params = new HttpParams();
    Object.keys(req)
      .filter(key => req[key] != null)
      .forEach(key => {
        if (key === 'roles') {
          params = req[key].length ? params.set('role', req[key].join(',')) : params;
        } else {
          params = requestParams.includes(req[key]) ? params.set(key, req[key]) : params;
        }
      });

    if (pageable) {
      params = params.set('page', String(pageable.page)).set('size', String(pageable.perPage));
    }

    if (req.teamUuid) {
      params = params.set('team', req.teamUuid);
    }

    return this.http
      .get<Member[]>(`directory/private/v1/members${unassign ? '/unassigned' : ''}`, {
        params: params,
        observe: 'response',
        headers: headers
      })
      .pipe(
        map(resp => {
          const page = new Page<Member>();
          page.content = resp.body.map(mem => new Member({ ...mem, uuid: mem.memberUuid, displayName: mem.name }));
          page.totalCount = +resp.headers.get(X_PAGINATION.totalCount);
          return page;
        }),
        tap(page => {
          if (!storable) {
            return;
          }

          this.orgMemberStore.set(page.content);
          this.orgMemberStore.setLoading(false);
        })
      );
  }

  searchMembersNotInTeam(req: SearchMemberNotInTeamReq): Observable<Page<Member>> {
    return this.http.post<Member[]>(`directory/private/v2/members/_searchNotInTeam`, req, { observe: 'response' }).pipe(
      map(resp => {
        const page = new Page<Member>();
        page.content = resp.body.map(
          mem => new Member({ ...mem, uuid: mem.uuid, displayName: mem.displayName, photoUrl: mem.photoUrl })
        );
        page.totalCount = +resp.headers.get(X_PAGINATION.totalCount);
        return page;
      })
    );
  }

  getMembersByMemberUuids(orgUuid: string, uuids: string[]): Observable<any[]> {
    return this.http.get<any[]>(`/auth/private/v1/organizations/${orgUuid}/members?memberUuids=${uuids.join(',')}`);
  }

  getMembers(req: GetMembersReq, pageable?: Pageable, addon?: { ignoreStore: boolean }): Observable<Page<Member>> {
    this.orgMemberStore.setLoading(true);
    let params = new HttpParams();
    if (pageable) {
      params = params.set('page', String(pageable.page)).set('size', String(pageable.perPage));
    }

    if (req.sort) {
      params = params.set('sort', req.sort);
    }

    return this.http
      .post<Member[]>(`auth/private/v2/organizations/${req.orgUuid}/members`, req, {
        params: params,
        observe: 'response' // to display the full response & as 'body' for type cast
      })
      .pipe(
        map(resp => {
          const page = new Page<Member>();
          page.content = resp.body.map(mem => new Member(mem));
          page.totalCount = +resp.headers.get(X_PAGINATION.totalCount);
          return page;
        }),
        tap(page => {
          if (!addon?.ignoreStore) {
            this.orgMemberStore.set(page.content);
          }

          this.orgMemberStore.setLoading(false);
        })
      );
  }

  getMembersBelongIAMGroup(
    groupID: string,
    req: GetMembersBelongIAMGroupReq,
    pageable?: Pageable
  ): Observable<Page<MemberWithIAM>> {
    let params = new HttpParams().set('sort', req.sort || SortMemberDirection.ASC);

    if (req.keyword) {
      params = params.set('keyword', req.keyword);
    }
    if (req.role) {
      params = params.set('role', req.role);
    }
    if (pageable) {
      params = params.set('page', pageable?.page + '').set('size', pageable?.perPage + '');
    }
    return this.http
      .get<MemberWithIAM[]>(`auth/private/v1/iam/group/${groupID}/members`, { params: params, observe: 'response' })
      .pipe(
        map(resp => {
          const content = resp.body.map(m => {
            return new MemberWithIAM(m);
          });

          const totalCount = +resp.headers.get(X_B3_HEADER.totalCount);
          return {
            content: content,
            totalCount: totalCount
          } as Page<MemberWithIAM>;
        })
      );
  }

  getAssignedIAMGroupsOfMember(orgUuid: string, memberUuid: string) {
    return this.http.get<PermissionGroup[]>(
      `auth/private/v1/organizations/${orgUuid}/iam/members/${memberUuid}/groups`
    );
    // .pipe(map(x => x || []));
  }

  updateIAMGroupMember(orgUuid: string, identityUuid: string, req: UpdateIAMGroupMemberReq) {
    return this.http.put<any>(`auth/private/v1/organizations/${orgUuid}/iam/members/${identityUuid}/groups`, req);
  }

  getMember(orgUuid: string, memeberUuid: string, getFromStore: boolean = true): Observable<Member> {
    const req = this.http.get<Member>(`auth/private/v1/organizations/${orgUuid}/members/${memeberUuid}`).pipe(
      map(mem => new Member(mem)),
      tap(member => this.orgMemberStore.add(member))
    );

    if (getFromStore) {
      return this.query.getEntity(memeberUuid) != null ? EMPTY : req;
    } else {
      return req;
    }
  }

  addMember(orgUuid: string, member: AddMemberRequest): Observable<void> {
    return this.http.post<void>(`auth/private/v1/organizations/${orgUuid}/members`, member);
  }

  deleteMember(orgUuid: string, memberUuid: string): Observable<void> {
    return this.http.delete<void>(`auth/private/v1/organizations/${orgUuid}/members/${memberUuid}`);
  }

  triggerExportMember(isExportDirectoryMember?: boolean, teamUuid?: string): Observable<TriggerExportMember> {
    const url = isExportDirectoryMember
      ? `/directory/private/v2/members/export`
      : `/auth/private/v1/organizations/members/export`;
    return this.http.post<TriggerExportMember>(url, { team: teamUuid });
  }

  getExportMember(jobId: string, isExportDirectoryMember?: boolean): Observable<ExportMember> {
    const url = isExportDirectoryMember
      ? `/directory/private/v2/members/export/${jobId}`
      : `/auth/private/v1/organizations/members/export/${jobId}`;
    return this.http.get<ExportMember>(url);
  }

  exportPendingMember(): Observable<TriggerExportMember> {
    return this.http.post<TriggerExportMember>(`/auth/private/v1/organizations/members/pending/export`, {});
  }

  getExportUrlForPendingMember(jobId: string): Observable<ExportMember> {
    return this.http.get<ExportMember>(`/auth/private/v1/organizations/members/pending/export/${jobId}`);
  }

  importMember(orgUuid: string, req: ImportMemberRequest): Observable<ImportMemberResp> {
    return this.http
      .post<ImportMemberResp>(`/auth/private/v1/organizations/${orgUuid}/members/bulk`, req, {
        withCredentials: true
      })
      .pipe(map(resp => new ImportMemberResp(resp)));
  }

  importMemberIncludeEmail(req: ImportMemberRequest): Observable<ImportMemberResp> {
    return this.http
      .post<ImportMemberResp>(`/auth/private/v1/organizations/members/imports`, req, {
        withCredentials: true
      })
      .pipe(map(resp => new ImportMemberResp(resp)));
  }

  importMemberIncludeUsername(orgUuid: string, req: ImportMemberRequest): Observable<ImportMemberResp> {
    return this.http
      .post<ImportMemberResp>(`/auth/private/v2/organizations/${orgUuid}/members/bulk`, req, {
        withCredentials: true
      })
      .pipe(map(resp => new ImportMemberResp(resp)));
  }

  resendActivationEmails(req: ResendActivationEmailReq): Observable<void> {
    return this.http.put<void>(`/auth/private/v1/organizations/members/imports/activations`, req);
  }

  deletePendingMembers(id: number): Observable<void> {
    return this.http.delete<void>(`/auth/private/v1/organizations/members/imports/activations/${id}`);
  }

  getPendingMembers(req: GetMembersReq): Observable<PendingMember[]> {
    const params = new HttpParams().set('activated', 'false').set('keyword', req.keyword || '');

    return this.http
      .get<PendingMember[]>(`/auth/private/v1/organizations/members/imports/activations`, { params: params })
      .pipe(
        map(list =>
          list.map(m => {
            return new PendingMember({ ...m, member: new Identity(m.member) });
          })
        ),
        tap(members => {
          switch (req.sort) {
            case 'identity.displayName':
              members = members.sort((a, b) => a?.member.displayName?.localeCompare(b?.member.displayName));
              break;
            case 'email':
              members = members.sort((a, b) => a?.email?.localeCompare(b?.email));
              break;
          }
          if (members.length > 0) {
            this.orgMemberStore.update({ hasPendingMember: true });
          }
        })
      );
  }

  fetchMemberPins(orgUuid: string, memberUuid: string): Observable<MemberPin[]> {
    return this.http.get<MemberPin[]>(`auth/private/v1/organizations/${orgUuid}/members/${memberUuid}/pins`);
  }

  createMemberPin(orgUuid: string, memberUuid: string) {
    return this.http.put(`auth/private/v1/organizations/${orgUuid}/members/${memberUuid}/pins`, {});
  }

  updateMember(orgUuid: string, memberUuid: string, req: MemberUpdateRequest): Observable<void> {
    return this.http.put<void>(`auth/private/v1/organizations/${orgUuid}/members/${memberUuid}`, req);
  }

  updateVIPMemberForLicenseOrg(memberUuid: string, req: MemberUpdateRequest): Observable<void> {
    return this.http.put<void>(`directory/private/v1/members/${memberUuid}`, req);
  }

  getRoleList(orgUuid: string): Observable<MemberRole[]> {
    return this.http.get<MemberRole[]>(`/auth/private/v1/organizations/${orgUuid}/roles`);
  }

  createCredential(orgUUid: string, memberUuid: string, createCredentialRequest: CreateCredentialRequest) {
    return this.http.post(
      `/auth/private/v1/organizations/${orgUUid}/members/${memberUuid}/credentials`,
      createCredentialRequest
    );
  }

  activeMember(member: Member) {
    this.orgMemberStore.setActive(member.uuid);
  }

  removeActive(id: ID) {
    if (id) {
      this.orgMemberStore.removeActive(id);
    }
  }

  transferOwner(orgUuid: string, newOwnerUuid: string): Observable<void> {
    const body = {
      newOwnerUuid: newOwnerUuid
    };
    return this.http.put<void>(`/auth/private/v1/organizations/${orgUuid}/owners`, body);
  }

  // member policy
  getPolicyDocument(orgUuid: string, memberUuid: string, upsert: boolean = false): Observable<PolicyDocument> {
    return this.http.get<PolicyDocument>(`auth/private/v2/organizations/${orgUuid}/iam/members/${memberUuid}`).pipe(
      map(policy => new PolicyDocument(policy).cleanupPolicies()),
      tap(policy =>
        upsert
          ? this.orgMemberStore.upsert(memberUuid, { iamPolicy: policy })
          : this.orgMemberStore.update(memberUuid, { iamPolicy: policy })
      )
    );
  }

  updatePolicyDocument(orgUuid: string, memberUuid: string, req: PolicyDocument): Observable<PolicyDocument> {
    req.cleanupPolicies();
    return this.http
      .put<PolicyDocument>(`/auth/private/v1/organizations/${orgUuid}/iam/members/${memberUuid}`, req)
      .pipe(
        map(resp => new PolicyDocument(resp)),
        tap(resp => this.orgMemberStore.update(memberUuid, { iamPolicy: resp }))
      );
  }

  appPolicyPermission(
    orgUuid: string,
    memberUuid: string,
    req: Partial<IAMGrantedPermission>
  ): Observable<PolicyDocument> {
    return this.http
      .put<PolicyDocument>(`auth/private/v1/organizations/${orgUuid}/iam/members/${memberUuid}/append`, req)
      .pipe(
        map(resp => new PolicyDocument(resp)),
        tap(resp => this.orgMemberStore.update(memberUuid, { iamPolicy: resp }))
      );
  }

  removePolicyPermission(
    orgUuid: string,
    memberUuid: string,
    req: Partial<IAMGrantedPermission>
  ): Observable<PolicyDocument> {
    return this.http
      .put<PolicyDocument>(`auth/private/v1/organizations/${orgUuid}/iam/members/${memberUuid}/remove`, req)
      .pipe(
        map(resp => new PolicyDocument(resp)),
        tap(resp => this.orgMemberStore.update(memberUuid, { iamPolicy: resp }))
      );
  }

  getMembersV3(req: GetMembersReq, pageable?: Pageable, addon?: { ignoreStore: boolean }): Observable<Page<Member>> {
    this.orgMemberStore.setLoading(true);
    let params = new HttpParams();
    if (pageable) {
      params = params.set('page', String(pageable.page)).set('size', String(pageable.perPage));
    }

    if (req.sort) {
      params = params.set('sort', req.sort);
    }

    return this.http
      .post<Member[]>(
        `auth/private/v3/organizations/${req.orgUuid}/members`,
        { keyword: req.keyword },
        {
          params: params,
          observe: 'response'
        }
      )
      .pipe(
        map(resp => {
          const page = new Page<Member>();
          page.content = resp.body.map(mem => new Member(mem));
          page.totalCount = +resp.headers.get(X_PAGINATION.totalCount);
          return page;
        }),
        tap(page => {
          if (!addon?.ignoreStore) {
            this.orgMemberStore.set(page.content);
          }

          this.orgMemberStore.setLoading(false);
        })
      );
  }
}
