import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Channel, DataFormTicket, UpdateDataFormTicketData } from './sourceChannel.model';

export interface SourceChannelState extends EntityState<Channel> {
  dataFormTicket: DataFormTicket;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_source-channel', idKey: 'uuid' })
export class SourceChannelStore extends EntityStore<SourceChannelState> {
  constructor() {
    super({ dataFormTicket: {} });
  }

  updateDataFormTicket(data: UpdateDataFormTicketData) {
    const { configFlowType, chatRoutingMode, inboxUuid, prevInboxUuid, formInbox } = data;
    this.update(state => {
      const dataFormTicket = structuredClone(state.dataFormTicket);
      if (prevInboxUuid && prevInboxUuid !== inboxUuid) {
        dataFormTicket?.[configFlowType]?.[chatRoutingMode]?.[prevInboxUuid] &&
          delete dataFormTicket[configFlowType][chatRoutingMode][prevInboxUuid];
      }
      if (dataFormTicket[configFlowType]) {
        const temp = structuredClone(dataFormTicket[configFlowType][chatRoutingMode] || {});
        Object.assign(temp, { [inboxUuid]: formInbox });
        dataFormTicket[configFlowType][chatRoutingMode]
          ? Object.assign(dataFormTicket[configFlowType][chatRoutingMode], temp)
          : Object.assign(dataFormTicket[configFlowType], { [chatRoutingMode]: temp });
      } else Object.assign(dataFormTicket, { [configFlowType]: { [chatRoutingMode]: { [inboxUuid]: formInbox } } });

      return { ...state, dataFormTicket: dataFormTicket };
    });
  }

  clearDataFormTicket() {
    this.update(state => ({ ...state, dataFormTicket: {} }));
  }
}
