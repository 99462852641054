import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { downloadData } from '@b3networks/shared/common';

@Component({
  selector: 'b3n-recovery-key',
  templateUrl: './recovery-key.component.html',
  styleUrls: ['./recovery-key.component.scss']
})
export class RecoveryKeyComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public recoveryKey: string,
    public dialogRef: MatDialogRef<RecoveryKeyComponent>
  ) {}

  ngOnInit(): void {}

  download() {
    const file = new Blob([`RECOVERY KEY:\n\n${this.recoveryKey}`], { type: 'text/plain;charset=utf-8' });
    downloadData(file, 'recovery-key.txt');
    this.dialogRef.close(true);
  }
}
