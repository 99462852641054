import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FormDataTxn } from './form-data.model';

export interface FormDataState extends EntityState<FormDataTxn> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'form-data-txn', idKey: 'txnUuid' })
export class FormDataStore extends EntityStore<FormDataState> {
  constructor() {
    super();
  }
}
