<div
  (@fadeAnimation.done)="onFadeFinished($event)"
  [@fadeAnimation]="{ value: animationState, params: { fadeIn: 150, fadeOut: 250 } }"
  [ngClass]="{
    success: data.type === 'success',
    warning: data.type === 'warning',
    error: data.type === 'error',
    default: !data.type
  }"
  class="toast"
>
  <div class="toast__message" [innerHTML]="data.message"></div>
  <mat-icon (click)="close()">close</mat-icon>
</div>
