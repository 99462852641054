import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';
import { WaNumbersState, WaNumbersStore } from './wa-numbers.store';

@Injectable({ providedIn: 'root' })
export class WaNumbersQuery extends QueryEntity<WaNumbersState> {
  constructor(protected override store: WaNumbersStore) {
    super(store);
  }

  getAllNumber() {
    return this.getAll();
  }

  selectAllNumber() {
    return this.selectAll();
  }

  selectId(phoneNumberId: string) {
    return this.selectEntity(phoneNumberId);
  }

  selectIdByInboxUuid(inboxUuid: string) {
    return this.selectAll({
      filterBy: entity => entity?.inbox?.uuid === inboxUuid,
      limitTo: 1
    }).pipe(map(l => l?.[0]));
  }
}
