import { Injectable } from '@angular/core';
import { UUID_V4_REGEX } from '@b3networks/shared/common';
import { CacheMedia } from './cache-media.model';
import { CacheMediaQuery, EXPIRED_CACHE_MEDIA } from './cache-media.query';
import { CacheMediaStore } from './cache-media.store';

@Injectable({
  providedIn: 'root'
})
export class CacheMediaService {
  constructor(private store: CacheMediaStore, private query: CacheMediaQuery) {}

  add(media: CacheMedia) {
    const item = this.query.getEntity(media.key);
    if (item && item?.url && item?.url?.includes('blob:')) {
      console.log('revokeObjectURL: ', item);
      URL.revokeObjectURL(item.url);
    }
    this.store.remove(media.key);
    this.store.add(media);
    this._jobClearBlobExpired();
  }

  remove(key: string) {
    let s3KeyWithouOrgUuid = key;
    const rs: RegExpMatchArray = s3KeyWithouOrgUuid.match(UUID_V4_REGEX);
    if (rs && rs.index === 0) {
      s3KeyWithouOrgUuid = s3KeyWithouOrgUuid.slice(37);
    }
    this.store.remove(s3KeyWithouOrgUuid);
    this._jobClearBlobExpired();
  }

  private _jobClearBlobExpired() {
    const all = [...this.query.getAll()];
    all.forEach(media => {
      if (!!media?.time && media.time <= Date.now() - EXPIRED_CACHE_MEDIA) {
        if (media && media?.url && media?.url?.includes('blob:')) {
          URL.revokeObjectURL(media.url);
        }
        this.store.remove(media.key);
      }
    });
  }
}
