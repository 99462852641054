import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CaseActivity } from './activities.model';

export interface ActivitiesState extends EntityState<CaseActivity> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_sc_activities', idKey: 'id' })
export class ActivitiesStore extends EntityStore<ActivitiesState> {
  constructor() {
    super();
  }
}
