import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { format, parse } from 'date-fns';
import { takeUntil, tap } from 'rxjs';
import { DestroySubscriberComponent } from '../destroy-subscriber.component';

@Component({
  selector: 'shc-input-timepicker',
  templateUrl: './input-timepicker.component.html',
  styleUrls: ['./input-timepicker.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxMatTimepickerModule
  ]
})
export class InputTimepickerComponent extends DestroySubscriberComponent implements OnInit, OnChanges {
  @Input() placeholder = 'Time';
  @Input() showSpinners = true;
  @Input() showSeconds = false;
  @Input() disabled = false;
  @Input() stepHour = 1;
  @Input() stepMinute = 1;
  @Input() stepSecond = 1;
  @Input() timeValue: Date | null | string;
  @Input() required: boolean;

  @Output() changeTime = new EventEmitter();
  @Output() setValid = new EventEmitter<boolean>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('inputRef', { read: NgModel, static: true }) ngModelRef: NgModel;

  time: Date | null = parse(this.showSeconds ? '00:00:00' : '00:00', 'HH:mm', new Date());

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['timeValue'].currentValue) this.time = new Date(this.timeValue);
    else this.time = parse(this.showSeconds ? '00:00:00' : '00:00', 'HH:mm', new Date());
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    this.ngModelRef.statusChanges
      .pipe(
        takeUntil(this.destroySubscriber$),
        tap(status => {
          this.setValid.emit(status === 'VALID');
        })
      )
      .subscribe();
  }

  onClosedMenu() {
    this.time = this.timeValue
      ? new Date(this.timeValue)
      : parse(this.showSeconds ? '00:00:00' : '00:00', 'HH:mm', new Date());
  }

  onSaveTime() {
    this.changeTime.emit(this.time);
    this.trigger.closeMenu();
  }

  get timeInputFormat() {
    return this.timeValue ? format(new Date(this.timeValue), 'HH:mm') : '';
  }
}
