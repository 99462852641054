export enum ROUTE_LINK {
  org_link = 'org-link'
}

export enum RESOURCE_CONNECT {
  dnc = 'DNC',
  inbox = 'Inbox',
  numbers = 'Numbers',
  teams = 'Teams',
  channel = 'Channel'
}

export enum API_TYPE_CONNECT {
  Inbox = 'INBOX',
  Numbers = 'NUMBER',
  Teams = 'TEAM',
  Ticket_Channel = 'TICKET_CHANNEL'
}

export enum ORG_CONNECT_STATUS {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  pending = 'PENDING',
  rejected = 'REJECTED'
}
