import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from '@b3networks/api/contact';
import { X_B3_HEADER } from '@b3networks/shared/common';
import { HashMap, ID } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TxnChannel } from '../txn/txn.model';
import { InboxUI } from './inboxes-ui.model';
import {
  ChannelType,
  Inbox,
  OrgInboxConfig,
  Schedule,
  StoreInboxRequest,
  StoreInboxRequestV5,
  SupplierInboxInfo,
  UpdateInboxRoutingModeReq
} from './inboxes.model';
import { InboxesQuery } from './inboxes.query';
import { InboxesState, InboxesStore } from './inboxes.store';

@Injectable({
  providedIn: 'root'
})
export class InboxesService {
  inboxes: Inbox[];

  constructor(
    private http: HttpClient,
    private store: InboxesStore,
    private query: InboxesQuery
  ) {}

  getAll() {
    return this.http.get<Inbox[]>('inbox/private/v2/inboxes').pipe(
      map(responses => {
        this.inboxes = responses.map(response => new Inbox(response));
        return this.inboxes;
      }),
      tap(responses => {
        this.store.set(responses);
        this.store.update({
          loaded: true
        });
      })
    );
  }

  getInboxesBychannel(channel: ChannelType | TxnChannel) {
    const params = new HttpParams().append('channel', channel);
    return this.http.get<Inbox[]>(`inbox/private/v2/inboxes`, { params: params });
  }

  getDetail(inboxUuid: string) {
    return this.http.get<Inbox>(`inbox/private/v2/inboxes/${inboxUuid}`).pipe(
      map(data => new Inbox(data)),
      tap(data => {
        this.store.upsertMany([data], { baseClass: Inbox });
      })
    );
  }

  createInbox(req: StoreInboxRequest) {
    const headers = new HttpHeaders().set(X_B3_HEADER.validateXSRFToken, 'true');
    return this.http.post<Inbox>('inbox/private/v3/inboxes', req, { headers }).pipe(
      map(data => new Inbox(data)),
      tap(res => {
        this.store.upsertMany([res], { baseClass: Inbox });
      })
    );
  }

  createInboxV5(req: StoreInboxRequestV5) {
    const headers = new HttpHeaders().set(X_B3_HEADER.validateXSRFToken, 'true');
    return this.http.post<Inbox>('inbox/private/v5/inboxes', req, { headers }).pipe(
      map(data => new Inbox(data)),
      tap(res => {
        this.store.upsertMany([res]);
      })
    );
  }

  updateInbox(inboxUuid: string, req: StoreInboxRequest) {
    return this.http.put<Inbox>(`inbox/private/v3/inboxes/${inboxUuid}`, req).pipe(
      map(data => new Inbox(data)),
      tap(res => {
        this.store.upsertMany([res], { baseClass: Inbox });
      })
    );
  }

  searchContact(keyword: string) {
    const params = new HttpParams().set('keyword', keyword === '' ? '+' : keyword);
    return this.http.get<Contact[]>(`inbox/private/v3/customers`, { params });
  }

  getSingleContact(contactUuid: string) {
    return this.http.get<Contact>(`inbox/private/v2/customers/${contactUuid}`).pipe(map(x => new Contact(x)));
  }

  updateRoutingMode(inboxUuid: string, req: Partial<UpdateInboxRoutingModeReq>) {
    return this.http
      .put(`/inbox/private/v3/inboxes/${inboxUuid}/routingMode`, req, {
        observe: 'response'
      })
      .pipe(map(res => (res.status === 200 ? true : false)));
  }

  getSupplierInboxes(supplierOrgUuid: string, channel: ChannelType) {
    const params = new HttpParams().append('channel', channel);
    return this.http
      .get<SupplierInboxInfo[]>(`inbox/private/v2/inboxes/supplier/${supplierOrgUuid}`, {
        params: params
      })
      .pipe(
        map(inboxes => inboxes as SupplierInboxInfo[]),
        tap(supplierInboxes => {
          this.store.update({ supplierInboxes });
        })
      );
  }

  getCountPendingInbox(inboxUuids: string[]) {
    const params = new HttpParams().append('inboxUuids', inboxUuids?.join(','));
    return this.http.get<HashMap<number>>(`inbox/private/v2/inboxes/_countPendingTxns`, { params: params }).pipe(
      tap(data => {
        const list = Object.keys(data);
        const inboxes = list?.map(key => {
          return <Inbox>{
            uuid: key,
            pendingCountTxn: data[key]
          };
        });

        inboxUuids.forEach(inboxUuid => {
          if (!list.includes(inboxUuid)) {
            inboxes.push(<Inbox>{
              uuid: inboxUuid,
              pendingCountTxn: 0
            });
          }
        });

        this.updateInboxState(inboxes);
      })
    );
  }

  setActive(inboxUuid: string | ID) {
    this.store.setActive(inboxUuid);
  }

  removeActive(inboxUuid: string | ID) {
    this.store.removeActive(inboxUuid);
  }

  updateInbox2Store(inboxUuid: string | ID, data: Partial<Inbox>) {
    this.store.upsert(inboxUuid, data, { baseClass: Inbox });
  }

  updateInboxState(inboxes: Inbox[]) {
    this.store.upsertMany(inboxes, { baseClass: Inbox });
  }

  updateStateStore(data: Partial<InboxesState>) {
    this.store.update(data);
  }

  updateInboxWorkingHours(inboxUuid: string, data: Schedule) {
    this.store.update(inboxUuid, { workingHour: data });
  }

  updateUIViewState(inboxUuid: string | string[] | ID, state: Partial<InboxUI>) {
    this.store.ui.update(inboxUuid, entity => ({
      ...entity,
      ...state
    }));
  }

  evictCacheInbox(queueUuid: string) {
    return this.http.put<any>(`inbox/private/v1/queues/${queueUuid}/evictCache`, {});
  }

  inscreasePendingTxn(inboxUuid: string) {
    const inbox = this.query.getEntity(inboxUuid);
    if (inbox) {
      this.updateInbox2Store(inboxUuid, {
        pendingCountTxn: (inbox?.pendingCountTxn || 0) + 1
      });
    }
  }

  descreasePendingTxn(inboxUuid: string) {
    const inbox = this.query.getEntity(inboxUuid);
    if (inbox) {
      this.updateInbox2Store(inboxUuid, {
        pendingCountTxn: inbox?.pendingCountTxn > 0 ? inbox?.pendingCountTxn - 1 : 0
      });
    }
  }

  getInboxOrgConfig() {
    const req$ = this.http.get<OrgInboxConfig>('inbox/private/v1/org/config').pipe(map(res => new OrgInboxConfig(res)));
    return req$;
  }

  updateInboxOrgConfig(req: Partial<OrgInboxConfig>) {
    return this.http.put<OrgInboxConfig>('inbox/private/v1/org/config', req).pipe(map(res => new OrgInboxConfig(res)));
  }

  deleteInbox(inboxUuid: string) {
    return this.http.delete<void>('inbox/private/v1/inboxes/' + inboxUuid).pipe(
      tap(() => {
        this.store.remove(inboxUuid);
      })
    );
  }

  getWorkingHourInbox(inboxUuid: string) {
    return this.http.get<Schedule>(`inbox/private/v3/inboxes/${inboxUuid}/workinghour`);
  }

  updateWorkingHourInbox(inboxUuid: string, data: Schedule) {
    return this.http.put<Schedule>(`inbox/private/v3/inboxes/${inboxUuid}/workinghour`, data);
  }

  getInboxesFromCache(): Observable<Inbox[]> {
    if (!this.inboxes) {
      return this.getAll();
    }

    return of(this.inboxes);
  }
}
