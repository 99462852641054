export enum ChannelType {
  gc = 'gc',
  dm = 'dm',
  PERSONAL = 'PERSONAL',
  THREAD = 'THREAD'
}

export enum NameChannelPersonal {
  BOOKMARKS = 'BOOKMARKS'
}
