<h2 mat-dialog-title>
  <span *ngIf="isTransferTxn; else defaultTitle">Transfer Chat</span>
  <ng-template #defaultTitle>
    <span>Assign Chat</span>
  </ng-template>
</h2>

<ng-container *ngIf="loading">
  <div class="container-dialog flex flex-col justify-center items-center" mat-dialog-content>
    <mat-spinner diameter="35"></mat-spinner>
  </div>
</ng-container>

<div *ngIf="!loading" mat-dialog-content>
  <mat-form-field *ngIf="!data.onlyAgent">
    <mat-label>{{ isTransferTxn ? 'Transfer chat' : 'Assign chat' }}</mat-label>
    <mat-select [formControl]="typeCtr">
      <mat-option *ngFor="let opt of typeOpts" [value]="opt.key">{{ opt.value }}</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container [ngSwitch]="typeCtr.value">
    <ng-container *ngSwitchCase="TypeTransfer.agent">
      <mat-form-field>
        <mat-label>Agent</mat-label>
        <input [formControl]="agentCtr" [matAutocomplete]="auto" matInput />
        <button
          [class.hidden]="!agentCtr.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="agentCtr.setValue(null)"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let agent of members; trackBy: trackBy" [value]="agent" class="option-text">
            <csh-render-member-v2
              [isBot]="false"
              [avatarUrl]="agent?.photoUrlOrDefault"
              [status]="agent?.agentStatus"
              [displayName]="agent?.displayName"
              [showDisplayName]="true"
              [showStatus]="true"
              [isSmallV2]="true"
              [key]="searchKey"
            ></csh-render-member-v2>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="TypeTransfer.inbox">
      <mat-form-field>
        <mat-label>Inbox</mat-label>
        <input [formControl]="inboxCtr" [matAutocomplete]="auto" matInput />
        <button
          [class.hidden]="!inboxCtr.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="inboxCtr.setValue(null)"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnInbox">
          <mat-option *ngFor="let inbox of inbox$ | async; trackBy: trackByInbox" [value]="inbox" class="option-text">
            <span [innerHTML]="inbox.name | highlight: searchKey"></span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </ng-container>
</div>

<div mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" [disabled]="!validForm" [loading]="processing" (click)="submit()">
    {{ isTransferTxn ? 'Transfer' : 'Assign' }}
  </button>
</div>
