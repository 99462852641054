import { ModuleWithProviders, NgModule } from '@angular/core';
import { ListToastsComponent } from './component/list-toasts/list-toasts.component';
import { ToastComponent } from './component/toast/toast.component';
import { TOAST_CONFIG_TOKEN, defaultToastConfig } from './config/toast-configs';

const component = [ToastComponent, ListToastsComponent];

@NgModule({
  imports: [component],
  declarations: []
})
export class SharedUiToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<SharedUiToastModule> {
    return {
      ngModule: SharedUiToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config }
        }
      ]
    };
  }
}
