import { NgxMatDateFormats, NgxMatNativeDateAdapter } from '@angular-material-components/datetime-picker';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';

export const B3N_NGX_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'dd/MM/yyyy, HH:mm',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};

@Injectable()
export class CustomDateAdapter extends NgxMatNativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
    return format(new Date(date), displayFormat.toString());
  }
}
