import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FormFieldSCConfig } from './form-integration.model';

export interface FormIntegrationSCState extends EntityState<FormFieldSCConfig> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'form-integration-sc', idKey: 'templateUuid' })
export class FormIntegrationSCStore extends EntityStore<FormIntegrationSCState> {
  constructor() {
    super();
  }
}
