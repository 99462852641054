import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class StarInfo {
  convoId: string;
  hsId: string;
}

@Injectable({
  providedIn: 'root'
})
export class StarService {
  constructor(private http: HttpClient) {}

  getStarChannels() {
    return this.http.get<StarInfo[]>('workspace2/private/v2/team/stars').pipe(
      map(x => x || []),
      catchError(_ => of([]))
    );
  }

  starChannelTeamChat(id: string) {
    return this.http.post(`workspace/private/v2/team-chat/${id}/star`, {}).pipe(catchError(_ => of(true)));
  }

  unstarChannelTeamChat(id: string) {
    return this.http.delete(`workspace/private/v2/team-chat/${id}/star`).pipe(catchError(_ => of(true)));
  }

  starChannelHyper(id: string, hsId: string) {
    return this.http.post(`workspace2/private/v2/team/${id}/hyperspace/${hsId}/star`, {});
  }

  unstarChannelHyper(id: string, hsId: string) {
    return this.http.delete<{ hyperchannels: { id: string; name: string }[] }>(
      `workspace2/private/v2/team/${id}/hyperspace/${hsId}/star`
    );
  }
}
