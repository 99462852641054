import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FormIntegrationInboxState, FormIntegrationInboxStore } from './form-integration-inbox.store';

@Injectable({ providedIn: 'root' })
export class FormIntegrationInboxQuery extends QueryEntity<FormIntegrationInboxState> {
  constructor(protected override store: FormIntegrationInboxStore) {
    super(store);
  }

  getFormIntegration(templateUuid: string) {
    return this.getEntity(templateUuid);
  }
}
