import { Injectable } from '@angular/core';
import { EntityState, EntityStore, HashMap, StoreConfig } from '@datorama/akita';
import { Agent, AgentMember } from './agent-config';

export interface AgentState extends EntityState<Agent> {
  teams: HashMap<AgentMember>;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'callcenter_agent', idKey: 'identityUuid' })
export class AgentStore extends EntityStore<AgentState> {
  constructor() {
    super({
      teams: {}
    });
  }
}
