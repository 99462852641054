import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OrgConnection } from './org-link.model';

export function createInitialState(): OrgConnectionState {
  return {} as OrgConnectionState;
}

export interface OrgConnectionState extends EntityState<OrgConnection>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'org-connection', idKey: 'uuid' })
export class OrgConnectionStore extends EntityStore<OrgConnectionState> {
  constructor() {
    super(createInitialState());
  }
}
