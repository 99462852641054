import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GetLicenseStatisticReq, LicenseStatistic } from './license-stat.model';
import { LicenseStatStore } from './license-stat.store';

@Injectable({
  providedIn: 'root'
})
export class LicenseStatService {
  constructor(private http: HttpClient, private store: LicenseStatStore) {}

  getLicenseStatistics(
    req?: GetLicenseStatisticReq,
    addon?: { useCache?: boolean; noStore?: boolean }
  ): Observable<LicenseStatistic[]> {
    let params = new HttpParams();
    if (req && req?.baseSku) {
      params = params.set('baseSku', req.baseSku);
    }

    const req$ = this.http.get<LicenseStatistic[]>(`license/private/v3/licenses/statistics`, { params }).pipe(
      map(list => list.map(l => new LicenseStatistic(l))),
      tap(result => {
        if (!addon?.noStore) {
          this.store.set(result);
        }
      })
    );

    if (addon && addon.useCache && this.store.getValue().ids.length) {
      return EMPTY;
    }
    return req$;
  }

  getAddonsNotInBundle(baseSku: string): Observable<LicenseStatistic[]> {
    const params = new HttpParams().set('baseSku', baseSku);
    return this.http.get<LicenseStatistic[]>(`/license/private/v3/licenses/statistics/addonNotInBundle`, {
      params: params
    });
  }
}
