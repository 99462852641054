import { ChatMessage } from '../chat-message/chat-message.model';
import { ConvoType, UserType } from '../enums.model';

export class PinMessage {
  userId: string;
  messageId: string;
  at: number;
  message?: ChatMessage;

  // ui
  convoId?: string;

  constructor(obj: Partial<PinMessage>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj?.at) {
        this.at = +obj.at || 0;
      }
    }
  }
}

export interface PinMessageRequest {
  convoId: string;
  convoType: ConvoType;
  userType: UserType;
  messageId: string;
}

export interface GetAllPinRequest {
  options: {
    withMessageContent: boolean;
  };
  convoId: string;
  convoType: ConvoType;
}
