import { TxnChannel } from '../txn/txn.model';

export class RelatesTo {
  fromTxn: string;
  orgUuid: string;
  listRelatedTo: CaseRelatedTo[];

  constructor(obj?: Partial<RelatesTo>) {
    if (obj) {
      Object.assign(this, obj);
      this.listRelatedTo = obj?.listRelatedTo?.map(x => new CaseRelatedTo(x)) || [];
    }
  }

  get key() {
    return `${this.orgUuid}_${this.fromTxn}`;
  }
}

export class CaseRelatedTo {
  uuid: string;
  txnUuid: string;
  orgUuid: string;
  title: string;
  channel: TxnChannel;
  mode?: ModeRelatedTxn;
  sid?: number;
  constructor(obj?: Partial<CaseRelatedTo>) {
    if (obj) {
      Object.assign(this, obj);
      this.txnUuid = this.uuid;
    }
  }

  get channelDisplay(): string {
    switch (this.channel) {
      case TxnChannel.call:
        return 'Call';
      case TxnChannel.livechat:
        return 'Live Chat';
      case TxnChannel.supportCenter:
        return 'Support Ticket';
      case TxnChannel.email:
        return 'Email';
      case TxnChannel.sms:
        return 'SMS';
      case TxnChannel.whatsapp:
        return 'WhatsApp';
      default:
        return this.channel;
    }
  }
  get channelIconDisplay() {
    switch (this.channel) {
      case TxnChannel.livechat:
        return 'b3n_inbox_livechat';
      case TxnChannel.supportCenter:
        return 'b3n_inbox_ticket';
      case TxnChannel.call:
        return 'b3n_inbox_call';
      case TxnChannel.whatsapp:
        return 'b3n_inbox_whatsapp';
      case 'email':
        return 'b3n_inbox_mail';
      default:
        return 'b3n_inbox_ticket';
    }
  }
}

export class ToastTxnCreated {
  txnUuid: string;
  displayText: string;
  srcOrgUuid?: string;
}

export enum ModeRelatedTxn {
  normal = 'normal',
  converted = 'converted'
}

export interface ReqCasesRelatedTo {
  channels: TxnChannel[];
  textSearch: string;
}

export interface QueryCaseRelatedTo {
  txnUuid: string;
  title: string;
  sid: number;
  channel: TxnChannel;
}
