import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, Pageable } from '@b3networks/api/common';
import { X_B3_HEADER } from '@b3networks/shared/common';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import {
  EmailDomainRes,
  OrgSecurityPolicy,
  SecurityCompliance,
  SecurityPolicy,
  SecurityPolicyDetail
} from './security';
import { SecurityComplianceStore } from './security.store';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private domainPolicy$: Observable<any>;

  constructor(private http: HttpClient, private store: SecurityComplianceStore) {}

  getSecurityCompliance(): Observable<SecurityCompliance> {
    return this.http
      .get<SecurityCompliance>('/auth/private/v1/security/compliance')
      .pipe(tap(res => this.store.update(res)));
  }

  getSecurityPolicy(pageable: Pageable): Observable<Page<SecurityPolicyDetail>> {
    let params = new HttpParams();
    params = params.set('page', String(pageable.page)).set('size', String(pageable.perPage));
    return this.http
      .get<SecurityPolicyDetail[]>(`auth/private/v1/security/policy/all`, { params: params, observe: 'response' })
      .pipe(
        map(resp => {
          const content = resp.body.map(x => new SecurityPolicyDetail(x));
          const totalCount = +resp.headers.get(X_B3_HEADER.totalCount);
          return {
            totalCount: totalCount,
            content: content
          } as Page<SecurityPolicyDetail>;
        }),
        tap(resp => this.store.update({ securityPolicyDetail: resp.content }))
      );
  }

  updateSecurityPolicy(data: SecurityPolicy, domainKey: string): Observable<void> {
    let params = new HttpParams();
    params = params.set('domainKey', domainKey);
    return this.http.put<void>(`auth/private/v1/security/policy`, data, {
      params
    });
  }

  getDomainSecurityPolicy(domainKey: string) {
    let params = new HttpParams();
    params = params.set('domainKey', domainKey);
    return this.http
      .get<SecurityPolicy>(`auth/private/v1/security/policy`, {
        params
      })
      .pipe(map(res => new SecurityPolicy(res)));
  }

  getDomainPolicy(): Observable<any> {
    if (!this.domainPolicy$) {
      this.domainPolicy$ = this.http.get<any>(`/auth/private/v1/security/policy`).pipe(shareReplay(1));
    }

    return this.domainPolicy$;
  }

  getClaimedEmailDomains = (): Observable<EmailDomainRes[]> => {
    return this.http.get<EmailDomainRes[]>('auth/private/v1/organizations/emailDomains').pipe(
      map(items => {
        return items.map(item => ({
          emailDomain: item.domain,
          requestedAt: item.requestedAt,
          status: item.status,
          approverNote: item.approverNote
        }));
      })
    );
  };

  claimEmailDomain = (domain: string) => {
    return this.http.post<EmailDomainRes>('auth/private/v1/organizations/emailDomains', {
      domain: domain
    });
  };

  getResetPasswordSecurityPolicies = (token: string) => {
    const params = new HttpParams().set('token', token);

    return this.http
      .get<SecurityPolicy>('auth/private/v1/password/policy', { params })
      .pipe(map(res => new SecurityPolicy(res)));
  };

  getOrgSecurityPolicies = (emailDomain?: string) => {
    let params = new HttpParams();
    if (emailDomain) {
      params = params.set('emailDomain', emailDomain);
    }

    return this.http
      .get<OrgSecurityPolicy[]>('auth/private/v1/organizations/securityPolicies', { params })
      .pipe(map(res => res.map(data => new OrgSecurityPolicy(data))));
  };

  updateOrgSecurityPolicy(req: OrgSecurityPolicy) {
    return this.http
      .put<OrgSecurityPolicy>('auth/private/v1/organizations/securityPolicies', req)
      .pipe(map(res => new OrgSecurityPolicy(res)));
  }
}
