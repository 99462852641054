import { CommonModule, KeyValue } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { AgentStatus } from '@b3networks/api/callcenter';
import { DirectoryAgent, DirectoryMemberService, GetDirectoryMembersByFeatureCodeReq } from '@b3networks/api/directory';
import { Inbox, InboxesQuery, RequestActionTxn, Txn, TxnChannel, TxnService } from '@b3networks/api/inbox';
import { LicenseFeatureCode } from '@b3networks/api/license';
import { User, UserQuery } from '@b3networks/api/workspace';
import { RenderMemberV2Component } from '@b3networks/chat/shared/core';
import { DestroySubscriberComponent, HighlightPipe, X } from '@b3networks/shared/common';
import { ButtonLoadingDirective } from '@b3networks/shared/ui/material';
import { ToastService } from '@b3networks/shared/ui/toast';
import { Observable, combineLatest, fromEvent, of } from 'rxjs';
import { debounceTime, delay, filter, finalize, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface TxnTransferTransactionData {
  txnUuid: string;
  channel: TxnChannel;
  onlyAgent: boolean;
  txn?: Txn;
}

enum TypeTransfer {
  agent = 'agent',
  inbox = 'inbox'
}

class AgentCustom extends User implements DirectoryAgent {
  agentStatus: AgentStatus;
  memberUuid: string;
}

const DEFAULT_SIZE = 20;

@Component({
  selector: 'b3n-txn-transfer-transaction',
  templateUrl: './txn-transfer-transaction.component.html',
  styleUrls: ['./txn-transfer-transaction.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    RenderMemberV2Component,
    MatButtonModule,
    ButtonLoadingDirective,
    HighlightPipe,
    MatIconModule,
    MatDialogModule
  ]
})
export class TxnTransferTransactionComponent extends DestroySubscriberComponent implements OnInit, AfterViewInit {
  @ViewChild('memberInput') memberInput: ElementRef;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

  processing: boolean;
  loading = false;
  isTransferTxn: boolean;

  typeCtr = new FormControl(TypeTransfer.agent);

  inbox$: Observable<Inbox[]>;
  inboxCtr = new FormControl();

  // agent form
  agentCtr = new FormControl();
  members: AgentCustom[] = [];
  hasMore: boolean;
  typeOpts: KeyValue<TypeTransfer, string>[] = [];
  private reqMembers = <GetDirectoryMembersByFeatureCodeReq>{
    keyword: '',
    featureCode: LicenseFeatureCode.license_livechat,
    page: 1,
    perPage: DEFAULT_SIZE
  };
  private _isLoadingMore: boolean;

  readonly TypeTransfer = TypeTransfer;

  get searchKey() {
    const key = this.agentCtr.value;
    return typeof key === 'string' ? key : '';
  }

  get validForm() {
    return this.typeCtr.value === TypeTransfer.agent
      ? this.agentCtr.value instanceof AgentCustom
      : this.inboxCtr.value instanceof Inbox;
  }

  constructor(
    private userQuery: UserQuery,
    private inboxesQuery: InboxesQuery,
    private txnService: TxnService,
    @Inject(MAT_DIALOG_DATA) public data: TxnTransferTransactionData,
    private dialogRef: MatDialogRef<TxnTransferTransactionComponent>,
    private directoryMemberService: DirectoryMemberService,
    private toastService: ToastService,
    private ngZone: NgZone
  ) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.memberInput?.nativeElement?.focus();
    }, 300);
  }

  ngOnInit() {
    if (!this.data.txn) {
      this.loading = true;
      this.txnService
        .getDetailTxnV2(X.orgUuid, this.data.txnUuid)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(detail => {
          this.data.txn = detail.txn;
          this.initForm();
        });
    } else {
      this.initForm();
    }
  }

  trackBy(i, member: AgentCustom) {
    return member?.memberUuid;
  }

  displayFn(member: User | string): string {
    return (<User>member)?.displayName;
  }

  trackByInbox(i, inbox: Inbox) {
    return inbox?.uuid;
  }

  displayFnInbox(inbox: Inbox | string): string {
    return (<Inbox>inbox)?.name;
  }

  submit() {
    this.processing = true;
    if (this.typeCtr.value === TypeTransfer.agent) {
      let api$: Observable<any>;
      if ([TxnChannel.supportCenter, TxnChannel.whatsapp, TxnChannel.livechat].includes(this.data?.channel)) {
        api$ = this.txnService.requestAssignTxn(this.data?.txnUuid, <RequestActionTxn>{
          agentUuid: (<AgentCustom>this.agentCtr.value).memberUuid
        });
      } else {
        const member = this.data.txn?.lastAssignedAgents?.[0];
        api$ = this.txnService
          .joinTxnV2(this.data?.txnUuid, (<AgentCustom>this.agentCtr.value).memberUuid)
          .pipe(switchMap(() => (member ? this.txnService.leftTxnV2(this.data.txnUuid, member) : of(null))));
      }

      api$
        .pipe(
          finalize(() => {
            this.processing = false;
          })
        )
        .subscribe(
          () => {
            this.ngZone.run(() => {
              this.dialogRef.close(true);
            });
          },
          err => this.toastService.error(err.message)
        );
    } else {
      this.txnService
        .moveInbox(this.data.txnUuid, [], (<Inbox>this.inboxCtr.value)?.uuid)
        .pipe(
          finalize(() => {
            this.processing = false;
          })
        )
        .subscribe(
          () => {
            this.toastService.success('Move inbox successfully!');
            this.ngZone.run(() => {
              this.dialogRef.close(true);
            });
          },
          err => this.toastService.error(err.message)
        );
    }
  }

  private getMembersByFeatureCode(req: GetDirectoryMembersByFeatureCodeReq) {
    return this.directoryMemberService.getMembersByFeatureCode(req).pipe(
      tap(member => {
        this.reqMembers = req;
        this.hasMore = member.content?.length === req.perPage;
      })
    );
  }

  private initForm() {
    this.typeOpts = [
      { key: TypeTransfer.agent, value: 'Agent' },
      { key: TypeTransfer.inbox, value: 'Inbox' }
    ].filter(
      opt => opt.key !== TypeTransfer.inbox || (opt.key === TypeTransfer.inbox && this.data.txn.allowActionDisplay)
    );

    this.isTransferTxn = this.data.txn?.lastAssignedAgents?.length > 0;
    this.handleAgentForm();
    this.inbox$ = this.inboxCtr.valueChanges.pipe(
      startWith(this.agentCtr.value || ''),
      switchMap(value => {
        return this.inboxesQuery.selectByChannelAndKeyword(
          value instanceof Inbox ? '' : value?.trim(),
          this.data.channel
        );
      })
    );
  }

  private handleAgentForm() {
    combineLatest([
      this.agentCtr.valueChanges.pipe(startWith(this.agentCtr.value || '')),
      this.typeCtr.valueChanges.pipe(
        startWith(this.typeCtr.value),
        tap(x => {
          if (x !== TypeTransfer.agent) {
            this.agentCtr.setValue('', { emitEvent: false });
          }
        }),
        filter(x => x === TypeTransfer.agent)
      )
    ])
      .pipe(
        debounceTime(300),
        switchMap(([value, _]) => {
          this.hasMore = false;
          const req = <GetDirectoryMembersByFeatureCodeReq>{
            keyword: typeof value === 'string' ? (<string>value)?.trim() : null,
            featureCode:
              this.data.txn.channel === TxnChannel.livechat
                ? LicenseFeatureCode.license_livechat
                : this.data.txn.channel === TxnChannel.whatsapp
                  ? LicenseFeatureCode.license_whatsapp
                  : LicenseFeatureCode.license_livechat,
            page: 1,
            perPage: DEFAULT_SIZE
          };
          return this.getMembersByFeatureCode(req);
        }),
        map(res =>
          res.content
            ?.map(
              x =>
                new AgentCustom({
                  ...(this.userQuery.getEntityStore(x.memberUuid) || {}),
                  ...x
                })
            )
            ?.filter(agent => !this.data?.txn?.lastAssignedAgents?.includes(agent.uuid))
        ),
        takeUntil(this.destroySubscriber$)
      )
      .subscribe(member => {
        this.members = member;
      });

    // listen scroll event to load more
    this.typeCtr.valueChanges
      .pipe(startWith(this.typeCtr.value), takeUntil(this.destroySubscriber$))
      .subscribe(value => {
        if (value === TypeTransfer.agent) {
          setTimeout(() => {
            this.matAutocomplete.opened
              .pipe(delay(300), takeUntil(this.typeCtr.valueChanges), takeUntil(this.destroySubscriber$))
              .subscribe(() => {
                const elr = this.matAutocomplete.panel.nativeElement;
                if (elr) {
                  fromEvent(elr, 'scroll')
                    .pipe(takeUntil(this.matAutocomplete.closed), takeUntil(this.destroySubscriber$), debounceTime(50))
                    .subscribe((value: any) => {
                      if (value?.target?.scrollTop + value?.target?.clientHeight === value?.target?.scrollHeight) {
                        if (!this._isLoadingMore && this.hasMore) {
                          this._isLoadingMore = true;

                          const req: GetDirectoryMembersByFeatureCodeReq = {
                            ...this.reqMembers,
                            page: this.reqMembers.page + 1
                          };
                          this.getMembersByFeatureCode(req)
                            .pipe(
                              map(res =>
                                res.content
                                  ?.map(
                                    x =>
                                      new AgentCustom({
                                        ...(this.userQuery.getEntityStore(x.memberUuid) || {}),
                                        ...x
                                      })
                                  )
                                  ?.filter(agent => !this.data?.txn?.lastAssignedAgents?.includes(agent.uuid))
                              ),
                              finalize(() => (this._isLoadingMore = false))
                            )
                            .subscribe(data => {
                              this.members = [...this.members, ...data];
                            });
                        }
                      }
                    });
                }
              });
          }, 300);
        }
      });
  }
}
