import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayText',
  standalone: true
})
export class DisplayTextPipe implements PipeTransform {
  transform(value: string, orgShortName?: string) {
    if (value && orgShortName) {
      return value.replace(/@.*$/, `@${orgShortName + ')'}`);
    }
    return value || '';
  }
}
