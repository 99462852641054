import { Injectable } from '@angular/core';
import { Team } from '@b3networks/api/auth';
import { Store, StoreConfig } from '@datorama/akita';
import { User } from './user.model';

export interface MeState {
  me: User;
  myTeam: Team[];
}

export function createInitialState(): MeState {
  return {
    me: null,
    myTeam: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workspace_me' })
export class MeStore extends Store<MeState> {
  constructor() {
    super(createInitialState());
  }
}
