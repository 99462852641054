import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { WhatsappNumbers } from './wa-numbers.model';

export interface WaNumbersState extends EntityState<WhatsappNumbers> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: `inbox-whatsapp-numbers`, idKey: 'phoneNumberId' })
export class WaNumbersStore extends EntityStore<WaNumbersState> {
  constructor() {
    super();
  }
}
