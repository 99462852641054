import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RelatedTxnsState, RelatedTxnsStore } from './related-txn.store';

@Injectable({ providedIn: 'root' })
export class RelatedTxnsQuery extends QueryEntity<RelatedTxnsState> {
  constructor(protected override store: RelatedTxnsStore) {
    super(store);
  }

  selectTxnsRelatedToMe(meIdentity: string) {
    return this.selectAll({
      filterBy: entity => entity?.mentions?.includes(meIdentity)
    });
  }
}
