import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2p',
  standalone: true
})
export class Nl2pPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value == null) {
      return null;
    }
    if (value.indexOf('- :feature:') !== -1) {
      return value
        .split('\n')
        .filter(feature => feature.indexOf('- :feature:') !== -1)
        .map(feature => '<p>' + feature.substr(12, feature.length) + '<p>')
        .join('\n');
    } else {
      return value
        .split('\n')
        .map(feature => '<p>' + feature + '<p>')
        .join('\n');
    }
  }
}
