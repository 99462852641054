import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Me, createMe } from './me.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_me' })
export class MeStore extends Store<Me> {
  constructor() {
    super(createMe({ whatsappAvailableCapacity: null }));
  }
}
