export class BuyerWallet {
  postpaid: boolean;
  currency: string;
  unbilled: number;
  billed: number;
  balance: number;
  reserved: number;
  creditLimit: number;
  liability?: number;
  usableCredit?: number;

  constructor(obj?: Partial<BuyerWallet>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export function createBuyerWallet(params: Partial<BuyerWallet>) {
  return {} as BuyerWallet;
}
