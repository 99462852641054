import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  AnnouncementResp,
  AnnouncementService,
  PostCreateAnnouncementReq,
  PutUpdateAnnouncementReq
} from '@b3networks/api/portal';
import { User, UserQuery } from '@b3networks/api/workspace';
import { ConfirmDialogComponent, ConfirmDialogInput } from '@b3networks/shared/ui/confirm-dialog';
import { ButtonLoadingDirective } from '@b3networks/shared/ui/material';
import { ThemeService } from '@b3networks/shared/ui/theme';
import { ToastService } from '@b3networks/shared/ui/toast';
import { defer, finalize } from 'rxjs';

export interface StoreAnnouncementInput {
  announcement?: AnnouncementResp;
}

type StoreAnnouncementForm = FormGroup<{
  title: FormControl<string>;
  content: FormControl<string>;
}>;

@Component({
  selector: 'b3n-store-announcement',
  templateUrl: './store-announcement.component.html',
  styleUrls: ['./store-announcement.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ButtonLoadingDirective
  ]
})
export class StoreAnnouncementComponent implements OnInit {
  form: StoreAnnouncementForm;
  user: User;
  selectedStatus: 'ACTIVE' | 'DISABLED' = 'ACTIVE';
  saving: boolean;
  isEditMode: boolean;

  readonly TITLE_MAX_LENGTH = 100;
  readonly CONTENT_MAX_LENGTH = 400;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StoreAnnouncementInput,
    private fb: FormBuilder,
    private announcementService: AnnouncementService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<StoreAnnouncementComponent>,
    private dialog: MatDialog,
    private userQuery: UserQuery,
    private themeService: ThemeService
  ) {
    this._initForm();
  }

  ngOnInit(): void {}

  storeAnnouncement() {
    const { title, content } = this.form.value;
    const req: PostCreateAnnouncementReq | PutUpdateAnnouncementReq = {
      title,
      content
    };

    this.saving = true;

    defer(() => {
      if (this.data.announcement) {
        req['status'] = 'ACTIVE';
        return this.announcementService.update(this.data.announcement.id, req as PutUpdateAnnouncementReq);
      } else {
        return this.announcementService.create(req);
      }
    })
      .pipe(
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe(
        _ => {
          this.toastService.success(`${this.data.announcement ? 'Edited' : 'Created'} announcement successfully`);
          this.dialogRef.close(true);
        },
        e => {
          this.toastService.error(e['message'] ?? `Something went wrong!`);
        }
      );
  }

  confirmArchive() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: <ConfirmDialogInput>{
          title: 'Archive Announcement',
          message: 'Are you sure to archive this announcement?',
          color: 'warn',
          confirmLabel: 'Archive'
        },
        panelClass: this.themeService.isDarkMode ? 'dark-theme-portal-base' : null
      })
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this._archive();
        }
      });
  }

  private _archive() {
    const { title, content, id } = this.data.announcement;
    const req = {
      title: title,
      content: content,
      status: 'DISABLED'
    } as PutUpdateAnnouncementReq;

    this.announcementService.update(id, req).subscribe(
      _ => {
        this.toastService.success('Archived announcement successfully');
        this.dialogRef.close(true);
      },
      e => {
        this.toastService.error(e['message'] ?? `Something went wrong!`);
      }
    );
  }

  private _initForm() {
    this.form = this.fb.group({
      title: ['', [Validators.required]],
      content: ['', [Validators.required]]
    });

    if (this.data.announcement) {
      const { title, content } = this.data.announcement;
      const uuid = this.data.announcement?.creator?.uuid;

      this.user = this.userQuery.getUserByUuid(uuid);
      this.form.patchValue({
        title,
        content
      });
    }
  }

  get titleFC() {
    return this.form.controls.title;
  }

  get contentFC() {
    return this.form.controls.content;
  }
}
