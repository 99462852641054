import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizeProvider, CreateRedirectLinkReq, VerifyStatusReq } from './sso-login.model';
import { LoginResponse } from '../../authentication/authentication.model';

@Injectable({
  providedIn: 'root'
})
export class SsoLoginService {
  constructor(private http: HttpClient) {}

  createRedirectLink(req: CreateRedirectLinkReq, authorizeProvider: AuthorizeProvider): Observable<string> {
    return this.http
      .post<string>(`/auth/private/v1/login/sso/${authorizeProvider}/url`, req)
      .pipe(map((res: any) => res.url || null));
  }

  verifyStatus(req: VerifyStatusReq, authorizeProvider: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`/auth/private/v1/login/sso/${authorizeProvider}`, req);
  }
}
