import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  RefreshRecoveryKeyResponse,
  SendOtpCodeResponse,
  TFAIntentType,
  TfaService,
  Verify2FaRequest,
  Verify2FaResponse,
  Verify2FaType
} from '@b3networks/api/auth';
import { MessageConstants } from '@b3networks/shared/common';
import { ToastService } from '@b3networks/shared/ui/toast';
import { finalize } from 'rxjs';
import { EnableTwofaV2Data } from '../enable-twofa-v2.component';

interface LoadingButtonOptions {
  text: string;
  loading: boolean;
}

@Component({
  selector: 'b3n-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  otpId = '';
  sanitizedCode = '';
  codeCtrl = new FormControl<string>({ value: '', disabled: true }, Validators.required);
  errorStr = '';
  loading = false;
  sendOtpButtonOptions = <LoadingButtonOptions>{
    text: 'Send verification code',
    loading: false
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnableTwofaV2Data,
    private dialogRef: MatDialogRef<VerifyEmailComponent>,
    private tfaService: TfaService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.codeCtrl.valueChanges.subscribe(() => {
      this.errorStr = '';
    });
  }

  sendOtpCodeToEmail() {
    this.sendOtpButtonOptions.loading = true;
    this.tfaService.sendOtpCodeToEmail({ intent: TFAIntentType.ENABLE_TFA }).subscribe({
      next: (res: SendOtpCodeResponse) => {
        this.otpId = res.otpId;
        this.sanitizedCode = res.sanitizedCode;
        this.codeCtrl.enable();
        this.sendOtpButtonOptions = { loading: false, text: 'Resend verification code' };
      },
      error: () => {
        this.sendOtpButtonOptions.loading = false;
        this.toastService.error(MessageConstants.GENERAL_ERROR);
      }
    });
  }

  continue() {
    if (this.sanitizedCode && this.codeCtrl.valid) {
      this.loading = true;
      this.tfaService
        .verify2Fa(<Verify2FaRequest>{
          otpId: this.otpId,
          otpCode: this.sanitizedCode + '-' + this.codeCtrl.value.trim(),
          type: Verify2FaType.system
        })
        .subscribe({
          next: (res: Verify2FaResponse) => {
            this.tfaService
              .toggle2Fa({ tfaSession: res.tfaSession })
              .pipe(finalize(() => (this.loading = false)))
              .subscribe({
                next: (response: RefreshRecoveryKeyResponse) => {
                  this.data.tfaInfo.recoveryKey = response.recoveryKey;
                  this.dialogRef.close(response.recoveryKey);
                },
                error: () => this.toastService.error(MessageConstants.GENERAL_ERROR)
              });
          },
          error: err => {
            this.loading = false;
            const data = err.error;
            if (data.code === 'auth.OtpCodeInvalid') {
              this.errorStr = 'Invalid code';
            } else if (data.code === 'auth.OtpExpired' || data.code === 'OtpFlowDurationExpiredException') {
              this.errorStr = 'The provided code has expired';
            } else {
              this.toastService.error(MessageConstants.GENERAL_ERROR);
            }
          }
        });
    }
  }
}
