import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abs',
  standalone: true
})
export class AbsPipe implements PipeTransform {
  transform(value: number) {
    // Math.abs might return NaN
    return value < 0 ? -value : value;
  }
}
