import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CreatedTxn } from './created-txn.model';

export interface CreatedTxnsState extends EntityState<CreatedTxn> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_created_txn', idKey: 'txnUuid' })
export class CreatedTxnsStore extends EntityStore<CreatedTxnsState> {
  constructor() {
    super();
  }
}
