import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { SystemMsgType } from '../../txn/txn.model';
import { CaseActivity } from './activities.model';
import { ActivitiesQuery } from './activities.query';
import { ActivitiesStore } from './activities.store';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  constructor(private http: HttpClient, private store: ActivitiesStore, private query: ActivitiesQuery) {}

  getActivities(txnUuid: string) {
    return this.http.get<CaseActivity[]>(`inbox/private/v1/txn/${txnUuid}/activities`).pipe(
      map(list =>
        list.map(
          l =>
            new CaseActivity({
              ...l,
              txnUuid
            })
        )
      ),
      tap(activities => {
        this.store.upsertMany(activities, { baseClass: CaseActivity });
      })
    );
  }

  getActivity(txnUuid: string, activity: { activityId: number; activityType: string; typeMsg: string }) {
    return this.http
      .get<CaseActivity>(
        `inbox/private/v1/txn/${txnUuid}/activities/${activity.activityId}?activityType=${activity.activityType}`
      )
      .pipe(
        map(
          caseActivity =>
            new CaseActivity({
              ...caseActivity,
              txnUuid
            })
        ),
        tap(caseActivity => {
          if (activity.typeMsg === SystemMsgType.createComment) this.store.add(caseActivity);
          if (activity.typeMsg === SystemMsgType.updateComment) this.store.upsert(caseActivity.id, caseActivity);
        })
      );
  }

  addActivityToStore(activity: CaseActivity) {
    this.store.upsert(activity.id, activity);
  }

  deleteActivity(activityId: number) {
    this.store.remove(activityId);
  }
}
