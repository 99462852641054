import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { License } from './license.model';

export interface LicenseState extends EntityState<License>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'license_license', idKey: 'id' })
export class LicenseStore extends EntityStore<LicenseState> {
  constructor() {
    super();
  }
}
