import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { FormDataTxn, ReqSubmitFormData } from './form-data.model';
import { FormDataQuery } from './form-data.query';
import { FormDataStore } from './form-data.store';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  constructor(private http: HttpClient, private store: FormDataStore, private query: FormDataQuery) {}

  getFormValues(txnUuid: string) {
    return this.http.get<FormDataTxn>(`/inbox/private/v1/form-integration/txn/${txnUuid}/form-data`).pipe(
      map(form => {
        const formConfig = this.query.getFormData(txnUuid);
        return new FormDataTxn({
          txnUuid,
          //submit form not write data immediately, should UI save data when cust input if call api 200
          displayData:
            form.formData?.data?.length > 0 ? form.formData?.data[0] : formConfig ? formConfig.displayData : null,
          ...form
        });
      }),
      tap(data => {
        this.store.upsert(data.txnUuid, data, { baseClass: FormDataTxn });
      })
    );
  }

  submitFormValues(txnUuid: string, req: ReqSubmitFormData) {
    return this.http.post(`/inbox/private/v1/form-integration/txn/${txnUuid}/form-data`, req).pipe(
      map(
        () =>
          new FormDataTxn({
            txnUuid,
            //submit form not write data immediately, should UI save data cust input if call api 200
            displayData: req.formValues
          })
      ),
      tap(data => this.store.upsert(data.txnUuid, data, { baseClass: FormDataTxn }))
    );
  }

  updateFormValues(txnUuid: string, formValues: Map<string, Object>) {
    return this.http.put(`/inbox/private/v1/form-integration/txn/${txnUuid}/form-data`, { formValues }).pipe(
      tap(() => {
        this.store.update(txnUuid, state => ({
          displayData: {
            ...state.displayData,
            ...formValues
          }
        }));
      })
    );
  }
}
