import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TeamChatState, TeamChatStore } from './teamchat.store';

@Injectable({ providedIn: 'root' })
export class TeamChatQuery extends QueryEntity<TeamChatState> {
  constructor(protected override store: TeamChatStore) {
    super(store);
  }
}
