import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isUuid',
  standalone: true,
})
export class IsUuidPipe implements PipeTransform {
  transform(text: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(text);
  }
}
