import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NoteTemplateV2 } from './form.model';

export interface FormState extends EntityState<NoteTemplateV2> {}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'data-form', idKey: 'templateUuid' })
export class FormStore extends EntityStore<FormState> {
  constructor() {
    super();
  }
}
