import { FieldType, UserDefinedField } from '@b3networks/api/data';
import { Dates, Holiday } from '@b3networks/api/leave';
import { addDays, endOfDay, format, getTime, parse, startOfDay, startOfWeek } from 'date-fns';
import { DisplayConfig, FieldSCConfig, MSDataForm } from '../form-integration/form-integration.model';
import { ShiftData, WeekDay } from '../inboxes/inboxes.model';

export class FormDataTxn {
  // templateCode: string;
  // templateUuid: string;
  // noteUuid: string;
  txnUuid: string;
  // columns: UserDefinedField[];
  // data: AgentFormValues[];
  formData: FormDataResp;
  fieldConfigList: FieldSCConfig[];
  formTemplate: MSDataForm;
  //
  displayData: Map<string, Object>;
  constructor(obj: Partial<FormDataTxn>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export interface ReqSubmitFormData {
  formValues: Map<string, Object>;
  srcChannelUuid: string;
  workingHourMode: WORKING_HOURS_MODE;
}

export interface FormDataReq {
  templateCode: string;
  templateUuid: string;
  values: AgentFormValues;
}

export interface AgentFormValues {
  [key: string]: any;
}

export class FormDataResp {
  columns?: UserDefinedField[];
  data?: AgentFormValues[];
}

export class FieldConfigCustom extends UserDefinedField {
  fieldKey: string;
  displayConfig?: DisplayConfig;
  isEditableByCustomer?: boolean;
  isVisibleToCustomer?: boolean;
  isEditableByAgent?: boolean;
  //UI
  displayFieldOptions: { id: string; value: string; color: string; default: boolean }[];
  displayFieldDescription: string;
  displayFieldLabel: string;
  //view detail txn
  data: any;
  displayData: any;

  constructor(obj?: Partial<FieldConfigCustom>) {
    super(obj);
    if (obj) {
      Object.assign(this, obj);
      if (obj?.type) {
        if (obj.type === FieldType.time) {
          this.type = this.display?.['dateOnly'] ? FieldType.dateOnly : FieldType.dateAndTime;
        } else if (obj.type === FieldType.options) {
          if ((this.minSelection === 1 && this.maxSelection === 1) || (!this.minSelection && !this.maxSelection)) {
            this.type = FieldType.singleChoice;
          } else {
            this.type = FieldType.multipleChoice;
            if (typeof this.data === 'string') {
              this.data = [this.data];
            }
          }
        }
      }

      this.displayData = this.data;

      if (obj?.options) {
        let mappingOptions = [];
        if (this.displayConfig?.displayOptions) {
          mappingOptions = Object.entries(this.displayConfig.displayOptions).map(([id, value]) => {
            return { id, value };
          });
        } else {
          mappingOptions = this.options.map(value => {
            return { id: value, value };
          });
        }
        const findDataOption = mappingOptions.find(option => option.id === this.data);
        if (findDataOption) {
          this.displayData = findDataOption.value;
        }
        const displayOptions = mappingOptions.map((option, index) => {
          if (obj?.display) {
            const findConfig = this.display[index];
            return { ...option, ...findConfig };
          } else {
            return { ...option };
          }
        });
        this.displayFieldOptions = displayOptions;
      }

      (this.displayFieldLabel = this.displayConfig?.displayName ?? this.label),
        (this.displayFieldDescription = this.displayConfig?.description ?? this.description);
    }
  }
}

export class WorkingHourConfigInbox {
  timezone?: string;
  shifts: ShiftData[];
  phCountryCode?: string;
  groupHolidayUuid?: string;
  //ui
  groupHoliday?: Dates[];
  publicHoliday?: Holiday[];
  whMode?: WORKING_HOURS_MODE;
  //convert timestamp
  timeRangeShift?: TimeRangeShift[];
  timeRangeGroupHoliday?: TimeRangeFormat[];
  timeRangePublicHoliday?: TimeRangeFormat[];

  constructor(obj: Partial<WorkingHourConfigInbox>) {
    if (obj) {
      Object.assign(this, obj);
      this.timeRangeShift = this.convertShifts(this.shifts);

      if (obj?.groupHoliday) {
        const dates = this.groupHoliday.map(holiday => holiday.date);
        if (dates.length > 0) {
          this.timeRangeGroupHoliday = this.convertDate(dates);
        }
      }

      if (obj?.publicHoliday) {
        const dates = this.publicHoliday.map(holiday => holiday.date);
        if (dates.length > 0) {
          this.timeRangePublicHoliday = this.convertDate(dates);
        }
      }

      const now = new Date().getTime();

      const isPublicHoliday = this.timeRangePublicHoliday
        ? this.timeRangePublicHoliday.some(time => time.from <= now && time.to >= now)
        : false;
      const isCustomHoliday = this.timeRangeGroupHoliday
        ? this.timeRangeGroupHoliday.some(time => time.from <= now && time.to >= now)
        : false;
      const isWorkingHour = this.timeRangeShift
        ? this.timeRangeShift.some(time => time.timeRanges.some(t => t.from <= now && t.to >= now))
        : true;

      if (isPublicHoliday || isCustomHoliday) {
        this.whMode = WORKING_HOURS_MODE.PUBLIC_HOLIDAY;
      } else if (isWorkingHour) {
        this.whMode = WORKING_HOURS_MODE.WORKING_HOURS;
      } else {
        this.whMode = WORKING_HOURS_MODE.NON_WORKING_HOURS;
      }
    }
  }

  private convertShifts(shifts: ShiftData[]) {
    const formatShift: TimeRangeShift[] = [];
    shifts.forEach(item => {
      const timeRanges: TimeRangeFormat[] = [];
      const dayOfWeek = [
        WeekDay.MONDAY,
        WeekDay.TUESDAY,
        WeekDay.WEDNESDAY,
        WeekDay.THURSDAY,
        WeekDay.FRIDAY,
        WeekDay.SATURDAY,
        WeekDay.SUNDAY
      ];
      const dof = dayOfWeek.findIndex(day => day === item.dayOfWeek); // monday = 0
      const targetDate = this.getDateFromDayOfWeek(dof); // 2024-05-23
      const timeZone = this.timezone.replace('GMT', '');
      if (item.timeRanges?.length > 0) {
        item.timeRanges.forEach(time => {
          //start time
          const startTimeString = time.from; // 00:01
          const dateStartTimeString = `${targetDate} ${startTimeString}${timeZone}`; // 2024-05-23 00:01+08:00
          const parsedDateStartTime = parse(dateStartTimeString, 'yyyy-MM-dd HH:mmXXX', new Date()); //Thu May 23...
          const timestampStart = parsedDateStartTime.getTime(); //timestamp

          //end time
          const endTimeString = time.to;
          const dateEndTimeString = `${targetDate} ${endTimeString}${timeZone}`;
          const parsedDateEndTime = parse(dateEndTimeString, 'yyyy-MM-dd HH:mmXXX', new Date());
          const timestampEnd = parsedDateEndTime.getTime();

          timeRanges.push(<TimeRangeFormat>{
            from: timestampStart,
            to: timestampEnd
          });
        });
      }

      formatShift.push(<TimeRangeShift>{
        dayOfWeek: targetDate,
        timeRanges
      });
    });

    return formatShift;
  }

  private getDateFromDayOfWeek(dayOfWeek: number): string {
    const today = new Date();
    const startOfWeekDate = startOfWeek(today, { weekStartsOn: 1 });
    const targetDate = addDays(startOfWeekDate, dayOfWeek);
    return format(targetDate, 'yyyy-MM-dd');
  }

  private convertDate(dates: string[]) {
    const formatRange: TimeRangeFormat[] = [];
    dates.forEach(item => {
      const date = new Date(item);
      const startDate = getTime(startOfDay(date)); //ts
      const endDate = getTime(endOfDay(date)); //ts
      formatRange.push(<TimeRangeFormat>{
        from: startDate,
        to: endDate
      });
    });
    return formatRange;
  }
}

interface TimeRangeShift {
  dayOfWeek: string; // sunday, monday, ..
  timeRanges: TimeRangeFormat[]; //from to
}

export enum WORKING_HOURS_MODE {
  WORKING_HOURS = 'working_hours',
  NON_WORKING_HOURS = 'non_working_hours',
  PUBLIC_HOLIDAY = 'public_holiday'
}

interface TimeRangeFormat {
  from: number;
  to: number;
}
