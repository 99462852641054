import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable, map } from 'rxjs';
import { IAMGrantedPermission } from './iam.model';
import { IamState, IamStore } from './iam.store';

@Injectable({ providedIn: 'root' })
export class IamQuery extends Query<IamState> {
  supportingPermissions$ = this.select('supportingPermissions');
  orgGrantedPolicy$ = this.select('orgGrantedPolicy');
  domainGrantedPolicy$ = this.select('domainGrantedPolicy');

  get supportingPermissions() {
    return this.getValue().supportingPermissions;
  }

  get orgGrantedPolicy() {
    return this.getValue().orgGrantedPolicy;
  }
  get domainGrantedPolicy() {
    return this.getValue().domainGrantedPolicy;
  }

  constructor(protected override store: IamStore) {
    super(store);
  }

  selectIAMActionsMappedPG(groupID: string) {
    return this.select('supportingActionMappingPG').pipe(map(map => (map != null ? map[groupID] : [])));
  }

  /// FOR GRANTED ORG POLICY
  selectOrgGrantedAction(service: string, action: string) {
    return this.orgGrantedPolicy$.pipe(
      map(p => p?.policies.filter(p => p.isAllowedAction(service, action))),
      map(list => {
        let result: IAMGrantedPermission;

        if (list?.length > 0) {
          let resources = list
            .map(m => m.resources)
            .reduce((prev, curr) => {
              prev.push(...curr);
              return prev;
            }, []);

          resources = [...new Set(resources)];
          if (resources.includes('*')) {
            resources = ['*'];
          }

          result = list[0];
          result.resources = resources;
        }

        return result;
      })
    );
  }

  selectOrgHasGrantedResource(service: string, action: string, resource: string): Observable<boolean> {
    return this.orgGrantedPolicy$.pipe(
      map(p => p?.policies.filter(p => p.isAllowResource(service, action, resource)).length > 0)
    );
  }

  getOrgGrantedAction(service: string, action: string) {
    return this.orgGrantedPolicy?.policies.find(p => p.isAllowedAction(service, action));
  }

  hasOrgGrantedAction(service: string, action: string): boolean {
    return this.getOrgGrantedAction(service, action) != null;
  }

  hasOrgGrantedResource(service: string, action: string, resource: string): boolean {
    return this.orgGrantedPolicy?.policies.find(p => p.isAllowResource(service, action, resource)) != null;
  }

  /// DOMAIN Policy
  selectHasDomainGrantedAction(service: string, action: string): Observable<boolean> {
    return this.domainGrantedPolicy$.pipe(map(p => p?.policies.some(i => i.isAllowedAction(service, action))));
  }

  selectHasDomainGrantedResource(service: string, action: string, resource: string): Observable<boolean> {
    return this.domainGrantedPolicy$.pipe(
      map(p => p?.policies.some(i => i.isAllowResource(service, action, resource)))
    );
  }

  hasDomainGrantedAction(service: string, action: string): boolean {
    return this.domainGrantedPolicy?.policies.some(p => p.isAllowedAction(service, action));
  }

  hasDomainGrantedResource(service: string, action: string, resource: string): boolean {
    return this.domainGrantedPolicy?.policies.some(p => p.isAllowResource(service, action, resource));
  }
}
