import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UUID_V4_REGEX } from './cache-media.model';
import { CachedMediaState, CacheMediaStore } from './cache-media.store';

export const EXPIRED_CACHE_MEDIA = 60 * 1000;

@Injectable({ providedIn: 'root' })
export class CacheMediaQuery extends QueryEntity<CachedMediaState> {
  constructor(protected override store: CacheMediaStore) {
    super(store);
  }

  getMediaByKey(key: string, isCheckIgnoreOrgUuid: boolean) {
    let s3KeyWithouOrgUuid = key;
    if (isCheckIgnoreOrgUuid) {
      const rs: RegExpMatchArray = s3KeyWithouOrgUuid.match(UUID_V4_REGEX);
      if (rs && rs.index === 0) {
        s3KeyWithouOrgUuid = s3KeyWithouOrgUuid.slice(37);
      }
    }

    const media = this.getEntity(s3KeyWithouOrgUuid);
    if (!media) {
      return null;
    }

    if (!!media?.time && media.time <= Date.now() - EXPIRED_CACHE_MEDIA) {
      if (media && media?.url && media?.url?.includes('blob:')) {
        URL.revokeObjectURL(media.url);
      }
      this.store.remove(s3KeyWithouOrgUuid);
      return null;
    }

    return media;
  }
}
