import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { WhatsappTemplate } from './wa-template.model';

export interface WaTemplateState extends EntityState<WhatsappTemplate> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: `inbox-whatsapp-template`, idKey: 'id' })
export class WaTemplateStore extends EntityStore<WaTemplateState> {
  constructor() {
    super();
  }
}
