import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ChatMessage } from '../../chat-message/chat-message.model';

export interface CacheMessageState extends EntityState<ChatMessage>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workspace_cache_message', idKey: 'clientId' })
export class CacheMessageStore extends EntityStore<CacheMessageState> {
  constructor() {
    super({});
  }
}
