export enum DeviceType {
  IP_PHONE = 'IP_PHONE',
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
  SIP_GATEWAY = 'SIP_GATEWAY',
  WEBRTC = 'WEBRTC',
  MSTEAM = 'MSTEAM',
  MSISDN = 'MSISDN',
  DELEGATED = 'DELEGATED',
  HOT_DESKING = 'HOT_DESKING'
}
