<h1 *ngIf="data.title" mat-dialog-title>{{ data.title || 'Confirm modal' | acronymsCapitalizeCase }}</h1>
<div mat-dialog-content>
  <div class="pb-2" [innerHtml]="data.message"></div>
  <mat-form-field class="w-full" [style.display]="!data.textConfirm ? 'none' : null">
    <input matInput placeholder="" type="text" ngModel #textValue="ngModel" />
  </mat-form-field>
  <mat-form-field class="w-full" *ngIf="data.hasTextarea">
    <mat-label *ngIf="data?.textAreaLabel">{{ data.textAreaLabel }}</mat-label>
    <textarea
      matInput
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="3"
      [cdkAutosizeMaxRows]="5"
      maxlength="250"
      [(ngModel)]="inputValue"
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button *ngIf="!data.hideCancel" mat-stroked-button [mat-dialog-close]="false">
    {{ data.cancelLabel || 'Cancel' }}
  </button>
  <button
    *ngIf="!data.hideConfirm"
    mat-flat-button
    [color]="data.color || 'primary'"
    [disabled]="(data.textConfirm && textValue.value !== data.textConfirm) || (data.hasTextarea && !inputValue)"
    (click)="confirm()"
  >
    {{ data.confirmLabel || 'Confirm' }}
  </button>
</div>
