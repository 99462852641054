import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SessionQuery } from '@b3networks/portal/base/shared';
import { DestroySubscriberComponent, UUID_V4_REGEX } from '@b3networks/shared/common';
import { takeUntil, tap } from 'rxjs/operators';
import { SwitchOrganizationData, SwitchOrganizationDialog } from '../shared/modal/switch-org/switch-org.component';

@Component({
  selector: 'b3n-navigate-org',
  templateUrl: './navigate-org.component.html',
  styleUrls: ['./navigate-org.component.scss']
})
export class NavigateOrgComponent extends DestroySubscriberComponent implements OnInit {
  constructor(private sessionQuery: SessionQuery, private router: Router, private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.sessionQuery.currentOrg$
      .pipe(
        takeUntil(this.destroySubscriber$),
        tap(currentOrg => {
          if (currentOrg) {
            const url = this.router.url;
            const rs: RegExpMatchArray = url.match(UUID_V4_REGEX);
            const params = url.split('/').filter(char => char !== '');

            if (!rs && params.length) {
              this.router.navigate([currentOrg.orgUuid, ...params]);
            } else {
              this.router.navigate([currentOrg.orgUuid, 'home']);
            }
          } else if (
            this.sessionQuery.profile?.organizations?.length > 1 ||
            this.sessionQuery.getValue().servicedOrgs?.length > 1
          ) {
            this.dialog.open(SwitchOrganizationDialog, {
              width: '500px',
              disableClose: true,
              data: <SwitchOrganizationData>{
                disabledClose: true,
                activeApp: location.hash?.substring('#/'.length)
              }
            });
          }
        })
      )
      .subscribe();
  }
}
