export class BalanceForBuyer {
  changes: number;
  closing: number;
  currency: string;
  date: string;

  constructor(obj?: Partial<BalanceForBuyer>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class BalanceForBuyerDetail {
  Amount: number;
  Description: string;
  Type: 'billed' | 'unbilled';

  constructor(obj?: Partial<BalanceForBuyerDetail>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
