import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { format, parse } from 'date-fns';
import { Observable } from 'rxjs';
import { InputTimepickerComponent } from '../input-timepicker/input-timepicker.component';

const WORKING_TIMES = {
  startDay: parse('00:00', 'HH:mm', new Date()),
  endDay: parse('23:59', 'HH:mm', new Date())
};

enum WeekDay {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday'
}

interface Schedule {
  timezone?: string;
  shifts: ShiftData[];
}

interface ShiftData {
  dayOfWeek: WeekDay;
  timeRanges: TimeRange[];
}

interface TimeRange {
  from: string;
  to: string;
}

@Component({
  selector: 'shc-working-hour',
  templateUrl: './working-hour.component.html',
  styleUrls: ['./working-hour.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTimepickerComponent,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatIconModule
  ]
})
export class WorkingHourComponent implements OnInit {
  @Input() workingSchedule: UntypedFormGroup = this.fb.group({
    shifts: this.fb.array([])
  });
  @Input() schedule: Schedule;
  @Input() isDisable: boolean;
  @Output() isDisableChange: EventEmitter<boolean> = new EventEmitter();
  @Output() finished = new EventEmitter<Schedule>();
  @Output() isInValidate: EventEmitter<boolean> = new EventEmitter();

  schedule$: Observable<Schedule>;
  workflow: Schedule;

  loadingView = true;
  progressing: boolean;
  limitShiftTimes = 3;

  readonly weekDays: WeekDay[] = [
    WeekDay.MONDAY,
    WeekDay.TUESDAY,
    WeekDay.WEDNESDAY,
    WeekDay.THURSDAY,
    WeekDay.FRIDAY,
    WeekDay.SATURDAY,
    WeekDay.SUNDAY
  ];

  get shiftsField() {
    return this.workingSchedule.get('shifts') as UntypedFormArray;
  }

  get validateInput() {
    return this.workingSchedule.valid;
  }

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    if (this.schedule) {
      this.workflow = this.schedule;
      this._initForm(this.workflow);
    } else this._initForm(this.workflow);
  }

  shiftChanged(cShift: UntypedFormGroup) {
    const shiftData = this.workflow?.shifts?.find(shift => shift.dayOfWeek === cShift.controls['dayOfWeek'].value);

    this._initTimeRange(
      cShift.controls['enabled'].value,
      shiftData ? shiftData.timeRanges : [],
      cShift.controls['timeRanges'] as UntypedFormArray
    );

    this.isInValidate.emit(this.shiftsField.invalid);
    this.isDisableChange.emit(false);
  }

  addMoreRange(timeRanges: UntypedFormArray) {
    timeRanges.push(
      this.fb.group({
        from: [
          {
            value: null,
            disabled: false
          }
        ],
        to: [
          {
            value: null,
            disabled: false
          }
        ]
      })
    );
    this.isDisableChange.emit(false);
  }

  removeTimeInRange(shiftControls: UntypedFormArray, timeIndex: number) {
    shiftControls['timeRanges']?.removeAt(timeIndex);

    const timeRanges = shiftControls['timeRanges'].controls.map(control => control.value);
    const isOverlap: boolean = this._checkOverlap(timeRanges);
    const isErrorRangeTime: boolean = this._checkErrorRangeTime(timeRanges);
    shiftControls['timeRanges'].setErrors(
      isOverlap || isErrorRangeTime ? { errorOverlap: isOverlap, errorRangeTime: isErrorRangeTime } : null
    );

    this.isInValidate.emit(this.shiftsField.invalid);
  }

  format24Hours(timeRanges: UntypedFormArray) {
    timeRanges.clear();
    timeRanges.push(
      this.fb.group({
        from: [
          {
            value: WORKING_TIMES.startDay,
            disabled: false
          }
        ],
        to: [
          {
            value: WORKING_TIMES.endDay,
            disabled: false
          }
        ]
      })
    );
    this.isDisableChange.emit(false);
  }

  applyWorkTimeToAll(shift: UntypedFormGroup) {
    for (const shiftItem of this.shiftsField.controls) {
      if (shiftItem.value.enabled) {
        const timeRanges = shiftItem.get('timeRanges') as UntypedFormArray;
        const timeRangesShift = shift.controls['timeRanges'] as UntypedFormArray;
        for (let index = 0; index < timeRangesShift.length; index++) {
          const syncData = (shift.controls['timeRanges'] as UntypedFormArray).controls[index];
          if (!!syncData.get('from').value && !!syncData.get('to').value) {
            const range = timeRanges.controls[index] as UntypedFormGroup;
            if (range) {
              range.get('from').setValue(syncData.get('from').value);
              range.get('to').setValue(syncData.get('to').value);
            } else {
              timeRanges.push(
                this.fb.group({
                  from: [
                    {
                      value: syncData.get('from').value,
                      disabled: false
                    }
                  ],
                  to: [
                    {
                      value: syncData.get('to').value,
                      disabled: false
                    }
                  ]
                })
              );
            }
          }
        }

        if (timeRanges.length > timeRangesShift.length) {
          while (timeRanges.length > timeRangesShift.length) timeRanges.removeAt(timeRangesShift.length);
        }
      }
    }
    this.isDisableChange.emit(false);
  }

  onChangeTime(indexShift: number, indexTimeRange: number, timeValue: Date, formControlName: string): void {
    const shiftItem = this.shiftsField.controls[indexShift];
    const timeRange = shiftItem['controls'].timeRanges.controls[indexTimeRange];
    timeRange.controls[formControlName].setValue(timeValue);

    const timeRanges = shiftItem['controls'].timeRanges.controls.map(control => control.value);

    const isOverlap: boolean = this._checkOverlap(timeRanges);
    const isErrorRangeTime: boolean = this._checkErrorRangeTime(timeRanges);
    shiftItem['controls'].timeRanges.setErrors(
      isOverlap || isErrorRangeTime ? { errorOverlap: isOverlap, errorRangeTime: isErrorRangeTime } : null
    );

    this.isInValidate.emit(this.shiftsField.invalid);
    this.isDisableChange.emit(false);
  }

  finish() {
    this.progressing = true;

    let data = Object.assign({}, this.workflow);
    data = Object.assign(data, this._getWorkingFormValue());
    data.shifts = data.shifts.map((shift: ShiftData) => ({
      dayOfWeek: shift.dayOfWeek,
      timeRanges: shift.timeRanges
        .filter(x => !!x.from && !!x.to)
        .map(
          (time: TimeRange) =>
            <TimeRange>{ from: format(new Date(time.from), 'HH:mm'), to: format(new Date(time.to), 'HH:mm') }
        )
    }));
    data.timezone = this.schedule?.timezone;
    this.workflow = data;
    this._initForm(data);
    this.finished.emit(data);
  }

  private _getWorkingFormValue() {
    const shiftsData: ShiftData[] = [];

    const shifts = this.workingSchedule.controls['shifts'] as UntypedFormArray;
    for (const cShift of shifts.controls) {
      const shiftGroup = cShift as UntypedFormGroup;
      const shiftData = { dayOfWeek: '', timeRanges: [] };
      shiftData.dayOfWeek = shiftGroup.controls['dayOfWeek'].value;
      if (shiftGroup.controls['enabled'].value) {
        const timeRanges = shiftGroup.controls['timeRanges'] as UntypedFormArray;
        for (const timeRange of timeRanges.controls) {
          const timeGroup = timeRange as UntypedFormGroup;
          const fromValue = timeGroup.controls['from'].value;
          const toValue = timeGroup.controls['to'].value;
          if (fromValue && toValue) shiftData.timeRanges.push({ from: fromValue, to: toValue });
        }
      }
      shiftsData.push(shiftData as ShiftData);
    }
    const data = this.workingSchedule.value;
    data.shifts = shiftsData;
    return data;
  }

  private _initForm(schedule: Schedule) {
    this.workingSchedule = this.fb.group({
      shifts: this.fb.array([])
    });

    this.weekDays.forEach(weekDay => {
      const shiftData = schedule?.shifts?.find(shift => shift.dayOfWeek === weekDay);

      const enabled = !schedule || (shiftData && shiftData.timeRanges.length > 0);
      const shiftForm = this.fb.group({
        dayOfWeek: [weekDay],
        timeRanges: this._initTimeRange(enabled, shiftData ? shiftData.timeRanges : []),
        enabled: [enabled]
      });
      (this.workingSchedule.get('shifts') as UntypedFormArray).push(shiftForm);
    });
  }

  private _initTimeRange(enabled: boolean, timeRanges: TimeRange[] = [], fields: UntypedFormArray = this.fb.array([])) {
    fields.controls = [];

    timeRanges.forEach(range => {
      fields.push(
        this.fb.group({
          from: [
            {
              value: range.from ? parse(range.from, 'HH:mm', new Date()) : null,
              disabled: !enabled
            }
          ],
          to: [
            {
              value: range.to ? parse(range.to, 'HH:mm', new Date()) : null,
              disabled: !enabled
            }
          ]
        })
      );
    });
    if (timeRanges.length === 0) {
      fields.push(
        this.fb.group({
          from: [
            {
              value: WORKING_TIMES.startDay,
              disabled: !enabled
            }
          ],
          to: [
            {
              value: WORKING_TIMES.endDay,
              disabled: !enabled
            }
          ]
        })
      );
    }

    return fields;
  }

  private _checkOverlap = (timeRanges: TimeRange[]) => {
    const listFormat = timeRanges.map(time => ({
      from: time.from ? new Date(time.from).getTime() : null,
      to: time.to ? new Date(time.to).getTime() : null
    }));
    listFormat.sort((firstTime, secondTime) => firstTime.from - secondTime.from);
    return !!listFormat.filter((time, i) => {
      if (!listFormat[i + 1]?.from) return false;

      const currentEndTime = time.to;
      const nextStartTime = listFormat[i + 1].from;
      return currentEndTime >= nextStartTime;
    }).length;
  };

  private _checkErrorRangeTime = (timeRanges: TimeRange[]) => {
    return !!timeRanges.filter(time => {
      if (!time.from || !time.to) return false;
      const fromTime: number = new Date(time.from).getTime();
      const toTime: number = new Date(time.to).getTime();
      return fromTime > toTime;
    }).length;
  };
}
