import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MessageLabelState, MessageLabelStore } from './message-label.store';

@Injectable({ providedIn: 'root' })
export class MessageLabelQuery extends QueryEntity<MessageLabelState> {
  constructor(protected override store: MessageLabelStore) {
    super(store);
  }

  selectMessageLabelByTxn(txnUuid: string) {
    return this.selectEntity(txnUuid);
  }

  getMessageLabelByTxn(txnUuid: string) {
    return this.getEntity(txnUuid);
  }
}
