export class ChatSession {
  chatUser: string; // user
  chatUserUuid: string; // customer
  id: string;
  ns: string;
  token: string;
  addr: string;

  constructor(obj?: Partial<ChatSession>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  get chatNode() {
    return this.addr.replace(/\..*$/, '');
  }
}

export enum ChatTopic {
  TEAM = 'TEAM',
  SUPPORT = 'SUPPORT',

  // callcenter
  COMMS = 'COMMS',

  // omnichannel
  OMNI = 'OMNI',
  SUPPORT_CENTER = 'SUPPORT_CENTER',
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL',
  LIVECHAT = 'LIVECHAT'
}

export enum SocketStatus {
  none = 'none',
  connecting = 'connecting',
  opened = 'opened',
  closed = 'closed'
}
