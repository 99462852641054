import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RelatesTo } from './cases.model';

export interface CasesState extends EntityState<RelatesTo> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_cases', idKey: 'key' })
export class CasesStore extends EntityStore<CasesState> {
  constructor() {
    super();
  }
}
