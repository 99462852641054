import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const PREFIX_OPEN_API = 'OPEN_API';

export const DISTRIBUTION_DOMAINS = [
  'portal.b3networks.com',
  'onboard.b3networks.com',
  'portal-uat.b3networks.com',
  'chaining.hoiio.net'
];

export const fileImage = 'bmp|jpg|jpeg|gif|png|webp|svg';
export const fileAudio = 'mp3|wav|ogg';
export const fileVideo = 'ogv|mp4|mov|webm';
export const fileWord = 'doc|docx|odt';
export const fileExcel = 'xls|xlsx|ods';
export const filePowerPoint = 'ppt|pptx|odp';
export const fileZip = 'zip|tar|gz|rar|7z';
export const fileAsImage = 'tiff';
export const filePdf = 'pdf';
export const fileText = 'txt|rtf|json|xml';
export const fileCode = 'html|css|js';

export const APP_IDS = {
  WHATAPPS: 'nWyRmvDLj3J9wGqi',
  UNIFIED_WORKSPACE: '1pxjX3FQumSFHzjC',
  DASHBOARD: 'drX4Id5eTSKpJUzC',
  DASHBOARD2: 'hwkKUS1kSRg4L3mH',
  FLOW: 'a4Az8QVOuS75ahkQ',
  BA_CREATOR: 'qlFzGAl5Cu3sjXpj',
  WORKSPACE: 'Pl06ZBdRuO3xbeMh',
  WALLBOARD: 'Y4v35i2TXBM0XL2l',
  VIRTUAL_LINE: '4ESLmjmXaWH0jcxT',
  CALL_TRACKING: 'DuCLEUPORmhjHjKX',
  PHONE_SETTING: '4dY1X5H0pF0jqbTy',
  SSO_IDP_TEST: '5hXSB78JSubOssiL',
  GLOBAL_DNC: 'LzgWeisit8OewfM1',

  // b3
  LEAVE: 'p21kL1Z0rc7LGR72',
  INTERNAL_CP: 'jvNKrghg7JGOXWdK',
  TEST_HUB: 'wxkMG8FAFy59wZ8s',

  // license apps
  COMMUNICATION_HUB: 'VM8od3o3pAQVbQIN',
  DEVELOPER_HUB: 'YHXoOJSx6vlJVvly',
  SSO_IDP: 'portal-module-sso-idp',
  SIPTRUNK: '8tEJ89MMuOVyiST0',
  AUTO_ATTENDANT: 'M0BHU7ZkN0ekybve',
  PHONE_SYSTEM: 'FS1b4bUeh8vct25g',
  BYOC_TRUNK: '11lehOezO2YAEjoH',
  BYOP_TRUNK: 'drOyMb3In8JzVc5R',
  FILE_EXPLORER: 'BiLrL9G6clY8TNCV',
  CONTACT: 'IX5LZU0Okzn0QMlr',
  FAX: 'Rq21shQgxqAVna7Q',
  HOT_DESKING: 'pOvYsaqbj4qkkfW7',
  ONBOARD: 'JOq0qjqbW1Y8Vo8R',
  POWER_SIP_TRUNK: 'HsuBIfjrdBmE6a7u',

  // partner
  BUSINESS_HUB: '56wTHokRhrrScx0s',
  SUPPORT_HUB: 'e6Png9HUfR8R5MSb',
  SALES_HUB: 'uSCGHY4HyLd6BGPC',
  TELCO_HUB: 'eMzwknwD1TuwV7CD',

  // portal module
  ORG_MANAGEMENTS: 'org-managements',
  SUPPORT_CENTER: 'rBccWkMjbEHrZGD2',
  CUSTOMER_REPORT: 'AdoRlc6AaEZnxFGD',
  RELEASE_NOTE: 'portal-module-release-note',
  STORE: 'YVNMmZ9yuj7QhO9b',
  CONNECTION_MANAGEMENT: 'BGp2FAH4WrRmaDFh',

  // embed widget
  NUMBER_MASKING: 'NUMBER_MASKING'
};

export const ISDN_PRODUCT = {
  id: 'p3d0r3gcO6ROYzxr',
  CR_Enterprise: 'n97g8J3b9MGI61qp',
  DPO_Enterprise: 'LzgWeisit8OewfM1',
  mobileDncCode: 'mobile_dnc',
  mobileCrCode: 'mobile_call_recording',

  mobileEnterpriseDnc: 'mobile_dpo',
  mobileEnterpriseCr: 'mobile_cr_compliance'
};

export const CHAT_PUBLIC_PREFIX = 'chat_public';

export const DEFAULT_ORG_LOGO = 'https://ui.b3networks.com/external/logo/default_org_icon.png';
export const DEFAULT_ORG_ICON = 'https://ui.b3networks.com/external/logo/default_org_icon.png';

export const X_PAGINATION = {
  totalCount: 'x-pagination-total-count',
  cursorResponse: 'x-cursoring-response'
};

export const USER_INFO = {
  orgUuid: 'orgUuid',
  sessionToken: 'sessionToken'
};

export const X_B3_HEADER = {
  orgUuid: 'x-user-org-uuid',
  sessionToken: 'x-credential-session-token',
  totalCount: 'x-pagination-total-count',
  widgetToken: 'x-wp-chat-widget-token',
  token: 'Token',
  domain: 'x-user-domain',
  userUuid: 'x-user-identity-uuid',
  clientAppId: 'x-client-app-id',
  baReleaseGroupId: 'x-ba-release-group-id',
  validateXSRFToken: 'Validate-XSRF-Token'
};

export const INJECT_PORTAL_DOMAIN = 'injectPortalDomain';
export const INJECT_CLIENT_ID = 'injectClientID';
export const INJECT_PRODUCT_BUILD = 'injectProductBuild';

export const REPLACE_BY_SUBDOMAINS = [
  'cG9ydGFsLmhvaWlvLmNvbQ==',
  'dm4uaG9paW8uY29t',
  'c2cuaG9paW8uY29t',
  'bXkuaG9paW8uY29t',
  'aGsuaG9paW8uY29t',
  'dXMuaG9paW8uY29t',
  'YXUuaG9paW8uY29t'
]; // pt, vn, sg, my, hk, us, au

export const SUBDOMAIN = 'LmhvaWlvLmNvbQ==';

export const TESTING_UAT_DOMAINS = [
  'cG9ydGFsLXVhdC5ob2lpby5uZXQ=',
  'cG9ydGFsLXVhdC5iM25ldHdvcmtzLm5ldA==',
  'cG9ydGFsLXVhdC5iM25ldHdvcmtzLmNvbQ=='
];
export const TESTING_DOMAIN = 'cG9ydGFsLmhvaWlvLm5ldA==';

export const DEFAULT_API_PROXY = 'aHR0cHM6Ly9hcGkuYjNuZXR3b3Jrcy5jb20=';

export class MessageConstants {
  public static GENERAL_ERROR =
    'Sorry, an error has occurred when we try to fulfill your request. Please try again in a few minutes.';
}

export const EMAIL_REGEX = /^([a-zA-Z0-9_.%+\-])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export const EMAIL_REGEX_V2 =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^(\+?\d{8,})$/;

export const NUMBER_PRODUCT_ID = 'number.hoiio';

export const UUID_V4_REGEX = '[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}';

export const UUID_REGEX = '[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}';

export const TXN_UUID_REGEX = '[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{12}-[a-f0-9]{2}';

export const B3_ORG_UUID = 'fc312420-0047-49a7-94a8-003f11f115c0';

export const CHAINING_ORG_UUID = 'c76c2bcb-4fa0-4a42-b06a-f840b7aac348';

export const B3_UAT_ORG_UUID = '8cd84d49-a5e2-49ba-993d-a8a5257ac55d';

export const HOIIO_INTERNAL_TESTING_ORG_UUID = '1a39064c-763c-4e31-ae30-4d87d901591c';

export const SYSTEM_IDENTITY = '8f5fbd1c-0e00-11eb-adc1-0242ac120002';

export const SESSION_NOTFOUND_CODE = 'auth.sessionNotFound';

export const ERROR_PERMISSION = {
  message: "You don't have permission to perform this action",
  code: 'unauthenticated'
};

export const UNEXPECTED_ERROR = {
  message: 'An unexpected error occurred.',
  code: 'unexpected_error'
};

// Jira: B3-12800 - Update Error Message for Capacity Limit
export const CAPACITY_LIMIT_ERROR = {
  message: 'Unable to assign conversation due to exceeding the defined capacities.',
  code: 'inbox.reachedCapacityLimit'
};

export const PORTAL_BASE_HANDLE_WS = 'PORTAL_BASE_HANDLE_WS';
export const DASHBOARD_V2_PREFIX = '/dashboard/private/v2';
export const DASHBOARD_V2_LOGGED_OUT = 'DASHBOARD_V2_LOGGED_OUT';
export const DEVICE_ACCESS_TOKEN = 'DEVICE_ACCESS_TOKEN';

export const STUN_SERVER = 'stun.b3networks.com';

export const FILE_EXPLORER = 'file_explorer';
export const AUTO_ATTENDANT = 'auto_attendant';
export const PER_PAGE_TXN = 50;

export const ipRegex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const DOMAIN_ALLOW_TELCO_FLOW = ['portal.hoiio.net', 'portal.telcoflow.com', 'localhost'];

export function getFileType(fileType: string): string {
  if (fileType) {
    fileType = fileType.toLowerCase();

    if (fileImage.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/image.png';
    }

    if (fileAsImage.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/image.png';
    }

    if (fileVideo.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/video.png';
    }

    if (fileAudio.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/audio.png';
    }

    if (fileWord.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/word.png';
    }

    if (fileExcel.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/excel.png';
    }

    if (filePowerPoint.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/power_point.png';
    }

    if (fileZip.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/archive.png';
    }

    if (filePdf.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/pdf.png';
    }

    if (fileText.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/text.png';
    }

    if (fileCode.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/code.png';
    }
    if (fileImage.indexOf(fileType) >= 0) {
      return 'assets/icons/attachment/image.png';
    }
  }

  return 'assets/icons/attachment/attach_file.png';
}

export const UNKNOWN_USER = 'Unknown user';
export const APPROVAL_BOT_NAME = 'Approval Bot';

export const isValidUuid4 = (input: string): boolean => {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return pattern.test(input);
};

export enum AccountTypeOrg {
  root = 'root',
  demoUser = 'demo_user',
  homeUser = 'home_user',
  cPaaSTelco = 'cpaas_telco',
  externalApp = 'external_app',
  businessUser = 'business_user',
  serviceProvider = 'service_provider',
  professionalUser = 'professional_user'
}

export const CURRENT_URL_WITHOUT_ORGIGIN = location.href.split(location.host)[1];

export const noWhitespaceValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { whitespace: true };
  };
};
