import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Product } from './product';

export interface ProductState extends EntityState<Product>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'store_product', idKey: 'productId' })
export class ProductStore extends EntityStore<ProductState> {
  constructor() {
    super();
  }
}
