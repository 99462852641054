import { IdentityStatus, MemberRole, MemberStatus, UPPER_ADMIN_ROLES } from '@b3networks/api/auth';
import { ExtensionBase } from '@b3networks/api/bizphone';
import { AgentStatus } from '@b3networks/api/callcenter';
import { LicenseFeatureCode } from '@b3networks/api/license';

export class DirectoryMember {
  memberUuid: string;
  username: string | null;
  email: string;
  mobileNumber: string;
  title: string | null;
  name: string;
  orgUuid: string;
  role: MemberRole;
  teamRole: MemberRole;
  identityStatus: IdentityStatus;
  memberStatus: MemberStatus;
  photoUrl: string | null;

  extensionKey: string | null;
  extensionLabel: string | null;

  constructor(obj?: Partial<DirectoryMember>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  get directoryText() {
    return this.extensionLabel + (this.extensionKey ? ` (#${this.extensionKey})` : '');
  }

  get isSuspended() {
    return this.identityStatus === IdentityStatus.suspended;
  }

  get isUpperAdmin(): boolean {
    return UPPER_ADMIN_ROLES.includes(this.role);
  }

  get isOwner(): boolean {
    return this.role === MemberRole.OWNER || this.role === MemberRole.SUPER_ADMIN;
  }

  get isDisabled() {
    return this.memberStatus === MemberStatus.disabled;
  }

  get displayStatus(): IdentityStatus | MemberStatus {
    if (this.isSuspended) {
      return IdentityStatus.suspended;
    }
    return this.memberStatus;
  }
}

export class DirectoryAgent {
  memberUuid: string;
  displayName: string;
  agentStatus: AgentStatus;

  // ui
  photoUrlOrDefault?: string;

  constructor(obj?: Partial<DirectoryAgent>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export interface GetDirectoryMembersReq {
  keyword?: string;
  filterExtension?: boolean;
  team?: string;
  status?: MemberStatus[];
  sort?: 'asc' | 'desc';
}

export interface GetDirectoryMembersByFeatureCodeReq {
  featureCode: LicenseFeatureCode;
  keyword: string;
  page: number;
  perPage: number;
}

export interface ExtensionContactReq {
  keyword: string;
  orgUuid: string;
  limit: number;
  cursor?: string;
}

export interface ExtensionContactResponse {
  content: ExtensionBase[];
  cursor: string;
}
