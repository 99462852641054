import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'credit',
  standalone: true
})
export class CreditPipe extends DecimalPipe implements PipeTransform {
  override transform(value: number | string, digitsInfo?: string, locale?: string): any {
    return super.transform(value, '1.0-6', locale);
  }
}
