import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RealDomain } from '@b3networks/api/auth';
import { RealDomainStore } from './real-domain.store';

@Injectable({ providedIn: 'root' })
export class RealDomainQuery extends Query<RealDomain> {
  realDomain$ = this.select();

  constructor(protected override store: RealDomainStore) {
    super(store);
  }
}
