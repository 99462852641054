import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { GetBulkTemplateResp } from './bulk-template.model';
export interface BulkTemplateState extends EntityState<GetBulkTemplateResp> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bulk_template', idKey: 'templateId' })
export class BulkTemplateStore extends EntityStore<BulkTemplateState, GetBulkTemplateResp> {
  constructor() {
    super();
  }
}
