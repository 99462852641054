import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { of } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, switchMap } from 'rxjs/operators';
import { IdentityProfileStore, ProfileState } from './identity-profile.store';

@Injectable({ providedIn: 'root' })
export class IdentityProfileQuery extends Query<ProfileState> {
  uuid$ = this.select(s => s.profile?.uuid).pipe(filter(uuid => !!uuid));
  profile$ = this.select('profile');

  currentOrg$ = this.select('currentOrg');
  roleCurrentOrg$ = this.select(entity => entity?.currentOrg?.role);
  licenseEnabled$ = this.select('currentOrg').pipe(map(x => x?.licenseEnabled));

  teams$ = this.select('teams');

  grantedPermissions$ = this.select('assignedPermissions');
  grantedPermissionGroups$ = this.select('assignedPermissionGroups');

  get identityUuid() {
    return this.getProfile().uuid;
  }

  get currentOrg() {
    return this.getValue().currentOrg;
  }

  get grantedPermissions() {
    return this.getValue().assignedPermissions;
  }

  get grantedPermissionGroups() {
    return this.getValue().assignedPermissionGroups;
  }

  constructor(protected override store: IdentityProfileStore) {
    super(store);
  }

  selectProfileOrg(orgUuid: string) {
    return this.profile$.pipe(
      filter(x => x != null),
      map(profile => profile.organizations.find(org => org.orgUuid === orgUuid))
    );
  }

  selectHasGrantedPermissionGroup(groupUUID: string) {
    return this.grantedPermissionGroups$.pipe(map(list => list?.findIndex(pg => pg.uuid === groupUUID) > -1));
  }

  selectHasGrantedPermissionAction(service, action: string) {
    return this.grantedPermissions$.pipe(
      map(list => list?.findIndex(p => p.service === service && p.action === action) > -1)
    );
  }

  selectHasGrantedPermissionActions(service: string, actions: string[]) {
    return this.grantedPermissions$.pipe(
      map(list => list?.findIndex(p => p.service === service && actions.includes(p.action)) > -1)
    );
  }

  selectHasGrantedPermissionResource(service, action, resource: string) {
    return this.grantedPermissions$.pipe(
      map(list => list?.findIndex(p => p.isAllowResource(service, action, resource)) > -1)
    );
  }

  /**
   * Only admin and has assigned PG by owner will have this Permission.
   * When Admin assigned PG. Then they can manage Permission of others members and can manage all the app
   * @param groupID
   * @returns
   */
  selectHasManagePermission(groupID: string) {
    return this.currentOrg$
      .pipe(
        filter(org => org != null),
        distinctUntilKeyChanged('role')
      )
      .pipe(switchMap(org => (org.isUpperAdmin ? this.selectHasGrantedPermissionGroup(groupID) : of(false))));
  }

  /**
   * Only admin has PG can perform all actions for the app
   * For Member role, MUST to check IAM Action granted for this user.
   * @param groupID
   * @param service
   * @param action
   * @returns
   */

  selectHasPermissionGroupOrPermissionAction(groupID, service, action: string) {
    return this.currentOrg$
      .pipe(
        filter(org => org != null),
        distinctUntilKeyChanged('role')
      )
      .pipe(
        switchMap(org =>
          org.isUpperAdmin
            ? this.selectHasGrantedPermissionGroup(groupID)
            : this.selectHasGrantedPermissionAction(service, action)
        )
      );
  }

  /**
   * Only admin has PG can perform all actions for the app
   * For Member role, MUST to check IAM Action granted for this user.
   * @param groupID
   * @param service
   * @param action
   * @param resource
   * @returns
   */

  selectHasPermissionGroupOrPermissionResource(groupID, service, action, resource: string) {
    return this.currentOrg$
      .pipe(
        filter(org => org != null),
        distinctUntilKeyChanged('role')
      )
      .pipe(
        switchMap(org =>
          // only license org has Permission Group concept
          // and admin of license org should check with PG instead of granted permission
          org.isUpperAdmin && org.licenseEnabled
            ? this.selectHasGrantedPermissionGroup(groupID)
            : this.selectHasGrantedPermissionResource(service, action, resource)
        )
      );
  }

  getProfile() {
    return this.getValue().profile;
  }

  getProfileOrg(orgUuid: string) {
    return this.getProfile().getOrganizationByUuid(orgUuid);
  }

  hasGrantedPermissionAction(service: string, action: string) {
    return this.grantedPermissions.findIndex(p => p.isAllowedAction(service, action)) > -1;
  }

  hasGrantedPermissionService(service: string) {
    return this.grantedPermissions.findIndex(p => p.isService(service)) > -1;
  }

  hasGrantedPermissionGroup(groupUUID: string) {
    return this.grantedPermissionGroups?.findIndex(pg => pg.uuid === groupUUID) > -1;
  }
}
