import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NoteTemplate } from './note.model';

export interface NoteState extends EntityState<NoteTemplate> {}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'data-note', idKey: 'templateUuid' })
export class NoteStore extends EntityStore<NoteState> {
  constructor() {
    super();
  }
}
