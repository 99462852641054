import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RealDomain, RealDomainService } from '@b3networks/api/auth';
import { Bundle } from '@b3networks/api/license';
import { Sku } from '@b3networks/api/store';
import { X } from '@b3networks/shared/common';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ResponseSaleModels, SaleModelResponse, SellerPrice } from './sale-model.model';

@Injectable({
  providedIn: 'root'
})
export class SaleModelService {
  constructor(private http: HttpClient, private domainService: RealDomainService) {}

  getSaleModelDetail(domain: string, productId: string, currencyCode?: string): Observable<SaleModelResponse> {
    return this.http.get<SaleModelResponse>(
      `/sale-model/private/v1/products/${productId}/domainpricelist?page=0&size=1000&domain=${domain}${
        currencyCode ? `&currency=${currencyCode}` : ''
      }`
    );
  }

  getSellerPrices(
    productId: string,
    sku: string,
    currency: string,
    includeSaleModelDetail?: boolean,
    sellerUuid?: string
  ): Observable<SellerPrice[]> {
    let params = new HttpParams();
    if (includeSaleModelDetail) {
      params = params.set('includeSaleModelDetail', 'true');
    } else {
      params = params.set('includeSaleModelDetail', 'false');
    }
    if (!sellerUuid) {
      sellerUuid = X.orgUuid;
    }

    return this.http
      .get<any[]>(`/sale-model/private/v3/sellers/${sellerUuid}/${currency}/products/${productId}/skus/${sku}/prices`, {
        params
      })
      .pipe(map(res => res.map(r => new SellerPrice(r))));
  }

  fetchPricing(productId: string, currency: string): Observable<Sku[]> {
    return this.domainService.getRealDomainFromPortalDomain().pipe(
      mergeMap((realDomain: RealDomain) => {
        const url = `/sale-model/private/v1/products/${productId}/domainpricelist?domain=${realDomain.domain}&currency=${currency}&page=0&size=1000`;
        return this.http.get(url);
      }),
      map((res: any) => res.content),
      map(skuList => {
        const list: Sku[] = [];
        skuList.forEach(element => {
          const sku = Sku.buildFromSalesModelResponse(element);
          if (sku) {
            list.push(sku);
          }
        });
        return list;
      }),
      catchError(_ => of([]))
    );
  }

  fetchB3Pricing(productId: string, currency: string): Observable<Sku[]> {
    return this.http
      .get(
        `/sale-model/private/v1/products/${productId}/domainpricelist?domain=portal.b3networks.com&currency=${currency}&page=0&size=1000`
      )
      .pipe(
        map((res: any) => res.content),
        map(skuList => {
          const list: Sku[] = [];
          skuList.forEach(element => {
            const sku = Sku.buildFromSalesModelResponse(element);
            if (sku) {
              list.push(sku);
            }
          });
          return list;
        }),
        catchError(_ => of([]))
      );
  }

  getSaleModels(orderItem: Bundle[]): Observable<ResponseSaleModels[]> {
    const body = {
      items: orderItem
    };

    return this.http.post<ResponseSaleModels[]>(`store/private/v3/partner/products/_getSaleModels`, body);
  }
}
