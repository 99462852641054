import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { X, X_B3_HEADER } from '@b3networks/shared/common';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SellerWallet } from './seller-wallet.model';
import { SellerWalletStore } from './seller-wallet.store';

/**
 * Seller wallet is using for node
 * Customer will get list of seller and then fetch all wallets with this sellers
 */
@Injectable({ providedIn: 'root' })
export class SellerWalletService {
  constructor(private sellerWalletStore: SellerWalletStore, private http: HttpClient) {}

  /**
   * Get list sellers of buyer
   */
  getSellers(headers?: HttpHeaders): Observable<string[]> {
    return this.http.get<string[]>(`billing/private/v6/sellerWallets`, { headers: headers }).pipe(
      tap(list => {
        this.sellerWalletStore.update({ sellerUuids: list });
      })
    );
  }

  getWallets(sellerUuid: string, headers?: HttpHeaders) {
    // tweak for bizhub can select wallet from key (seller & buyer)
    let buyerUuid = X.orgUuid;
    if (headers && headers.has(X_B3_HEADER.orgUuid)) {
      buyerUuid = headers.get(X_B3_HEADER.orgUuid);
    }
    return this.http.get<SellerWallet[]>(`billing/private/v6/sellerWallets/${sellerUuid}`, { headers: headers }).pipe(
      map(list => list.map(wallet => new SellerWallet({ ...wallet, sellerUuid, buyerUuid }))),
      tap(entities => {
        this.sellerWalletStore.set(entities);
      })
    );
  }
}
