import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable, map, tap } from 'rxjs';
import { ContactUI } from './contact-ui.model';
import { Contact, ContactsFilterState, MergeCustomerReq, SaveManageContactReq } from './contact.model';
import { ContactQuery } from './contact.query';
import { ContactStore } from './contact.store';
// https://b3networks.atlassian.net/browse/B3-3620

@Injectable({ providedIn: 'root' })
export class ContactService {
  constructor(private store: ContactStore, private http: HttpClient, private query: ContactQuery) {}

  updateFilter(filter: Partial<ContactsFilterState>) {
    this.store.updateUiState(filter);
  }

  setActive(id: string) {
    if (id) {
      this.store.setActive(id);
    }
  }

  removeActive(id: ID) {
    if (id) {
      this.store.removeActive(id);
    }
  }

  updateContacts2Store(contacts: Contact[]) {
    this.store.upsertMany(contacts, { baseClass: Contact });
  }

  updateUIViewState(convoId: string | string[] | ID, state: Partial<ContactUI>) {
    this.store.ui.update(convoId, state);
  }

  queryCustomer(req: { [key: string]: string }): Observable<Contact[]> {
    let params = new HttpParams();
    Object.keys(req).forEach(key => {
      if (req[key]) params = params.set(key, req[key]);
    });
    return this.http
      .get<Contact[]>(`inbox/private/v2/customers`, { params })
      .pipe(map(contacts => contacts.map(contact => new Contact(contact))));
  }

  mergeCustomer(customerUuid: string, req: MergeCustomerReq): Observable<Contact> {
    return this.http.put<Contact>(`inbox/private/v3/customers/${customerUuid}/merge`, req).pipe(
      map(contact => new Contact(contact)),
      tap(contact => {
        this.store.upsert(customerUuid, contact);
      })
    );
  }

  saveManageContact(customerUuid: string, req: SaveManageContactReq): Observable<Contact> {
    return this.http.put<Contact>(`inbox/private/v3/customers/${customerUuid}`, req).pipe(
      map(contact => new Contact(contact)),
      tap(contact => {
        this.store.upsert(customerUuid, contact);
      })
    );
  }
}
