import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { GetAllPinRequest, PinMessage, PinMessageRequest } from './pin-message.model';
import { PinMessageStore } from './pin-message.store';

@Injectable({
  providedIn: 'root'
})
export class PinMessageService {
  constructor(private http: HttpClient, private store: PinMessageStore) {}

  pinMessage(req: PinMessageRequest) {
    return this.http.post<{ pin: PinMessage }>(`/public/v2/_tc/namespace/channel/pinMessage`, req).pipe(
      map(x => x?.pin),
      map(
        x =>
          new PinMessage({
            ...x,
            convoId: req.convoId
          })
      ),
      tap(pin => this.store.upsert(pin.messageId, pin))
    );
  }

  unpinMessage(req: PinMessageRequest) {
    return this.http.post<any>(`/public/v2/_tc/namespace/channel/unpinMessage`, req).pipe(
      tap(() => {
        this.store.remove(req.messageId);
      })
    );
  }

  getAllPinByChannelId(req: GetAllPinRequest) {
    return this.http.post<{ pins: PinMessage[] }>(`/public/v2/_tc/namespace/channel/getPinMessages`, req).pipe(
      map(x => x?.pins),
      map(
        res =>
          res?.map(
            x =>
              new PinMessage({
                ...x,
                convoId: req.convoId
              })
          ) || []
      ),
      tap(list => {
        if (list?.length > 0) {
          this.store.upsertMany(list, { baseClass: PinMessage });
        }
      })
    );
  }

  addPin2Store(pin: PinMessage) {
    this.store.upsertMany([pin], { baseClass: PinMessage });
  }

  removePin2Store(messageId: string) {
    this.store.remove(messageId);
  }
}
