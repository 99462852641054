import { IAMGrantedPermission } from './iam.model';

export class PolicyDocument {
  orgUuid: string; // added from client for storing
  version: string;
  createAt: string;
  updatedAt: string;
  policies: IAMGrantedPermission[] = [];

  constructor(obj?: Partial<PolicyDocument>) {
    if (obj) {
      Object.assign(this, obj);
      this.policies = this.policies?.map(p => new IAMGrantedPermission(p));
    } else {
      this.policies = [];
    }
  }

  withOrgUuid(orgUuid: string) {
    this.orgUuid = orgUuid;
    return this;
  }

  hasGrantedActionPermission(service, action: string) {
    return this.policies.some(p => p.isAllowedAction(service, action));
  }

  flattenResourcesFromAction(service, action: string) {
    return [
      ...new Set(
        this.policies
          .filter(p => p.isAllowedAction(service, action))
          .reduce((pre, curr) => [...pre, ...curr.resources], [])
      )
    ];
  }

  replacePolicyAction(service, action: string, resources: string[]) {
    const index = this.policies.findIndex(p => p.service === service && p.action === action);

    if (resources.length && index > -1) {
      this.policies[index].resources = resources;
    } else if (resources.length && index === -1) {
      this.policies.push(new IAMGrantedPermission({ service: service, action: action, resources: resources }));
    } else if (!resources.length && index > -1) {
      this.policies.splice(index, 1);
    }
  }

  addPolicyAction(service, action: string, resources: string[] = ['*']) {
    const index = this.policies.findIndex(p => p.service === service && p.action === action);
    if (index === -1) {
      this.policies.push(new IAMGrantedPermission({ service: service, action: action, resources: resources }));
    } else {
      this.policies[index].resources = resources;
    }
  }

  removePolicyAction(service, action: string) {
    const index = this.policies.findIndex(p => p.service === service && p.action === action);
    if (index > -1) {
      this.policies.splice(index, 1);
    }
  }

  cleanupPolicies() {
    const policyResources = this.policies.reduce((prev, curr) => {
      if (!curr.action || !curr.service || !curr.resources.length) {
        // ignore invalid data
        return prev;
      }
      const key = `${curr.service}_${curr.action}`;
      prev[key] = prev[key] || curr;

      prev[key].resources = [...new Set([...prev[key].resources, ...curr.resources])];

      if (prev[key].resources.includes('*')) {
        prev[key].resources = ['*'];
      }
      return prev;
    }, {});

    this.policies = Object.values(policyResources);
    return this;
  }

  hideRenewalCostPrice(): boolean {
    return this.policies.some(x => x.service === 'ui' && x.action === 'HideRenewalCostPrice');
  }

  showPaymentOptions(): boolean {
    const resources = (this.policies.find(x => x.service === 'ui' && x.action === 'ShowPaymentOption') || {}).resources;
    if (!resources) {
      return true;
    }

    return (
      resources.includes('*') ||
      (resources.includes('normal') && resources.includes('contract') && resources.includes('invoice'))
    );
  }

  showRefundOptions(): boolean {
    const resources = (this.policies.find(x => x.service === 'ui' && x.action === 'ShowTerminationOption') || {})
      .resources;
    if (!resources) {
      return true;
    }

    return resources.includes('*') || (resources.includes('normal') && resources.includes('credit_note'));
  }
}

export function createOrganizationPolicy() {
  return {} as PolicyDocument;
}
