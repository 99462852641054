import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { DomainUtilsService, X_B3_HEADER } from '@b3networks/shared/common';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PasswordPolicy, SecurityPolicy } from '../security/security';
import { RealDomainStore } from './real-domain.store';

export interface RealDomain {
  domain: string; // right domain
  portalDomain: string;
  publicKey: string | null;
  ssoIdPs?: string[];
  passwordPolicy?: PasswordPolicy;
  securityPolicy?: SecurityPolicy;
}

export interface LoginOptionAndPolicy {
  loginOptions: string[];
  rememberMeEnabled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RealDomainService implements OnDestroy {
  domain$ = new Subject<string>();
  destroy$ = new Subject<boolean>();

  domain: string;

  private _realDomain: RealDomain; // some partner changed domain from auth module. And need mapping to right domain when call ms-partner

  constructor(
    private http: HttpClient,
    private domainUtilService: DomainUtilsService,
    private store: RealDomainStore
  ) {
    this.domain$
      .pipe(
        takeUntil(this.destroy$),
        tap(domain => {
          this.domain = domain;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * This api does not need session to validation
   */
  getRealDomainFromPortalDomain() {
    const headers = new HttpHeaders().set(X_B3_HEADER.orgUuid, '').set(X_B3_HEADER.sessionToken, '');
    const domain = this.domain ?? this.domainUtilService.getPortalDomain();

    const request$ = this.http.get<RealDomain>(`/auth/private/v1/domains?domain=${domain}`, { headers: headers }).pipe(
      tap(realDomain => {
        this._realDomain = realDomain;
        this.store.update(realDomain);
      })
    );

    if (this.domain) {
      return request$;
    }

    if (this._realDomain) {
      return of(this._realDomain);
    } else {
      return request$;
    }
  }

  getRealDomainFromPortalDomainV2(domain: string) {
    return this.http.get<any>(`/auth/private/v2/domains?domain=${domain}`);
  }

  getRealDomain(domain: string) {
    const params = new HttpParams().set('domain', domain);
    return this.http.get<any>(`/auth/private/v1/domains/portal`, { params });
  }

  getOptionSignIn(credential: string): Observable<string[]> {
    const headers = new HttpHeaders().set(X_B3_HEADER.orgUuid, '').set(X_B3_HEADER.sessionToken, '');
    const domain = this.domain ?? this.domainUtilService.getPortalDomain();

    return this.http.post<string[]>(
      '/auth/private/v3/login/options',
      {
        domain: domain,
        credential: credential
      },
      { headers: headers }
    );
  }

  getLoginOptionsAndPolicy(credential: string): Observable<LoginOptionAndPolicy> {
    const headers = new HttpHeaders().set(X_B3_HEADER.orgUuid, '').set(X_B3_HEADER.sessionToken, '');
    const domain = this.domain ?? this.domainUtilService.getPortalDomain();

    return this.http.post<LoginOptionAndPolicy>(
      '/auth/private/v3/login/policy',
      {
        domain: domain,
        credential: credential
      },
      { headers: headers }
    );
  }
}
