import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Watchers } from './watchers.model';
import { WatchersQuery } from './watchers.query';
import { WatchersStore } from './watchers.store';

@Injectable({
  providedIn: 'root'
})
export class WatchersService {
  constructor(private http: HttpClient, private store: WatchersStore, private query: WatchersQuery) {}

  getWatchersV2(txnUuid: string) {
    return this.http.get<string[]>(`inbox/private/v2/txn/watchers/${txnUuid}`).pipe(
      tap(result => {
        this.store.upsert(txnUuid, { watchers: result });
      })
    );
  }

  watchCaseV2(txnUuid: string) {
    return this.http.post<string[]>(`inbox/private/v2/txn/watchers/${txnUuid}`, null).pipe(
      tap(watchersUuid => {
        this.store.upsert(txnUuid, {
          watchers: watchersUuid
        });
      })
    );
  }

  unwatchCaseV2(txnUuid: string) {
    return this.http.delete<string[]>(`inbox/private/v2/txn/watchers/${txnUuid}`).pipe(
      tap(watchersUuid => {
        this.store.upsert(txnUuid, {
          watchers: watchersUuid
        });
      })
    );
  }

  updateWatchers2Store(watchers: Watchers[]) {
    this.store.upsertMany(watchers);
  }
}
