import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OutboundRuleState, OutboundRuleStore } from './outbound-rule.store';

@Injectable({ providedIn: 'root' })
export class OutboundRuleQuery extends QueryEntity<OutboundRuleState> {
  constructor(protected override store: OutboundRuleStore) {
    super(store);
  }
}
