import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FormFieldInboxConfig } from './form-integration-inbox.model';

export interface FormIntegrationInboxState extends EntityState<FormFieldInboxConfig> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'form-integration-inbox', idKey: 'templateUuid' })
export class FormIntegrationInboxStore extends EntityStore<FormIntegrationInboxState> {
  constructor() {
    super();
  }
}
