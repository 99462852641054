import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Inbox } from '../inboxes/inboxes.model';
import { ConnectedOrgInfo } from './connected-org.model';

export interface ConnectedOrgsState extends EntityState<ConnectedOrgInfo> {
  listInboxes: Inbox[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_connect_org', idKey: 'inboxUuid' })
export class ConnectedOrgsStore extends EntityStore<ConnectedOrgsState> {
  constructor() {
    super();
  }
}
