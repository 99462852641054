export enum PolicyName {
  lowercaseRequired = 'lowercaseRequired',
  nonAlphanumericRequired = 'nonAlphanumericRequired',
  numberRequired = 'numberRequired',
  uppercaseRequired = 'uppercaseRequired',
  letterRequired = 'letterRequired',
  maximumLength = 'maximumLength',
  minimumLength = 'minimumLength'
}

export interface PatternConfig {
  pattern: RegExp;
  name: PolicyName;
}

export interface Policy {
  name: PolicyName;
  regex: RegExp;
  displayText: string;
}
