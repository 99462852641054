import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { WaTemplateState, WaTemplateStore } from './wa-template.store';

@Injectable({ providedIn: 'root' })
export class WaTemplateQuery extends QueryEntity<WaTemplateState> {
  constructor(protected override store: WaTemplateStore) {
    super(store);
  }

  selectAllTemplate() {
    return this.selectAll({
      sortBy: 'name',
      sortByOrder: Order.ASC
    });
  }
}
