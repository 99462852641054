import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { OutboundRule } from './outbound-rule.model';
export interface OutboundRuleState extends EntityState<OutboundRule> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'outbound-rule', idKey: 'id' })
export class OutboundRuleStore extends EntityStore<OutboundRuleState> {
  constructor() {
    super();
  }
}
