export interface Media {
  fileKey: string;
  fileSize: string;
  mimeType: string;
  fileName?: string;
  caption?: string;
  width?: number; // for image
  height?: number; // for image
}

export interface RequestSendMsgWhatsapp {
  txnUuid: string;
  wabaPhoneNumberId: string;
  waId: string;
  messageType: TypeMsgWhatsapp;
  clientTs: number;

  replyTo?: {
    messageId: string;
  };

  // send text msg
  text?: {
    body: string;
  };

  //  send media msg
  media?: Media;

  // send template msg
  template?: {
    name: string; // '{{template}}'
    language: string; // 'en_US'
    components: TemplateComponent[];
  };
}

export enum TypeMsgWhatsapp {
  text = 'text',
  template = 'template',
  audio = 'audio',
  image = 'image',
  sticker = 'sticker',
  video = 'video',
  document = 'document'
}

export interface TemplateComponent {
  type: string;
  index?: number; // for button
  parameters: TemplateComponentParameter[];
}

export interface TemplateComponentParameter {
  type: 'text' | 'media';
  text?: string;
  media?: Media;
}

export interface ReqCreateTxnWhatsapp {
  wabaPhoneNumberId: string;
  waId: string;
  selfAssign: boolean;
  inboxUuid: string;
}

export interface ReqCreateChannelWhatsapp {
  phoneNumberId: string;
  name: string;
}
