import { Channel } from '@b3networks/api/inbox';
import { Inbox } from '../../inboxes/inboxes.model';

export class WhatsappNumbers {
  phoneNumberId: string; // ID
  displayPhoneNumber: string;
  wabaId: string;
  orgUuid: string;
  inbox: Partial<Inbox>;
  status?: string;
  errMessage?: string;
  channel?: Channel;

  constructor(obj: Partial<WhatsappNumbers>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export interface VerifiedWABA {
  endpoint: string;
  verifyToken: string;
  wabaId: string;
}
export interface VerifiedWABAReq {
  wabaId: string;
  accessToken: string;
  verifyToken: string;
}

export interface AssignNumberToInboxReq {
  phoneNumberId: string;
  inboxUuid: string;
}
