import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Me } from './me.model';
import { MeStore } from './me.store';

@Injectable({ providedIn: 'root' })
export class MeQuery extends Query<Me> {
  isLimitCreateWa$ = this.select(state => state.whatsappAvailableCapacity).pipe(
    map(whatsappAvailableCapacity => whatsappAvailableCapacity === 0)
  );

  constructor(protected override store: MeStore) {
    super(store);
  }
}
