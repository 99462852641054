import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CreatedTxnsState, CreatedTxnsStore } from './created-txn.store';

@Injectable({ providedIn: 'root' })
export class CreatedTxnsQuery extends QueryEntity<CreatedTxnsState> {
  constructor(protected override store: CreatedTxnsStore) {
    super(store);
  }

  selectTxnsCreatedByMe(meIdentity: string) {
    return this.selectAll({
      filterBy: entity => entity?.createdBy === meIdentity
    });
  }
}
