import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TxnChannel } from '../txn/txn.model';
import { OwnerType } from './canned-responses.model';
import { CannedResponsesState, CannedResponsesStore } from './canned-responses.store';

@Injectable({ providedIn: 'root' })
export class CannedResponsesQuery extends QueryEntity<CannedResponsesState> {
  all$ = this.selectAll();

  constructor(protected override store: CannedResponsesStore) {
    super(store);
  }

  selectCannedResponeseByChannel(channel: TxnChannel) {
    return this.selectAll({
      filterBy: entity => entity.channel?.toLowerCase() === channel?.toLowerCase()
    });
  }

  getCannedResponeseByChannel(channel: TxnChannel) {
    return this.getAll({
      filterBy: entity => entity.channel?.toLowerCase() === channel?.toLowerCase()
    });
  }

  selectCannedResponeseByTypeAndChannel(ownerType: OwnerType, channel?: TxnChannel) {
    return this.selectAll({
      filterBy: entity =>
        entity.ownerType === ownerType && (channel.trim().length > 0 ? entity.channel === channel : true)
    });
  }
}
