import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { captureException } from '@sentry/angular-ivy';
import { B3Sentry } from '../utils/sentry/b3-sentry';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (B3Sentry.enabledSentryLog) {
      if (error instanceof HttpErrorResponse) {
      } else if (error?.status && error?.code) {
      } else {
        captureException(error);
        throw error;
      }
    } else {
      throw error;
    }
  }
}
