import { ChatMessage } from './chat-message.model';
import { AclActionMessageUI } from './chat-messsage-ui.model';

export class ExtraData {
  linkedMessages: LinkedMessages;

  constructor(obj?: Partial<ExtraData>) {
    if (obj) {
      Object.assign(this, obj);
      if (obj?.linkedMessages) {
        this.linkedMessages = new LinkedMessages(obj.linkedMessages);
      }
    }
  }
}

export class LinkedMessages {
  snapshots: ChatMessage[];
  srcId: string; // messsageId bookmarked
  replyTo: string; // messageId reply

  constructor(obj?: Partial<LinkedMessages>) {
    if (obj) {
      Object.assign(this, obj);
      this.snapshots =
        obj?.snapshots?.map(
          x =>
            new ChatMessage({
              ...x,
              aclActions: <AclActionMessageUI>{
                isReplyMessage: this.replyTo === x.id,
                isBookmarkMessage: this.srcId === x.id
              }
            })
        ) || [];
    }
  }

  get messageBookmark() {
    return this.snapshots?.find(x => x.id === this.srcId);
  }

  get indexMessageBookmark() {
    return this.snapshots?.findIndex(x => x.id === this.srcId);
  }

  get messageReply() {
    return this.snapshots?.find(x => x.id === this.replyTo);
  }

  get indexMessageReply() {
    return this.snapshots?.findIndex(x => x.id === this.replyTo);
  }

  addMsgToSnapshots(msg: ChatMessage) {
    if (msg) {
      const index = this.snapshots.findIndex(x => x.id === msg.id);
      if (index === -1) {
        this.snapshots.push(msg);
      }
    }
    return this;
  }
}
