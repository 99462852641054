import { Policy, PolicyName } from './password-policy.model';

export const REGEX_LEAST_A_UPPERCASE = /(?=.*?[A-Z])/;
export const REGEX_LEAST_A_LOWERCASE = /(?=.*?[a-z])/;
export const REGEX_LEAST_A_NUMBER = /(?=.*?[0-9])/;
export const REGEX_LEAST_A_LETTER = /\w*[a-zA-Z]\w*/;
export const REGEX_LEAST_A_SPECIAL_CHAR = /\W|_/;

export const PASSWORD_POLICIES: Policy[] = [
  {
    name: PolicyName.lowercaseRequired,
    regex: REGEX_LEAST_A_LOWERCASE,
    displayText: '- At least 1 lowercase letter (a-z)'
  },
  {
    name: PolicyName.uppercaseRequired,
    regex: REGEX_LEAST_A_UPPERCASE,
    displayText: '- At least 1 uppercase letter (A-Z)'
  },
  { name: PolicyName.numberRequired, regex: REGEX_LEAST_A_NUMBER, displayText: '- At least 1 number' },
  {
    name: PolicyName.nonAlphanumericRequired,
    regex: REGEX_LEAST_A_SPECIAL_CHAR,
    displayText: '- At least 1 special character'
  }
];

export const DEFAULT_PASSWORD_POLICIES: Policy[] = [
  { name: PolicyName.numberRequired, regex: REGEX_LEAST_A_NUMBER, displayText: '- At least 1 number' },
  { name: PolicyName.letterRequired, regex: REGEX_LEAST_A_LETTER, displayText: '- At least 1 letter (A-Z, a-z)' }
];
