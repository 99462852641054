import { Pipe, PipeTransform } from '@angular/core';

enum CauseNumber {
  consent = 'consent',
  coverage = 'coverage',
  bypass = 'bypass',
  licence = 'licence',
  credit = 'credit',
  dnc = 'dnc'
}

@Pipe({
  name: 'dncStatus',
  standalone: true
})
export class DNCStatusPipe implements PipeTransform {
  transform(cause: CauseNumber): any {
    switch (cause) {
      case CauseNumber.dnc:
        return 'DNC';
      default:
        return cause;
    }
  }
}
