import { DistributingSku } from '@b3networks/api/store';

export class PriceModel {
  currency: string;
  amount: number;
  isBlocked: boolean;
}

export class SaleModel {
  code: string; // billing cycle
  basePrice: PriceModel[]; // using for topup admin fee when they don't publish domain price
  domainPrice: PriceModel[];
}

export class SkuModel {
  skuCode: string;
  saleModel: SaleModel[];
}

export class SaleModelResponse {
  content: SkuModel[];
}

export class SaleModelSkuResponse {
  skuCode: string;
  saleModel: SaleModel[];
}

export class SaleModelDetail {
  code: string;
  subscription: SaleModelSubscription;
  type: string;
}

export class SaleModelResponseV3 {
  saleModel: string;
  sku: string;
}

export class SaleModelSubscription {
  cycle: number;
  unit: string;
}

export class SalesModel {
  code: string;
  amount: number;
  currency: string;
  isBlocked: boolean;
  isB3Blocked: boolean;
  description: string;
  saleModel?: string;
  type?: string;

  static buildFromResponse(params: any): SalesModel {
    if (!params['domainPrice'] || params['domainPrice'].length === 0) {
      return null;
    }

    const pricing = params['domainPrice'][0];

    return new SalesModel({
      code: params['code'],
      amount: +pricing['amount'],
      currency: pricing['currency'],
      isBlocked: pricing['isBlocked'],
      description: params['description'] ?? '',
      isB3Blocked: false
    });
  }

  constructor(obj: Partial<SalesModel>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class SellerPrice {
  public sku: string;
  public saleModel: string;
  public currency: string;
  public amount: number;
  public isBlocked: boolean;
  public rsp?: number;

  // When include saleModel info
  public description: string;
  public type: string;

  constructor(value?: any) {
    if (!!value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          this[key] = value[key];
        }
      }
    }
  }

  isActivationFee(): boolean {
    return this.saleModel === 'activation_fee';
  }

  isUsageSaleModel(): boolean {
    return this.saleModel === 'usage';
  }
}

export interface CreateSaleModelRequest {
  type: string;
  saleModel: string;
  description: string;
  cycle: number;
}

export enum SaleModelCode {
  ONE_OFF = 'one_off',
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export enum SaleModelDescription {
  ONE_TIME_CHARGE = 'One Time Charge',
  RECURRING_CHARGE_MONTHLY = 'Monthly Recurring Charge',
  RECURRING_CHARGE_ANNUAL = 'Annual Recurring Charge'
}

export interface ResponseSaleModels {
  productId: string;
  name: string;
  type: string;
  skus: DistributingSku[];
}
