import { isExternalUrl, randomGuid } from '../../plain_func.util';
import { MatchType, PREFIX_LINK } from '../model/match.model';
import { OutputProcessMessage, WidgetCompile, WidgetMatched } from '../model/output-message.model';
import { RegExpPattern } from '../model/patterns.const';

// highlight link href: [abc xyz](https://google.com)
export class HighlightLinkMarkdownCompiler implements WidgetCompile {
  constructor() {}

  preCompile(data: OutputProcessMessage): WidgetMatched[] {
    const highlightLinkMatched = data.text.match(RegExpPattern.HIGHLIGHT_LINK);
    const matches: WidgetMatched[] = [];
    if (highlightLinkMatched) {
      highlightLinkMatched.forEach(item => {
        const replaceString: string = randomGuid();
        data.text = data.text.replace(item, replaceString);
        matches.push({ random: replaceString, text: item });
      });
    }
    return matches;
  }

  compile(matches: WidgetMatched[], data: OutputProcessMessage) {
    matches.forEach(item => {
      let replaceItem = item.text;
      const split = item.text?.split('](');
      const text = split[0].slice(1);
      const link = split[1].slice(0, split[1].length - 1);

      if (link.startsWith('storage://')) {
        // interactive msg
        data.isTriggerDirective = true;
        replaceItem = `<a class='highlight-link cursor-pointer cannotRender' data-path="${link}" target="_blank" data-cannot-render='${MatchType.HIGHLIGHT_LINK}'>${text}</a>`;
      } else if (link.startsWith(PREFIX_LINK)) {
        const path = link.replace(PREFIX_LINK, '');
        data.isTriggerDirective = true;
        replaceItem = `<a class='highlight-link cursor-pointer cannotRender'
                  data-path="${path}"
                  data-cannot-render='${MatchType.LINK}'>${text}</a>`;
      } else if (!isExternalUrl(link)) {
        data.isTriggerDirective = true;
        replaceItem = `<a class='highlight-link cursor-pointer cannotRender' data-path="${link}" data-cannot-render='${MatchType.LINK}'>${text}</a>`;
      } else {
        replaceItem = `<a class='highlight-link' href="${link}" target="_blank">${text}</a>`;
      }

      data.text = data.text.replace(item.random, replaceItem);
    });
  }
}
