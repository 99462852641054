import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchProductType',
  standalone: true
})
export class MatchProductTypePipe implements PipeTransform {
  transform(products: Array<unknown>, productType: string): any {
    return products?.filter(product => product['type'] === productType);
  }
}
