import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'shc-resource-not-found',
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule]
})
export class ResourceNotFoundComponent {
  @Input() message = 'No Resource Found';
  @Input() icon = 'insert_drive_file';
}
