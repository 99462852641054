import { Injectable } from '@angular/core';
import { CreatedTxn } from './created-txn.model';
import { CreatedTxnsStore } from './created-txn.store';

@Injectable({
  providedIn: 'root'
})
export class CreatedTxnsService {
  constructor(private store: CreatedTxnsStore) {}

  updateCreatedTxns2Store(createdBy: CreatedTxn[]) {
    this.store.upsertMany(createdBy);
  }
}
