import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { WatchersState, WatchersStore } from './watchers.store';

@Injectable({ providedIn: 'root' })
export class WatchersQuery extends QueryEntity<WatchersState> {
  constructor(protected override store: WatchersStore) {
    super(store);
  }

  selectWatcherByTxn(txnUuid: string) {
    return this.selectEntity(txnUuid);
  }

  selectTxnsWatchingByMe(meIdentity: string) {
    return this.selectAll({
      filterBy: entity => entity?.watchers?.includes(meIdentity)
    });
  }
}
