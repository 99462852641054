import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PinMessage } from './pin-message.model';

export interface PinMessageState extends EntityState<PinMessage> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workspace_pin_message', idKey: 'messageId' })
export class PinMessageStore extends EntityStore<PinMessageState> {
  constructor() {
    super();
  }
}
