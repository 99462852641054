import { OutputProcessMessage, markdownUtil } from '@b3networks/shared/common';
import { InteractiveBlockType } from '../enums.model';

export class InteractiveMessageData {
  // for webhook message
  blocks: InteractiveBlock[];

  constructor(obj: Partial<InteractiveMessageData>) {
    if (obj) {
      Object.assign(this, obj);
      this.blocks = obj?.blocks?.map(x => new InteractiveBlock(x)) || [];
    }
  }
}

export class InteractiveBlock {
  type: InteractiveBlockType;
  text?: InteractiveBlock; // text maybe string or an UIItem depend on the type
  fields?: InteractiveBlock[];
  color: string;

  // ui
  builtTextMessage: OutputProcessMessage;

  constructor(obj: Partial<InteractiveBlock>) {
    if (obj) {
      Object.assign(this, obj);
      this.fields = obj?.fields?.map(x => new InteractiveBlock(x)) || [];

      if (typeof obj?.text === 'string') {
        this.builtTextMessage = markdownUtil(obj.text);
      } else if (typeof obj?.text === 'object') {
        this.text = new InteractiveBlock(obj.text);
        if (this.text?.type === InteractiveBlockType.markdown && typeof this?.text.text === 'string') {
          this.builtTextMessage = markdownUtil(this.text.text);
        }
      }
    }
  }
}
