import { Injectable } from '@angular/core';
import { HashMap, Store, StoreConfig } from '@datorama/akita';
import { IAMActionMappedPG, PermissionGroup } from '../iam/iam-group.model';
import { IAMPermission } from './iam.model';
import { PolicyDocument } from './policty-document.model';

export interface IamState {
  // supporting data
  // iam
  supportingPermissions: IAMPermission[];

  // pg data  source
  supportingPermissionGroups: PermissionGroup[];
  supportingActionMappingPG: HashMap<IAMActionMappedPG[]>;

  // granted policy per org and domain
  orgGrantedPolicy: PolicyDocument;
  domainGrantedPolicy: PolicyDocument;
}

export function createInitialState(): IamState {
  return {} as IamState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth_iam' })
export class IamStore extends Store<IamState> {
  constructor() {
    super(createInitialState());
  }
}
