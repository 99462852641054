import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SharedResourcesState, SharedResourcesStore } from './shared-resources.store';

@Injectable({ providedIn: 'root' })
export class SharedResourcesQuery extends QueryEntity<SharedResourcesState> {
  constructor(protected override store: SharedResourcesStore) {
    super(store);
  }

  getSharedResources() {
    return this.getAll();
  }
}
