import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface TeamChatState extends EntityState<any> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'teamchat', idKey: 'key' })
export class TeamChatStore extends EntityStore<TeamChatState> {
  constructor() {
    super();
  }
}
