export interface CreateRedirectLinkReq {
  deviceType: string;
  srcUrl: string;
  activationToken?: string;
  rememberMe?: boolean;
  email?: string;
}

export interface VerifyStatusReq {
  state: string;
  code: string;
}

export enum AuthorizeProvider {
  microsoft = 'ms',
  google = 'google',
  myinfo_singpass = 'myinfo_singpass',
  password = 'password'
}

export interface LoginData {
  authorizeProvider: AuthorizeProvider;
}
