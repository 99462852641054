import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TfaService } from '@b3networks/api/auth';
import { ToastService } from '@b3networks/shared/ui/toast';
import { finalize } from 'rxjs';

@Component({
  selector: 'b3n-authenticator-app',
  templateUrl: './authenticator-app.component.html',
  styleUrls: ['./authenticator-app.component.scss']
})
export class AuthenticatorAppComponent implements OnInit {
  qrSecret: string;
  qrCode = '';
  loading = true;
  progressing: boolean;
  verificationCodeCtr = new FormControl('', Validators.required);

  getVerificationCodeError() {
    if (this.verificationCodeCtr.hasError('required')) {
      return 'Verification code is required';
    } else if (this.verificationCodeCtr.hasError('mismatched')) {
      return 'Verification code mismatched';
    }
    return '';
  }

  constructor(
    private dialogRef: MatDialogRef<AuthenticatorAppComponent>,
    private tfaService: TfaService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.verificationCodeCtr.valueChanges.subscribe(() => {
      this.verificationCodeCtr.setErrors(null);
    });

    this.tfaService.getTotpCode().subscribe(res => {
      this.qrCode = res.uri;
      this.qrSecret = res.secret;
      this.loading = false;
    });
  }

  verify() {
    if (this.verificationCodeCtr.valid) {
      this.progressing = true;
      this.tfaService
        .verifyTotpCode({ secret: this.qrSecret, code: this.verificationCodeCtr.value })
        .pipe(finalize(() => (this.progressing = false)))
        .subscribe({
          next: res => {
            this.toastService.success(
              "You're all set. From now on, you'll use Authenticator to sign in to your account."
            );
            this.dialogRef.close(res.recoveryKey);
          },
          error: () => this.verificationCodeCtr.setErrors({ mismatched: true })
        });
    }
  }
}
