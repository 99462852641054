import { Pipe, PipeTransform } from '@angular/core';

export type Order = 'asc' | 'desc';

// Sort list by order params
// If item of list is a both number type and string type.
//We are sort number first by order 'asc' (default).

@Pipe({
  name: 'sortList',
  standalone: true
})
export class SortListPipe implements PipeTransform {
  transform(value: any[], order: Order = 'asc', key?: string): any {
    let numberArray = [];
    let stringArray = [];

    if (!value || (order !== 'asc' && order !== 'desc')) {
      return value;
    }

    if (!key) {
      numberArray = value.filter(item => typeof item === 'number').sort();
      stringArray = value.filter(item => typeof item === 'string').sort();
    } else {
      numberArray = value.filter(item => typeof item[key] === 'number').sort((a, b) => a[key] - b[key]);
      stringArray = value
        .filter(item => typeof item[key] === 'string')
        .sort((a, b) =>
          a[key].toLowerCase() < b[key].toLowerCase() ? -1 : a[key].toLowerCase() > b[key].toLowerCase() ? 1 : 0
        );
    }

    const sorted = numberArray.concat(stringArray);

    return order === 'asc' ? sorted : sorted.reverse();
  }
}
