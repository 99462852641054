import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { HotDesking } from './hot-desking.model';

export interface HotDeskingState extends EntityState<HotDesking>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'hot_desking', idKey: 'sipUsername' })
export class HotDeskingStore extends EntityStore<HotDeskingState, HotDesking> {
  constructor() {
    super();
  }
}
