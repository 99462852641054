import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { WhatsappTemplate } from './wa-template.model';
import { WaTemplateStore } from './wa-template.store';

@Injectable({
  providedIn: 'root'
})
export class WaTemplateService {
  constructor(private http: HttpClient, private store: WaTemplateStore) {}

  getAll(forceSync?: boolean) {
    const params = new HttpParams().append('forceSync', forceSync ? 'true' : 'false');
    return this.http.get<WhatsappTemplate[]>(`inbox/private/v1/whatsapp/templates`, { params }).pipe(
      map(res => res.map(data => new WhatsappTemplate(data))),
      tap(list => this.store.upsertMany(list, { baseClass: WhatsappTemplate }))
    );
  }
}
