import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityProfile, ProfileOrg } from '@b3networks/api/auth';
import { DataLoginHistory, GetReportV4Payload, Period, ReportV4Code, V4Service } from '@b3networks/api/data';
import { AnnouncementResp, AnnouncementService, PortalQuery, PortalService } from '@b3networks/api/portal';
import { UserService } from '@b3networks/api/workspace';
import { AppStateService, SessionQuery } from '@b3networks/portal/base/shared';
import { DestroySubscriberComponent, X_B3_HEADER } from '@b3networks/shared/common';
import { ThemeService } from '@b3networks/shared/ui/theme';
import { ToastService } from '@b3networks/shared/ui/toast';
import { addDays, startOfDay, subMonths } from 'date-fns';
import { format } from 'date-fns-tz';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';

interface LandingData {
  profile: IdentityProfile;
  currentOrg: ProfileOrg;
  lastLogin: DataLoginHistory;
}
interface ColorPlallet {
  bgColor: string;
  fontColor: string;
}

@Component({
  selector: 'b3n-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent extends DestroySubscriberComponent implements OnInit {
  readonly bgColors = [
    '#ffffff',
    '#0079bf',
    '#d29034',
    '#519839',
    '#b04632',
    '#89609e',
    '#cd5a91',
    '#4bbf6b',
    '#00aecc',
    '#838c91'
  ];
  readonly darkBgColor = ['#0079bf', '#b04632', '#89609e'];

  data$: Observable<LandingData>;
  colorPallet$: Observable<ColorPlallet>;
  isDarkMode$ = this.themeService.isDarkMode$;

  loading: boolean;
  progressing: boolean;

  announcements$: Observable<AnnouncementResp[]>;
  timezone: string;

  constructor(
    private sessionQuery: SessionQuery,
    private portalQuery: PortalQuery,
    private portalService: PortalService,
    private toastService: ToastService,
    private appStateService: AppStateService,
    private announcementService: AnnouncementService,
    private v4Service: V4Service,
    private router: Router,
    private userService: UserService,
    private themeService: ThemeService
  ) {
    super();
  }

  ngOnInit() {
    console.log('init landing page component');
    this.userService.fetchAllUsersV2().subscribe();

    this.announcements$ = this.announcementService.getAnnouncements();
    this.data$ = combineLatest([
      this.sessionQuery.profile$,
      this.sessionQuery.currentOrg$.pipe(
        filter(org => org != null),
        tap(org => this.handleOrgChanged(org))
      )
    ]).pipe(
      filter(([profile, org]) => profile != null && org != null),
      switchMap(([profile, currentOrg]) => forkJoin([of(profile), of(currentOrg), this.getLastLogin$()])),
      map(([profile, currentOrg, lastLogin]) => {
        this.timezone = currentOrg.utcOffset;

        return <LandingData>{
          profile: profile,
          currentOrg: currentOrg,
          lastLogin: lastLogin
        };
      })
    );
    this.appStateService.toggleAppLoading(false); // for when switching org
  }

  copy() {
    this.toastService.success('Copied to clipboard');
  }

  navigateToActivity() {
    this.router.navigate([this.sessionQuery.currentOrg.orgUuid, 'account', 'activity']);
  }

  changeSidebarBackgroundColor(bg: string) {
    this.progressing = true;
    this.portalService
      .updateOrgHomeBackground(bg)
      .pipe(finalize(() => (this.progressing = false)))
      .subscribe(
        _ => {
          this.toastService.success('Changed background color.');
        },
        error => {
          this.toastService.error(error.message || 'Cannot change sidebar background color. Please try again later.');
        }
      );
  }

  private handleOrgChanged(org: ProfileOrg) {
    this.announcements$ = this.announcementService.getAnnouncements();

    this.colorPallet$ = this.portalQuery.orgBackground$.pipe(
      map(bgColor => <ColorPlallet>{ bgColor: bgColor, fontColor: this.generalFontColor(bgColor) })
    );
  }

  private generalFontColor(bgColor: string) {
    switch (bgColor) {
      case '#0079bf': {
        return '#fafdff';
      }
      case '#d29034': {
        return '#2e1f0a';
      }
      case '#519839': {
        return '#12210d';
      }
      case '#b04632': {
        return '#f6f4f3';
      }
      case '#89609e': {
        return '#faf9fb';
      }
      case '#cd5a91': {
        return '#32061c';
      }
      case '#4bbf6b': {
        return '#0f2916';
      }
      case '#00aecc': {
        return '#003038';
      }
      case '#838c91': {
        return '#0b222d';
      }
      default: {
        return '#616161';
      }
    }
  }

  private getLastLogin$(): Observable<DataLoginHistory> {
    const headers = new HttpHeaders().set(X_B3_HEADER.orgUuid, '');
    return this.v4Service
      .getReportData<DataLoginHistory>(
        Period.dump,
        ReportV4Code.loginHistory,
        this.buildLoginHistoryBodyReq(),
        { page: 1, perPage: 2 },
        false,
        headers
      )
      .pipe(
        map(resp => resp?.rows?.reduce((prev, current) => (prev.time < current.time ? prev : current))),
        catchError(_ => of(null))
      );
  }

  private buildLoginHistoryBodyReq(): GetReportV4Payload {
    const endTime = format(startOfDay(addDays(new Date(), 1)), "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: this.timezone });
    const startTime = format(startOfDay(subMonths(new Date(), 6)), "yyyy-MM-dd'T'HH:mm:ssxxx", {
      timeZone: this.timezone
    });
    return <GetReportV4Payload>{
      startTime: startTime,
      endTime: endTime,
      scope: 'personal',
      filter: {
        deviceType: 'web'
      }
    };
  }
}
