import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CacheMessageState, CacheMessageStore } from './cache-message.store';

@Injectable({ providedIn: 'root' })
export class CacheMessageQuery extends QueryEntity<CacheMessageState> {
  constructor(protected override store: CacheMessageStore) {
    super(store);
  }

  selectManyMessages(messageIds: string[]) {
    return this.selectMany(messageIds);
  }
}
