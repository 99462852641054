import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IdentityProfileQuery } from '@b3networks/api/auth';
import { AnnouncementResp, AnnouncementService } from '@b3networks/api/portal';
import { DestroySubscriberComponent } from '@b3networks/shared/common';
import { ThemeService } from '@b3networks/shared/ui/theme';
import { filter } from 'rxjs';
import { distinctUntilKeyChanged, finalize, takeUntil, tap } from 'rxjs/operators';
import { StoreAnnouncementComponent, StoreAnnouncementInput } from './store-announcement/store-announcement.component';

@Component({
  selector: 'b3n-landing-page-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatCardModule, MatProgressBarModule, MatDividerModule]
})
export class AnnouncementsComponent extends DestroySubscriberComponent implements OnInit {
  loading = true;
  announcements: AnnouncementResp[] = [];

  constructor(
    private announcementService: AnnouncementService,
    private dialog: MatDialog,
    private identityProfileQuery: IdentityProfileQuery,
    private cdr: ChangeDetectorRef,
    private themeService: ThemeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.identityProfileQuery.currentOrg$
      .pipe(
        filter(x => x != null),
        distinctUntilKeyChanged('orgUuid'),
        tap(_ => {
          this._fetchAnnouncements();
        }),
        takeUntil(this.destroySubscriber$)
      )
      .subscribe();
  }

  storeAnnouncement(announcement?: AnnouncementResp) {
    this.dialog
      .open(StoreAnnouncementComponent, {
        width: '750px',
        autoFocus: false,
        panelClass: ['store-announcement', this.themeService.isDarkMode ? 'dark-theme-portal-base' : ''],
        data: {
          announcement
        } as StoreAnnouncementInput,
        disableClose: true
      })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this._fetchAnnouncements();
        }
      });
  }

  private _fetchAnnouncements() {
    this.loading = true;
    this.announcements = [];

    this.announcementService
      .getAnnouncements()
      .pipe(
        tap(announcements => {
          this.announcements = announcements;
        }),
        finalize(() => {
          this.loading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }
}
