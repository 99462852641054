import { InjectionToken } from '@angular/core';

export type ToastType = 'error' | 'warning' | 'success';

export class ToastDataBase {
  guid: string;
  duration?: number;
  time: number;

  constructor(obj: Partial<ToastDataBase>) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class ToastData extends ToastDataBase {
  // default toast
  message?: string;
  type?: ToastType;

  // custom toast with your component
  template: any;
  templateContext: any;

  constructor(obj: Partial<ToastData>) {
    if (obj) {
      super(obj);
      Object.assign(this, obj);
    }
  }
}

export class ToastConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
}

export const defaultToastConfig: ToastConfig = {
  position: {
    top: 20,
    right: 20
  },
  animation: {
    fadeOut: 250,
    fadeIn: 150
  }
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
