import { Injectable } from '@angular/core';
import { RelatedTxn } from './related-txn.model';
import { RelatedTxnsStore } from './related-txn.store';

@Injectable({
  providedIn: 'root'
})
export class RelatedTxnsService {
  constructor(private store: RelatedTxnsStore) {}

  updateRelatedTxns2Store(relatedTxns: RelatedTxn[]) {
    this.store.upsertMany(relatedTxns);
  }
}
