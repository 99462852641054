import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';
import { CasesState, CasesStore } from './cases.store';

@Injectable({ providedIn: 'root' })
export class CasesQuery extends QueryEntity<CasesState> {
  constructor(protected override store: CasesStore) {
    super(store);
  }

  selectRelatedToByTxn(orgUuid: string, txnUuid: string) {
    return this.selectEntity(`${orgUuid}_${txnUuid}`).pipe(map(x => x?.listRelatedTo || []));
  }
}
