import { BillingCycle } from '@b3networks/api/salemodel';

export class BundleItem {
  productId: string;
  sku: string;
  saleModelCode: BillingCycle;
  quantity: number;
  numberSku?: string | null;
  type?: 'BASE' | 'ADDON';
  autoRenew?: boolean;
  multiplier?: number;
  numberProduct?: string;
  numbers?: string[];
  newQuantity?: number;
  isNew?: boolean;
  isRemoved?: boolean;
  skuName?: string;
  vendor?: string;
}

export enum BundleStatus {
  active = 'ACTIVE',
  deleted = 'DELETED'
}

export class Bundle {
  id: number | string;
  name: string;
  description: string;
  items: BundleItem[];
  status: BundleStatus;
  createdAt: string | null;
  updatedAt: string | null;
  published: boolean;
  productId?: string;
  sku?: string;
  quantity?: number;
  //ui
  price?: string;

  constructor(obj?: Partial<Bundle>) {
    if (obj) {
      Object.assign(this, obj);
    }
    this.items = this.items || [];
  }

  get numbersItems() {
    console.log(this.items);

    return this.items.filter(i => !!i.numberSku);
  }
}

export function createBundle(_: Partial<Bundle>) {
  return {} as Bundle;
}

export interface GetBundleReq {
  statuses: BundleStatus[];
}

export interface BundleSkus {
  productId: string;
  sku: string;
  items: BundleItem[];
}

export class PurchaseLicenseRequest {
  productId: string;
  sku: string;
  items: PurchaseLicenseRequest.Item[];
  contractNumber: string;
  trial: boolean;
  expireAt: string;
}

export namespace PurchaseLicenseRequest {
  export class Item {
    productId: string;
    sku: string;
    saleModelCode: string;
    quantity: number;
    autoRenew: boolean;
    multiplier: number;
    numberProduct: string;
    numberSku: string;
    numbers: string[];
    productName?: string;
    skuName?: string;
  }
}
