<button
  class="button-copy grey-300"
  mat-button
  [matTooltip]="tooltip"
  [cdkCopyToClipboard]="text"
  (cdkCopyToClipboardCopied)="copied()"
>
  {{ text | slice: 0:limitContentLength }}
  <span *ngIf="limitContentLength < text?.length"> <ng-container *ngIf="!ellipsis">...</ng-container></span>
</button>
