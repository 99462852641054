import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { QueuesState, QueuesStore } from './queue.store';

@Injectable({ providedIn: 'root' })
export class QueuesQuery extends QueryEntity<QueuesState> {
  all$ = this.selectAll();

  constructor(protected override store: QueuesStore) {
    super(store);
  }

  selectQueueByUuid(uuid: string) {
    return this.selectEntity(uuid);
  }

  getQueueByQueueCode(code: string) {
    return this.getAll().find(x => x.code === code);
  }
}
