import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours',
  standalone: true
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(minutes: number) {
    if (!minutes) return '';
    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      const minutesOfHours = minutes % 60;
      const displayMinutes = minutesOfHours > 0 ? `${minutesOfHours} minute${minutesOfHours > 1 ? 's' : ''}` : '';
      return `${hours} hour${hours > 1 ? 's' : ''} ${displayMinutes}`;
    }
    {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  }
}
