import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { RealDomain } from '@b3networks/api/auth';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'real-domain' })
export class RealDomainStore extends Store<RealDomain> {
  constructor() {
    super({});
  }
}
