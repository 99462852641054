export const EXPIRED_TIME_WHATSAPP = 24 * 60 * 60 * 1000; // 24h

export class WhatsappInfo {
  waId: string; // number, ID
  wabaPhoneNumberId: string;
  wabaId: string; // of org
  lastMessageTime: number;
  type: WhatsappInfoType;

  // ui
  phoneNumberCustomer: string;

  constructor(obj?: Partial<WhatsappInfo>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj?.waId) {
        this.phoneNumberCustomer = obj.waId.startsWith('+') ? obj.waId : `+${obj.waId}`;
      }
    }
  }
}

export enum WhatsappInfoType {
  incoming = 'incoming',
  outgoing = 'outgoing'
}
