import { b3nColorPalettes, getHexTransparencies } from '@b3networks/shared/ui/theme';

export const THEME_DARK_MODE = {
  b3n: {
    // required
    background: b3nColorPalettes.black[800],
    'background-v1': b3nColorPalettes.black[800],
    color: b3nColorPalettes.blue[50],
    'secondary-text': '#E3E5E8CC',
    'box-uuid': getHexTransparencies(b3nColorPalettes.gray[915], 60),
    selection: {
      active: '#4E505899',
      'text-active': '#14B0FF',
      hover: '#4E50584D',
      'text-hover': '#E3E5E8'
    },
    highlight: b3nColorPalettes.black[900],
    link: b3nColorPalettes.blue[850],
    disabled: getHexTransparencies(b3nColorPalettes.blue[50], 40),
    hover: getHexTransparencies(b3nColorPalettes.gray[915], 30),

    // optional
    'secondary-color': getHexTransparencies(b3nColorPalettes.blue[50], 80),
    border: getHexTransparencies(b3nColorPalettes.gray[915], 48),
    'border-2': getHexTransparencies(b3nColorPalettes.blue[50], 12),
    shadow: getHexTransparencies(b3nColorPalettes.blue[50], 8),
    'scrollbar-thumb': b3nColorPalettes.gray[915],
    'scrollbar-track': b3nColorPalettes.black[900],
    contrast: getHexTransparencies(b3nColorPalettes.blue[50], 96),
    title: getHexTransparencies(b3nColorPalettes.blue[50], 96),
    sidebar: {
      'user-menu': b3nColorPalettes.black[800],
      'hover-menu': getHexTransparencies(b3nColorPalettes.gray[915], 60),
      'user-menu-chip': getHexTransparencies(b3nColorPalettes.gray[915], 60),
      'org-section': '#2B2D31',
      'org-menu-item-hover': '#4E50584D',
      'box-uuid-color': '#E3E5E8',
      background: b3nColorPalettes.blue[550],
      'hover-item': b3nColorPalettes.gray[918]
    },
    home: {
      bg: b3nColorPalettes.black[800],
      announcement: b3nColorPalettes.black[900]
    },
    sidenav: {
      item: {
        hover: getHexTransparencies(b3nColorPalettes.gray[915], 60),
        active: b3nColorPalettes.blue[956]
      },
      icon: {
        color: b3nColorPalettes.blue[50],
        active: b3nColorPalettes.blue[50]
      }
    },
    icon: {
      color: b3nColorPalettes.blue[50],
      action: b3nColorPalettes.gray[917],
      active: getHexTransparencies(b3nColorPalettes.gray[915], 60),
      disabled: getHexTransparencies(b3nColorPalettes.blue[50], 25)
    },
    conversation: {
      header: {
        title: getHexTransparencies(b3nColorPalettes.blue[50], 96),
        subtitle: b3nColorPalettes.gray.DEFAULT
      },
      message: {
        mention: getHexTransparencies(b3nColorPalettes.blue[100], 10),
        highlight: getHexTransparencies(b3nColorPalettes.yellow.DEFAULT, 15),
        editing: b3nColorPalettes.gray[800]
      },

      mention: {
        background: b3nColorPalettes.black[100],
        'not-member': getHexTransparencies(b3nColorPalettes.blue[50], 10)
      },
      footer: {
        bg: getHexTransparencies(b3nColorPalettes.gray[915], 60),
        'delete-bg': b3nColorPalettes.red[700]
      },
      preview: {
        background: b3nColorPalettes.gray[800]
      },
      quote: b3nColorPalettes.red[350]
    },
    'landing-page': {
      'org-title': b3nColorPalettes.gray.DEFAULT,
      'last-login-color': b3nColorPalettes.gray[700]
    },
    toast: {
      success: {
        color: b3nColorPalettes.green[700],
        bg: b3nColorPalettes.green[800],
        border: b3nColorPalettes.green[900]
      },
      warning: {
        color: b3nColorPalettes.yellow[800],
        bg: b3nColorPalettes.yellow[900],
        border: b3nColorPalettes.yellow[50]
      },
      error: {
        color: b3nColorPalettes.red[50],
        bg: b3nColorPalettes.red[150],
        border: b3nColorPalettes.red[250]
      }
    }
  },
  'primary-a-tag': b3nColorPalettes.blue[850]
};
