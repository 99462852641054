import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FirstWordPipe, HighlightPipe } from '@b3networks/shared/common';

@Component({
  selector: 'csh-render-member-v2',
  templateUrl: './render-member-v2.component.html',
  styleUrls: ['./render-member-v2.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, FirstWordPipe, HighlightPipe]
})
export class RenderMemberV2Component implements OnChanges {
  photoUrl: string;

  @Input() isBot: boolean;
  @Input() isMe = false;
  @Input() avatarUrl: string;
  @Input() status: string;
  @Input() displayName: string;
  @Input() showDisplayName = true;
  @Input() showStatus = true;
  @Input() key = '';

  // TODO: refactor code
  @Input() isLarge: boolean; // size 80px
  @Input() isSmall: boolean; // size 18px
  @Input() isSmallV2: boolean; // size 24px

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['avatarUrl'] && this.avatarUrl) {
      this.photoUrl = `url("${this.avatarUrl}")`;
    }
  }
}
