import { Media } from '@b3networks/api/inbox';

export class WaTemplateMessageData {
  components: WaTemplateComponent[];

  // ui
  header?: WaTemplateComponent;
  body?: WaTemplateComponent;
  footer?: WaTemplateComponent;
  buttons?: WaTemplateComponent;

  constructor(obj?: Partial<WaTemplateMessageData>) {
    if (obj) {
      Object.assign(this, obj);

      this.components = obj?.components?.map(x => new WaTemplateComponent(x)) || [];

      this.header = this.components.find(x => x.type === TemplateComponentType.HEADER);
      this.body = this.components.find(x => x.type === TemplateComponentType.BODY);
      this.buttons = this.components.find(x => x.type === TemplateComponentType.BUTTON);
      this.footer = this.components.find(x => x.type === TemplateComponentType.FOOTER);
    }
  }
}

export class WaTemplateComponent {
  type: TemplateComponentType;

  format?: string | 'text' | 'image' | 'video' | 'document';
  text?: string;
  image?: Media;
  video?: Media;
  document?: Media;
  buttons: WaTemplateButton[];

  constructor(obj?: Partial<WaTemplateComponent>) {
    if (obj) {
      Object.assign(this, obj);

      if (obj.buttons) {
        obj.buttons.forEach(b => {
          switch (b.sub_type?.toUpperCase()) {
            case TemplateButtonType.QUICK_REPLY:
              b.icon = 'wa_btn_reply';
              break;
            case TemplateButtonType.URL:
              b.icon = 'wa_btn_launch';
              break;
            case TemplateButtonType.PHONE_NUMBER:
              b.icon = 'b3n_call';
              break;
            case TemplateButtonType.COPY_CODE:
              b.icon = 'wa_btn_copy';
              break;
          }
        });
      }
    }
  }
}

export interface WaTemplateButton {
  sub_type: TemplateButtonType;
  text?: string;
  url?: string;
  phoneNumber?: string;
  icon?: string;
  example: string[] | any[];
}

export enum TemplateComponentType {
  HEADER = 'header',
  BODY = 'body',
  FOOTER = 'footer',
  BUTTON = 'button'
}

export enum TemplateButtonType {
  QUICK_REPLY = 'QUICK_REPLY',
  URL = 'URL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  COPY_CODE = 'COPY_CODE'
}
