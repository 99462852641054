export interface PortalData {
  orgBackground?: string;
}

export class PortalDomainConfig {
  portalTitle: string;
  supportEmail: string;
  salesEmail: string;
  loginHeaderBackground: string;
  loginButtonBackground: string;
  logoUrl: string;
  faviconUrl: string;

  constructor(value: Object) {
    if (!!value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          this[key] = value[key];
        }
      }
    }
  }
}
