declare const DOMPurify;

let isInit = false;
export function domPurifyUtilSanitize(dirty: string) {
  if (!isInit) {
    addHookSanitize();
    isInit = true;
  }

  let clean = dirty;

  clean = DOMPurify.sanitize(clean, {
    ALLOWED_TAGS: ['a', 'pre', 'span', 'i', 'strong', 'br', 'code'],
    ADD_ATTR: [
      'class',
      'style',
      'id',
      'href',
      'target',
      'data-hyper-id',
      'data-channel',
      'data-cannot-render',
      'data-path',
      'data-query',
      'data-convo-type',
      'data-user-uuid',
      'data-user-chatUuid',
      'data-action-type',
      'data-jump-message'
    ]
  });

  return clean;
}

function addHookSanitize() {
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    // Add a hook to make all links open a new window
    hookMakeOpenWindown(node);

    // Add a hook to enforce URI scheme allow-list
    checkAllowValueAttribute(node);
  });

  DOMPurify.addHook('beforeSanitizeAttributes', node => {
    // Add a hook to remove empty content nodes
    hookRemoveEmptyContent(node);
  });
}

function hookRemoveEmptyContent(node: Element) {
  if (!node.hasChildNodes() && !node.textContent) {
    if (node.tagName?.toUpperCase() !== 'BR') {
      node.remove();
    }
  }
}

function hookMakeOpenWindown(node: Element) {
  if ('target' in node) {
    // set all elements owning target to target=_blank
    // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
    node.setAttribute('rel', 'noopener noreferrer');
  }
  // set non-HTML/MathML links to xlink:show=new
  if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
}

function checkAllowValueAttribute(node: Element) {
  // allowed URI schemes
  const allowlist = ['http', 'https', 'ftp'];
  // build fitting regex
  const regex = RegExp('^(' + allowlist.join('|') + '):', 'im');

  // build an anchor to map URLs to
  const anchor = document.createElement('a');

  // check all href attributes for validity
  if (node.hasAttribute('href')) {
    anchor.href = node.getAttribute('href');
    if (anchor.protocol && !anchor.protocol.match(regex)) {
      node.removeAttribute('href');
    }
  }

  // check all xlink:href attributes for validity
  if (node.hasAttribute('xlink:href')) {
    anchor.href = node.getAttribute('xlink:href');
    if (anchor.protocol && !anchor.protocol.match(regex)) {
      node.removeAttribute('xlink:href');
    }
  }
}
