import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomainUtilsService, X_B3_HEADER } from '@b3networks/shared/common';
import { map, tap } from 'rxjs';
import { ResponseTokenInbox } from '../txn/txn.model';
import {
  CustomerChatBox,
  ReqGetTokenUploadFile,
  RequestUpdateLastActivity,
  RequestUploadFile,
  ResponseToken,
  ResponseTxn,
  ResponseUpdateFile,
  ResponseVerify,
  ResquestCreateChatSession,
  ResquestCreateToken,
  ResquestCreateTxn,
  ResquestEndChatSession,
  ResquestVerify,
  UIConfig
} from './livechat.model';
import { LiveChatStore } from './livechat.store';

@Injectable({
  providedIn: 'root'
})
export class LiveChatService {
  sessionTokenCustomer: string;

  constructor(private http: HttpClient, private store: LiveChatStore, private domainUtil: DomainUtilsService) {
    console.log(this);
  }

  createTokenV2(req: ResquestCreateToken) {
    return this.http.post<ResponseToken>(`inbox/public/v2/livechat/_createToken`, req).pipe(
      map(res => res?.token),
      tap(res => {
        this.sessionTokenCustomer = res;
      })
    );
  }

  createTxn(req: ResquestCreateTxn) {
    const headers = new HttpHeaders()
      .set(X_B3_HEADER.domain, this.domainUtil.getPortalDomain())
      .set(X_B3_HEADER.sessionToken, '');
    return this.http.post<ResponseTxn>(`callcenter/public/v2/chat/txn`, req, { headers: headers });
  }

  createTxnV4(req: ResquestCreateChatSession) {
    return this.http.post<ResponseTxn>(`inbox/public/v4/livechat/_create`, req);
  }

  endTxnV2(req: ResquestEndChatSession) {
    return this.http.post<ResponseTxn>(`inbox/public/v2/livechat/_end`, req);
  }

  verifyLiveChat(req: ResquestVerify) {
    return this.http.post<ResponseVerify>(`inbox/public/v2/livechat/verify`, req);
  }

  uploadFile(req: RequestUploadFile) {
    return this.http.post<ResponseUpdateFile>(`inbox/public/v2/livechat/_uploadFile`, req);
  }

  uploadFileAsync(req: RequestUploadFile) {
    return this.http.post<ResponseUpdateFile>(`inbox/public/v2/livechat/_uploadFile/async`, req);
  }

  statusUploadAsync(asyncId: number) {
    return this.http.get<any>(`inbox/public/v2/livechat/_uploadFile/async/${asyncId}`);
  }

  updateLastActivity(widgetId: string, req: RequestUpdateLastActivity) {
    return this.http.put(`inbox/public/v2/livechat/widgets/${widgetId}/_updateLastActivity`, req);
  }

  updateCustomersInfo(customer: CustomerChatBox) {
    this.store.update(customer);
  }

  updateFlow(answers: string[]) {
    this.store.update({
      answers: answers
    });
  }

  updateUI(ui: UIConfig) {
    this.store.update(entity => ({
      ...entity,
      ui: {
        ...entity.ui,
        ...ui
      }
    }));
  }

  resetUIState() {
    const ui = <UIConfig>{
      showFooter: true,
      waitingChatbot: false
    };
    this.updateUI(ui);
  }

  getTokenInbox(body: ReqGetTokenUploadFile) {
    return this.http.post<ResponseTokenInbox>(`inbox/public/v2/livechat/_uploadFile/_getToken`, body);
  }
}
