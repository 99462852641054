import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MessageLabelTxn } from './message-label.model';

export interface MessageLabelState extends EntityState<MessageLabelTxn> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'inbox_sc_msg_label', idKey: 'txnUuid' })
export class MessageLabelStore extends EntityStore<MessageLabelState> {
  constructor() {
    super();
  }
}
