import { Pipe, PipeTransform } from '@angular/core';
import { UserQuery } from '@b3networks/api/workspace';

@Pipe({
  name: 'userOnline',
  standalone: true
})
export class UserOnlinePipe implements PipeTransform {
  constructor(private userQuery: UserQuery) {}

  transform(userUuid: string) {
    return this.userQuery.isOnline$(userUuid);
  }
}
