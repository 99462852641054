import { Injectable } from '@angular/core';
import { ChatMessage } from '../../chat-message/chat-message.model';
import { CacheMessageQuery } from './cache-message.query';
import { CacheMessageStore } from './cache-message.store';

@Injectable({ providedIn: 'root' })
export class CacheMessageService {
  constructor(private store: CacheMessageStore, private query: CacheMessageQuery) {}

  upsertManyMessages(messages: ChatMessage[]) {
    this.store.upsertMany(messages, {
      baseClass: ChatMessage
    });
  }

  updateMessageV2(message: ChatMessage) {
    this.store.update(message.clientId, message);
  }
}
