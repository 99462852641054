import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CallerIdBase } from './caller-id.model';

export interface CallerIdsState extends EntityState<CallerIdBase>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'callcenter_callerIds', cache: { ttl: 10 * 60 * 1000 } })
export class CallerIdsStore extends EntityStore<CallerIdsState> {
  constructor() {
    super();
  }
}
