import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, Pageable } from '@b3networks/api/common';
import { X_PAGINATION } from '@b3networks/shared/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BulkTemplateApplyHistory, GetBulkTemplateResp, GetUserTplReportBody } from './bulk-template.model';

@Injectable({ providedIn: 'root' })
export class BulkTemplateService {
  constructor(private http: HttpClient) {}

  getAllTemplates(pageable: Pageable, type: string = '', keyword: string = ''): Observable<Page<GetBulkTemplateResp>> {
    let params = new HttpParams();
    if (pageable) {
      params = params.set('page', String(pageable.page)).set('perPage', String(pageable.perPage));
    }
    if (type) {
      params = params.set('type', String(type));
    }
    if (keyword) {
      params = params.set('keyword', String(keyword));
    }

    return this.http
      .get<GetBulkTemplateResp[]>(`callcenter/private/v3/extension/template`, { params, observe: 'response' })
      .pipe(
        map(response => {
          const page = new Page<GetBulkTemplateResp>();
          page.totalCount = +response.headers.get(X_PAGINATION.totalCount);
          page.content = response.body;
          return page;
        })
      );
  }

  createTemplate(template: GetBulkTemplateResp): Observable<GetBulkTemplateResp> {
    return this.http.post<GetBulkTemplateResp>(`callcenter/private/v3/extension/template`, template);
  }

  deleteTemplate(uuid: string): Observable<void> {
    return this.http.delete<void>(`callcenter/private/v3/extension/template/${uuid}`);
  }

  getDetailTemplate(uuid: string): Observable<GetBulkTemplateResp> {
    return this.http.get<GetBulkTemplateResp>(`callcenter/private/v3/extension/template/${uuid}`);
  }

  getApplyTemplateHistory(
    templateUuid: string,
    pageable: Pageable,
    body: Partial<GetUserTplReportBody>
  ): Observable<Page<BulkTemplateApplyHistory>> {
    let params = new HttpParams();

    if (pageable) {
      params = params.set('page', String(pageable.page)).set('perPage', String(pageable.perPage));
    }
    return this.http
      .post<BulkTemplateApplyHistory[]>(
        `callcenter/private/v3/extension/template/${templateUuid}/appliedHistory`,
        body,
        {
          params,
          observe: 'response'
        }
      )
      .pipe(
        map(list => {
          const result = new Page<BulkTemplateApplyHistory>();
          result.totalCount = +list.headers.get('x-pagination-total-count');
          result.content = list.body.map(item => new BulkTemplateApplyHistory(item));
          return result;
        })
      );
  }

  exportReport(templateUuid: string, templateAppliedId: number) {
    return this.http.get(`callcenter/private/v3/extension/template/${templateUuid}/report/${templateAppliedId}`, {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }
}
